import React from 'react';
import { Image, Badge,Tag,Avatar,Tooltip,Row, Col, Card } from 'antd';
import { MessageOutlined,PlusOutlined } from '@ant-design/icons';
import { payOptionsArray } from "../config/constants";
import './BankList.css';

const marketIcons = {
    1: "/assets/img/logo_new/Binance.png",
    2: "/assets/img/logo_new/ByBit.png",
    3: "/assets/img/logo_new/HTX.png",
    4: "/assets/img/logo_new/Commex.png",
};

export const MarketIcon = ({ market, size=32}) => (
    <div style={{ textAlign: 'center' }}><Avatar src={marketIcons[market]} shape="square"  size={size} alt={market} /></div>

);

export const MerchantIcon = () => (
    <Image src={"/assets/img/merchant.png"} width={20} height={20} preview={false} alt="Мерчант" />
);


export const getBankTag = (bankName) => {
    const bankOption = payOptionsArray.find(option => option.value === bankName);

    if (bankOption) {
        return <Tag color={bankOption.color}>{bankOption.label}</Tag>;
    } else {
        return <Tag color="lightgray">{bankName}</Tag>;
    }
};
export const ChatButton = ({ msgCount, onClick }) => (
    <div className='btn-chat-general' onClick={onClick}>
        <Badge count={msgCount}>
            <MessageOutlined className='btn-chat-message-outlined' />
        </Badge>
    </div>
);

export const ChatBankButton = ({ msgCount, bank, onClick }) => {
    return (
        <div className='btn-chat-general' onClick={onClick}>
            <Badge count={msgCount}>
                {Array.isArray(bank) && bank.length > 0 ? (
                    bank.length === 1 ? (
                        <BankIcon bank={bank[0]} size={30} />
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <BankIcon bank={bank[0]} size={30} />
                            <Tooltip
                                title={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {bank.slice(1).map((bankItem, index) => (
                                            <BankIcon key={index} bank={bankItem} size={22} style={{ marginLeft: 4 }} />
                                        ))}
                                    </div>
                                }
                            >
                                <PlusOutlined style={{ marginLeft: 4 }} />
                            </Tooltip>
                        </div>
                    )
                ) : null}
            </Badge>
        </div>
    );
};


export const BankIcon = ({ bank,size=36,style=null}) => (
    <Image
        src={bank !== undefined ? bankIcons[bank] : "NoBank"}
        width={size}
        height={size}
        preview={false}
        alt={bank}
        className="bank-icon"
        style={style}
    />
);

export const renderCryptoAddress = (address) => {
    try {
        if (address.length > 8) {
            return `${address.substring(0, 6)} ... ${address.substring(address.length - 6)}`;
        }
        return address;
    } catch {
        return "-"
    }

};

const bankIcons = {
    "TinkoffBank": "/assets/img/bank_glass/Tinkoff.png",
    "SberBank": "/assets/img/bank_glass/Sberbank.png",
    "RaiffeisenBank": "/assets/bank_glass/bank/Raiffeisenbank.png",
    "NoBank": "/assets/img/bank_glass/nobank.png",
};

export const BankImg = ({ bank }) => (
    <Image src={bankIcons[bank]} width={"100%"} height={"100%"} preview={false} alt="Market Icon" />
);


export const getRandomColorHex = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const BankImage = ({ bankName }) => {
    // Путь к изображению банка
    const imagePath = `/assets/img/bank_glass/${bankName}.png`;
    const handleError = (e) => {
        e.target.src = '/assets/img/bank_glass/nobank.png';
    };

    return (
        <Tooltip title={bankName}>
            <img
                src={imagePath}
                alt={bankName}
                onError={handleError}
                className="bank-icon"
            />
        </Tooltip>
    );
};
export const BankImageWrapper = ({ bankName }) => {
    // Преобразуем название банка в нужный формат
    const getFormattedBankName = (name) => {
        switch (name) {
            case 'Тинькофф Банк':
                return 'Tinkoff';
            case 'Сбербанк':
                return 'Sberbank';
            case 'ВТБ':
                return 'VTB Bank';
            case 'Альфа Банк':
                return 'Alfa Bank';
            default:
                return name;
        }
    };

    // Получаем форматированное название банка
    const formattedBankName = getFormattedBankName(bankName);

    return <BankImage bankName={formattedBankName} />;
};
export const BankList = ({ bankNames }) => {
    return (
        <div className="bank-list">
            {bankNames.map((bankName, index) => (
                <BankImage key={index} bankName={bankName} />
            ))}
        </div>
    );
};