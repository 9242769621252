import {CRM_URL} from "../configUrls";
import {postApiRequest, getApiRequest, getUserIp} from "../apiParams";


export const fetchMerchAllInfo = async (body) => {
    return postApiRequest(`${CRM_URL}/merch/all-info`, body);
};


export const fetchMerchMailInfo = async (body) => {
    return postApiRequest(`${CRM_URL}/merch/mail-info`, body);
};

export const fetchMerchMarketInfo = async (body) => {
    return postApiRequest(`${CRM_URL}/merch/market-info`, body);
};


export const fetchMerchGoogleInfo = async (userNumber) => {
    return getApiRequest(`${CRM_URL}/merch/google-info/${userNumber}`, );
};
export const fetchRebootProxy = async (userId) => {
    return postApiRequest(`${CRM_URL}/merch/reboot-proxy`, {userId});
};


export const fetchInfoProxy = async (userId) => {
    return postApiRequest(`${CRM_URL}/merch/info-proxy`, {userId});
};
