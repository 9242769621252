import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import LoginPage from './pages/LoginPage/LoginPage';
import MainLayout from './Layouts/MainLayout';
import routeConfig from './routesConfig';
import AuthLayout from './Layouts/AuthLayout';
import ProfilePage from "./pages/LoginPage/ProfilePage";
import NotFound from "./pages/404";
import './assets/css/Home.css';
import './assets/css/index.css';

import './assets/css/button-css.css';
import MerchantRegistration from "./pages/AdminPage/mercnats/components/MerchantRegistration";
import MerchantManagement from "./pages/AdminPage/mercnats/components/MerchantManagement";
import CommandManagement from "./pages/AdminPage/mercnats/components/CommandPage";




const renderRoute = ({ path, component: Component, allowedRoles, layout, requiresWebSocket, key }) => (
    <Route
        key={key}
        path={path}
        element={
            <AuthenticatedRoute allowedRoles={allowedRoles}>
                {layout === 'main' ? (
                    <MainLayout requiresWebSocket={requiresWebSocket}>
                        {Component && <Component />}
                    </MainLayout>
                ) : (
                    <AuthLayout>
                        {Component && <Component />}
                    </AuthLayout>
                )}
            </AuthenticatedRoute>
        }
    />
);

// Функция для рендеринга всех маршрутов, включая вложенные
const renderRoutes = (routes) => {
    let result = []; // Массив для сохранения результатов
    let routeKey = 0; // Счетчик для ключей
    routes.forEach(route => {
        result.push(renderRoute({
            path: route.path,
            component: route.component,
            allowedRoles: route.allowedRoles,
            layout: route.layout,
            requiresWebSocket: route.requiresWebSocket,
            key: `route-${routeKey++}` // Уникальный ключ для каждого маршрута

        }));


        // Если есть дочерние маршруты, рекурсивно обрабатываем их
        if (route.children && route.children.length > 0) {
            const childRoutes = renderRoutes(route.children); // Рекурсивный вызов для дочерних маршрутов
            result = result.concat(childRoutes); // Добавляем результаты рендеринга дочерних маршрутов
        }
    });

    return result;
};


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<Navigate to="/home" replace />} />
                {renderRoutes(routeConfig)}
                <Route
                    path="/profile"
                    element={
                        <AuthenticatedRoute allowedRoles={["ADMIN", "PRICE", "WORKER"]}>
                            <MainLayout>
                                <ProfilePage/>
                            </MainLayout>
                        </AuthenticatedRoute>
                    }
                />
                <Route
                    path="/register-merchant"
                    element={
                        <AuthenticatedRoute allowedRoles={["ADMIN", "PRICE", "WORKER"]}>
                            <MainLayout>
                                <MerchantRegistration/>
                            </MainLayout>
                        </AuthenticatedRoute>
                    }
                />
                <Route
                    path="/register-market"
                    element={
                        <AuthenticatedRoute allowedRoles={["ADMIN", "PRICE", "WORKER"]}>
                            <MainLayout>
                                <MerchantRegistration/>
                            </MainLayout>
                        </AuthenticatedRoute>
                    }
                />
                <Route
                    path="/manage-merchants"
                    element={
                        <AuthenticatedRoute allowedRoles={["ADMIN", "PRICE", "WORKER"]}>
                            <MainLayout>
                                <MerchantManagement/>
                            </MainLayout>
                        </AuthenticatedRoute>
                    }
                />
                <Route
                    path="/manage-commands"
                    element={
                        <AuthenticatedRoute allowedRoles={["ADMIN", "PRICE", "WORKER"]}>
                            <MainLayout>
                                <CommandManagement/>
                            </MainLayout>
                        </AuthenticatedRoute>
                    }
                />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}

export default App;
