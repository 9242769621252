import {getApiRequest,postApiRequest} from "../apiParams";
import {CRM_URL2} from "../configUrls";

export const fetchGetMerchants = () => {
    return getApiRequest(`${CRM_URL2}/merch/manage-merchants`);
};

export const createMerchant = (data) => {
    return postApiRequest(`${CRM_URL2}/merch/new_merchant`, data);
};

export const createMarket = (data) => {
    return postApiRequest(`${CRM_URL2}/merch/new_market`, data);
};

export const editMerchant = (data) => {
    return postApiRequest(`${CRM_URL2}/merch/edit-merchant`, data);
};

export const deleteMerchant = (data) => {
    return postApiRequest(`${CRM_URL2}/merch/del-merchant`, data);
};

export const editMarket = (data) => {
    return postApiRequest(`${CRM_URL2}/merch/edit-market`, data);
};


export const editWorkStatus = (data) => {
    return postApiRequest(`${CRM_URL2}/merch/work-status`, data);
};

export const deleteMarket = (data) => {
    return postApiRequest(`${CRM_URL2}/merch/del-market`, data);
};

export const getNewUserId = (data) => {
    return getApiRequest(`${CRM_URL2}/merch/get-number`, data);
};

export const editTeam = (data) => {
    return postApiRequest(`${CRM_URL2}/merch/edit-team`, data);
};
export const decryptValue = (data) => {
    return postApiRequest(`${CRM_URL2}/merch/decrypt`, data);
};

export const encryptValue = (data) => {
    return postApiRequest(`${CRM_URL2}/merch/encrypt`, data);
};


export const fetchGetCommands = () => {
    return getApiRequest(`${CRM_URL2}/commands/info`);
};

export const createCommand = (data) => {
    return postApiRequest(`${CRM_URL2}/commands/new`, data);
};

export const editCommand = (data) => {
    return postApiRequest(`${CRM_URL2}/commands/edit`, data);
};

export const deleteCommand = (data) => {
    return postApiRequest(`${CRM_URL2}/commands/del`, data);
};