import React, { useRef, useEffect, useState, useMemo} from 'react';
import { Modal,Skeleton , Tooltip, Button, Input, Dropdown, Card, Spin, Image, List, Col, Row, Upload } from 'antd';
import {
  PlusOutlined,
  PaperClipOutlined,
  CheckCircleOutlined,
  SendOutlined,
  SnippetsOutlined,
  DeleteOutlined,
  StarOutlined,
  DollarOutlined
} from '@ant-design/icons';
import webSocketStore from "../../state/WebSocketStore";
import { fetchChatList, uploadChatFile, fetchChatFile,fetchAntiFile, fetchConvertPDF} from "../../config/api/apiGeneral";
import '../../assets/css/chat_new.css';
import { observer } from "mobx-react";
import { addToast } from "../Notification/Toast";
import { statusDescriptions, quickRepliesBuy, quickRepliesSell } from "../../config/constants";
import { uniqueId } from 'lodash';
import MerchStore from "../../state/MerchStore";


function formatTime(timestamp) {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })} ${date.toLocaleTimeString('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
}
const messages  = [
    "Пожалуйста, оплатите точно по ордеру одной транзакцией",
    "Пожалуйста, оплатите сумму ордера одной транзакцией",
    "Оплатите, пожалуйста, по ордеру одной транзакцией",
    "Проведите оплату по ордеру одной транзакцией",
    "Оплатите, пожалуйста, по ордеру в одной транзакцией",
    "Убедительная просьба оплатить по ордеру одной транзакцией",
    "Пожалуйста, произведите оплату по ордеру одной транзакцией",
    "Пожалуйста, проведите оплату по ордеру одной транзакцией",
    "Пожалуйста, произведите оплату по ордеру в одной транзакцией",

];

const ChatMessage = React.memo(({index, message, imageUrl, triggerLoadFile }) => {
  const [isLoading, setLoading] = useState(false);
  const isSystemMessage = message.type === 'sys';
  const isAlarmMessage = message.type === 'alarm';
  const [visible, setVisible] = useState(false); // Управление видимостью модального окна
  const [antiFileResponse, setAntiFileResponse] = useState(null);
  const [convertedImgUrl, setConvertedImgUrl] = useState(null);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loadingAnti, setLoadingAnti] = useState(false);

  const handleLoad = async () => {
    if (!isLoading) {
      setLoading(true);
      await triggerLoadFile(message.imageUrl, message.id);
      setLoading(false);
    }
  };
  useEffect(() => {
    const loadFile = async () => {
      if (!isLoading && !imageUrl[message.id] && message.imageUrl) {
        setLoading(true);
        await triggerLoadFile(message.imageUrl, message.id);
        setLoading(false);
      }
    };

    loadFile();
  }, [message, imageUrl, isLoading, triggerLoadFile]);

  const messageClass = isSystemMessage ? 'chat-message-system' : isAlarmMessage ? 'chat-message-alarm' : `chat-message ${message.role}`;
  const showModal = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };
  const handleGoAnti = async (file_anti) => {
    setDrawerVisible(true);  // Открываем Drawer при получении ответа
    setAntiFileResponse(null);
    setConvertedImgUrl(null);
    try {
      setLoadingAnti(true);  // Начало загрузки
      const response = await fetchAntiFile(file_anti);
      const responseImg = await fetchConvertPDF(file_anti);

      setAntiFileResponse(response);
      if (responseImg && responseImg.url) {
        setConvertedImgUrl(responseImg.url);
      }
    } catch (error) {
      setAntiFileResponse({ pdfMeta: { msg: "Ошибка загрузки" } });
    } finally {
      setLoadingAnti(false);  // Завершение загрузки
    }
  };
  return (
      <>
        <div className={messageClass}>
          {!isSystemMessage && (
              <div className="chat-nickname">{message.nickName}</div>
          )}
          <div className="chat-text">
            {message.content}
            {message.type === 'image' && (
                <div className="chat-message-image">
                  {!imageUrl[message.id] ? (
                      <>
                        {isLoading ? (
                            <Spin />
                        ) : (
                            <button onClick={handleLoad}>Загрузить изображение</button>
                        )}
                      </>
                  ) : (
                      <Image width={220} src={imageUrl[message.id]} />
                  )}
                </div>
            )}
            {message.type === 'pdf' && (
                <div>
                  {message.type === 'pdf' && (
                      <div className="chat-message-pdf">
                        {!imageUrl[message.id] ? (
                            <>
                              {isLoading ? (
                                  <Spin />
                              ) : (
                                  <Button onClick={handleLoad}>Загрузить PDF</Button>
                              )}
                            </>
                        ) : (
                            <Button type="link" onClick={showModal}>Открыть PDF</Button>
                        )}
                      </div>
                  )}

                  <Modal
                      title={
                        <>
                       <span
                           onClick={() => window.open(imageUrl[message.id], '_blank')}
                           style={{cursor: 'pointer'}} // Устанавливаем стиль курсора
                       >
                          Просмотр PDF
                        </span>
                          <Button type="link" onClick={() => handleGoAnti(imageUrl[message.id])}>Проверить PDF</Button>
                        </>

                      }
                      open={visible}
                      onOk={handleClose}
                      onCancel={handleClose}
                      footer={null}
                      centered // Центрируем модальное окно
                      style={{ top: '0.5vh', minWidth: 0 }} // Минимальный отступ сверху
                      width="40%"
                  >
                    <iframe
                        src={imageUrl[message.id]}
                        width="100%"
                        height="800px"
                        style={{transformOrigin: 'top left'}}
                    />
                  </Modal>
                  <Modal
                      onClose={() => setDrawerVisible(false)}
                      onCancel={() => setDrawerVisible(false)}
                      open={drawerVisible}
                      footer={null}
                      width={300}
                  >
                    {loadingAnti ? (
                        <Skeleton active />
                    ) : (
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            {convertedImgUrl && (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <img src={convertedImgUrl} alt="Converted PDF" style={{ width: '100%' }} />
                                </div>
                            )}
                          </Col>
                          <Col span={24}>
                            {antiFileResponse && (
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                    {antiFileResponse.pdfMeta.msg}
                  </pre>
                                </div>
                            )}
                          </Col>
                        </Row>
                    )}
                  </Modal>
                </div>
            )}
            {message.type === 'video' && (
                <div className="chat-message-pdf">
                  {!imageUrl[message.id] ? (
                      <>
                        {isLoading ? (
                            <Spin />
                        ) : (
                            <button onClick={handleLoad}>Загрузить Видео</button>
                        )}
                      </>
                  ) : (
                      <button type="link" onClick={() => window.open(imageUrl[message.id], '_blank')}>Открыть Видео</button>
                  )}
                </div>
            )}
          </div>
          <div className="chat-meta">
            <span className="chat-time">{formatTime(message.createTime)}</span>
            {message.isRead && <span className="read-status"><CheckCircleOutlined /></span>}
          </div>
        </div>
      </>
  );
});

const Chat = observer(({ userNumber, orderNumber, tradeType, componentKey , sizeChat = 200 }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const chatWindowRef = useRef(null);
  const [message, setMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  useEffect(() => {
    setImageUrls({});
  }, [orderNumber]);

  useEffect(() => {
    const fetchData = async () => {
      if (!userNumber || !orderNumber) {
        setLoadingChat(false);
        setChatMessages([]);
        return;
      }
      setLoadingChat(true);
      try {
        const response = await fetchChatList(userNumber, orderNumber);
        setChatMessages(response.data);
      } catch (error) {
        addToast('error', 'Ошибка чата', "Не удалось загрузить данные чата");
      } finally {
        setLoadingChat(false);
      }
    };
    fetchData();
  }, [userNumber, orderNumber, componentKey ]);

  useEffect(() => {
    const chatElement = chatWindowRef.current;
    if (chatElement) {
      chatElement.scrollTop = chatElement.scrollHeight;
    }
  }, [chatMessages]);

  const observerId = useMemo(() => uniqueId('observer_'), []); // Генерируем уникальный ID на базе lodash или другой библиотеки

  useEffect(() => {
    const handleMessage = (data) => {
      if (data.orderNumber !== orderNumber) {
        return; // Игнорируем сообщения не для этого заказа
      }
      const baseChatMessage = {
        content: '',
        createTime: Math.floor(data.timeStamp),
        isRead: true,
        type: data.contentType,
        id: data.id
      };
      let chatMessage = {...baseChatMessage};
      if (data.name === 'message') {
        chatMessage = {
          ...chatMessage,
          content: data.message,
          imageUrl: data.contentUrl,
          nickName: data.userId.toString(),
          role: data.role,
        };
      } else if (data.name === 'status_order') {
        chatMessage = {
          ...chatMessage,
          content: statusDescriptions[data.orderStatus] || data.orderStatus,
          type: 'sys'
        };
      }
      setChatMessages(prevMessages => [...prevMessages, chatMessage]);
    };

    webSocketStore.addObserver(handleMessage, observerId);
    return () => {
      webSocketStore.removeObserver(observerId);
    };
  }, [orderNumber,observerId]); // Зависимость только от orderNumber

  const clearChat = async () => {
    const messageData = {
      type: "clearChat",
      userNumber: userNumber,
      orderNumber: orderNumber,
    };
    if (webSocketStore.socket && webSocketStore.socket.readyState === WebSocket.OPEN) {
      webSocketStore.socket.send(JSON.stringify(messageData));
      await addToast('info', 'Чат очищен', "Сообщения в чате удалены");
    } else {
      await addToast('error', 'Ошибка', "WebSocket не подключен.");
    }
  };
  const sendMessage = async () => {
    if (fileInfo && fileInfo.file) {
      const uploadingMessage = {
        id: Date.now(),
        role: "agent1",
        nickName: "Мерчант",
        content: `Идет загрузка файла: ${fileInfo.file.name}`, // Теперь здесь только текст
        createTime: Date.now()
      };
      setChatMessages(prevMessages => [...prevMessages, uploadingMessage]); // Добавляем сообщение о загрузке

      const formData = new FormData();
      formData.append('file', fileInfo.file);
      if (userNumber) {
        formData.append('userNumber', userNumber);
      }
      setIsUploading(true);
      try {
        const { imageName, imageUrl } = await uploadChatFile(formData, userNumber, orderNumber);
        const messageData = {
          type: fileInfo.type,
          userNumber: userNumber,
          orderNumber: orderNumber,
          imageName: imageName,
          imageUrl: imageUrl
        };
        if (webSocketStore.socket && webSocketStore.socket.readyState === WebSocket.OPEN) {
          webSocketStore.socket.send(JSON.stringify(messageData));
          setChatMessages(prevMessages => prevMessages.filter(msg => msg.id !== uploadingMessage.id)); // Удаляем сообщение о загрузке
        } else {
          await addToast('error', 'Ошибка', "WebSocket не подключен.");
        }
      } catch (error) {
        await addToast('error', 'Ошибка при загрузке файла', error);
        setChatMessages(prevMessages => prevMessages.filter(msg => msg.id !== uploadingMessage.id));
      } finally {
        setIsUploading(false);
        clearFile();
      }
    } else if (message.trim()) {
      const messageData = {
        type: "message",
        userNumber: userNumber,
        orderNumber: orderNumber,
        message: message
      };
      if (webSocketStore.socket && webSocketStore.socket.readyState === WebSocket.OPEN) {
        webSocketStore.socket.send(JSON.stringify(messageData));
        setMessage('');
      } else {
        await addToast('error', 'Ошибка', "WebSocket не подключен.");
      }
    } else {
      await addToast('warning', 'Внимание', "Введите сообщение или выберите файл");
    }
  };


  const fileInputRef = useRef(null);
  const handleQuickReply = (reply) => {
    setMessage(reply);
  };
  const triggerLoadFile = async (imageUrl, messageId) => {
    try {
      const response = await fetchChatFile(userNumber, imageUrl);
      const imageUrlFromResponse = response.data.url;
      setImageUrls((prevUrls) => ({
        ...prevUrls,
        [messageId]: imageUrlFromResponse
      }));
    } catch (error) {
      console.error('Failed to load image:', error);
    }
  };

  const handleFileSelectDirectly = (file) => {
    setIsUploading(true);
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setFilePreview(fileReader.result);  // Обновляем превью файла
    };
    if (file.type.startsWith('image/')) {
      fileReader.readAsDataURL(file);  // Чтение изображения для превью
    } else {
      setFilePreview(null);  // Очистка превью для не-изображений
    }
    setFileInfo({
      file: file,
      type: file.type.includes('pdf') ? 'messagePDF' : 'messageImage',
      userNumber: userNumber,
      orderNumber: orderNumber
    });
    setIsUploading(false);
  };


  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileSelectDirectly(file);
    }
    event.target.value = null; // Сброс значения input, чтобы повторно можно было загрузить тот же файл

  };

  const handleAttachmentClick = () => {
    fileInputRef.current.click();
  };

  const clearFile = () => {
    setFileInfo(null);
    setFilePreview(null);
    setFileInputKey(Date.now()); // Обновляем ключ, чтобы принудительно перерендерить input

  };

  const replies = tradeType === 'BUY' ? quickRepliesBuy : quickRepliesSell;

  const items = replies.map((reply, index) => ({
    key: String(index),
    label: (
        <div onClick={() => handleQuickReply(reply.text)}>
          <Tooltip placement='leftTop' title={reply.text}>
            <span>{reply.label}</span>
          </Tooltip>
        </div>
    ),
  }));

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFileSelectDirectly(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };
  const getRandomMessage = () => {
    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
  };
  return (
      <Card
          type="inner"
          size="small"
          title={<div style={{ cursor: 'pointer' }}>Чат</div>}
          bordered={false}
          style={{ position: 'relative', height: '100%' }}
      >
        <div className="chat-window"
             ref={chatWindowRef}
             style={{ height: `calc(100vh - ${sizeChat}px)` }}
             onDragOver={handleDragOver}
             onDragLeave={handleDragLeave}
             onDrop={handleDrop}
             key={orderNumber}
        >
          <List loading={loadingChat}>
            {chatMessages.map((message, index) => (
                <ChatMessage
                    key={`${index}-${message.createTime}`}  // используется только React'ом и не передается в компонент
                    index={index}  // Теперь индекс доступен внутри компонента как prop
                    createTime={message.createTime}  // createTime тоже передается как prop
                    message={message}
                    imageUrl={imageUrls}
                    triggerLoadFile={triggerLoadFile}
                />
            ))}
          </List>
        </div>

        <div className="input-panel" style={{ bottom: 0, left: 0, right: 4, padding: '0px' }}>
          <Row gutter={[16, 16]}>
            <Col span={16}>
              {fileInfo ? (
                  <Upload
                      className="upload-full-height"
                      accept=".jpg,.jpeg,.png,.pdf"
                      listType="picture"
                      fileList={fileInfo ? [{
                        uid: '-1',
                        name: fileInfo.file.name,
                        status: 'done',
                        url: filePreview
                      }] : []}
                      onRemove={clearFile}
                      beforeUpload={() => false}
                      showUploadList={{
                        showRemoveIcon: true,
                        removeIcon: <DeleteOutlined onClick={clearFile} />,
                      }}
                  >
                    {!fileInfo && '+ Upload'}

                  </Upload>

              ) : (
                  <Input.TextArea
                      rows={3}
                      style={{ resize: 'none' }}
                      placeholder="Введите сообщение или перетащите файл"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          sendMessage();
                        }
                      }}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      className={isDragOver ? "drag-over" : ""}
                  />
              )}
            </Col>
            <Col span={8}>
              <Row>
                <Col span={8}>
                  <Tooltip
                      placement='top'
                      title={tradeType === "SELL" ? "Запрос чека" : "Отпустите монеты"}
                  >
                    <Button
                        icon={<SnippetsOutlined />}
                        shape="circle"
                        onClick={() => {
                          const message = tradeType === "SELL"
                              ? "Предоставьте пожалуйста банковский чек(квитанцию) в формате PDF - это поможет мне проверить поступление"
                              : "Проверьте пожалуйста поступление и отпустите монеты. Нажав на кнопку 'Перевести'";
                          handleQuickReply(message);
                        }}
                    >
                    </Button>
                  </Tooltip>
                  <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileSelect}
                      accept=".jpg,.jpeg,.png,.pdf"
                      style={{ display: 'none' }}
                  />
                </Col>
                <Col span={8}>
                  <Tooltip
                      placement='top'
                      title="@telega"
                  >
                    <Button
                        icon={<StarOutlined />}
                        shape="circle"
                        onClick={() => {
                          const telegramUser = MerchStore.getInfoTelegram(userNumber);
                          handleQuickReply(`@${telegramUser}`);
                        }}
                    >
                    </Button>
                  </Tooltip>
                </Col>
               <Col span={8}>
                  <Dropdown
                      menu={{
                        items,
                      }}
                      trigger={['click']}
                  >
                    <Button
                        shape="circle"
                        icon={<PlusOutlined />}
                        style={{ marginBottom: '4px' }}
                    />
                  </Dropdown>
                </Col>


              </Row>
              <Row>
                <Col span={8}>
                  <Button
                      icon={<SendOutlined />}
                      shape="circle"
                      onClick={() => {
                        sendMessage(message);
                        setMessage('');
                      }}
                  >
                  </Button>
                </Col>
                <Col span={8}>
                  <Tooltip
                      placement='top'
                      title="Округлите"
                  >
                    <Button
                        icon={<DollarOutlined  />}
                        shape="circle"
                        onClick={() => {
                          const randomMessage = getRandomMessage();
                          handleQuickReply(randomMessage);
                        }}
                    >
                    </Button>
                  </Tooltip>
                </Col>
                <Col span={8}>
                  <Button
                      shape="circle"
                      icon={<PaperClipOutlined />}
                      onClick={handleAttachmentClick}
                  />

                  <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileSelect}
                      accept=".jpg,.jpeg,.pdf"
                      style={{ display: 'none' }}
                  />
                </Col>
              </Row>

            </Col>
          </Row>
        </div>
      </Card>
  );
});

export default Chat;
