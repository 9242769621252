import React, { useEffect, useState } from 'react';
import BtnStatusInfo from "./BtnStatusInfo";
import {Card, List, Spin, Skeleton, Tooltip,Badge} from 'antd';
import { LockOutlined, InfoCircleOutlined, FileTextOutlined, UnorderedListOutlined } from "@ant-design/icons";
import {handleCopy} from "../LogicComponents";
import {observer} from "mobx-react";
import {formatNumber} from "../numericFunc";
import {formattedDate} from "../dateFunc";
import {BankIcon} from "../CommonComponents";
import {OrderHistoryModal,NotesModal,AgentInfoModal} from "./OrderHistoryModal";
import {crmAgentInfo, crmOrderInfo, crmOrdersInfo,crmOrderAdd} from "../../config/crm/apiNote";
import {tradeStore} from "../../state/TradeStore";
function renderInfoItem(label, value, tooltip = null, copy = null, defaultValue = '') {
    const content = (
        <span className="list-item-value">
            {copy ? (
                <span style={{ cursor: 'pointer' }} onClick={() => handleCopy(copy)}>{value}</span>
            ) : (
                value || defaultValue
            )}
        </span>
    );

    return (
        <List.Item className="list-item">
            <span className="list-item-label">{label}:</span>
            {tooltip ? (
                <Tooltip placement="left" title={tooltip}>
                    {content}
                </Tooltip>
            ) : (
                content
            )}
        </List.Item>
    );
}

const renderSkeletonItem = (leftWidth, rightWidth, label) => (
    <List.Item className="list-item">
        <div style={{ width: leftWidth, float: 'left' }}>
            <span className="list-item-label">{label}:</span>
        </div>
        <div style={{ width: rightWidth, float: 'right' }}>
            <Skeleton.Input size={14} active title={{ width: '100%' }} block={true} />
        </div>
    </List.Item>
);

function SkeletonCard() {
    const skeletonWidths = [
        { label: 'Дата', left: '20%', right: '50%' },
        { label: 'Ордер', left: '20%', right: '50%' },
        { label: 'Актив', left: '20%', right: '50%' },
        { label: '.....', left: '50%', right: '50%' },
        { label: 'Сумма', left: '20%', right: '50%' },
    ];

    return (
        <Card
            size="small"
            bordered={false}
            style={{ height: '100%' }}
            title={<div style={{ cursor: 'pointer' }}>Информация</div>}
        >
            <Spin tip="Загрузка...">
                <List>
                    {skeletonWidths.map((widths, index) => (
                        <React.Fragment key={index}>
                            {renderSkeletonItem(widths.left, widths.right, widths.label)}
                        </React.Fragment>
                    ))}
                    <List.Item>
                        <button className={`btn btn-warning btn-sm w-100 disabled`}>
                            <LockOutlined />
                        </button>
                    </List.Item>
                </List>
            </Spin>
        </Card>
    );
}


const OrderInfo = observer(({ orderInfo, loading, setKey,done }) => {
    const [agentInfoStatus, setAgentInfoStatus] = useState(false);
    const [orderNotesStatus, setOrderNotesStatus] = useState(false);
    const [relatedOrdersStatus, setRelatedOrdersStatus] = useState('gray');
    const [isOrderHistoryModalVisible, setIsOrderHistoryModalVisible] = useState(false);
    const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
    const [isAgentInfoModalVisible, setIsAgentInfoModalVisible] = useState(false);
    const [hasActiveOrders, setHasActiveOrders] = useState(false);
    const [relatedOrdersCount, setRelatedOrdersCount] = useState(null);

    useEffect(() => {
        setAgentInfoStatus(false);
        setOrderNotesStatus(false);
        setRelatedOrdersCount(null);
        setHasActiveOrders(false);
        setIsOrderHistoryModalVisible(false);
        setIsNotesModalVisible(false);
        setIsAgentInfoModalVisible(false);
        if (!loading) {
            const fetchInfo = async () => {
                try {
                    const agentResponse = await crmAgentInfo({ agentUserId: orderInfo.agentUserId });
                    setAgentInfoStatus(agentResponse.data.length > 0);

                    const orderNoteResponse = await crmOrderInfo({ orderNumber: orderInfo.orderNumber });
                    setOrderNotesStatus(orderNoteResponse.data.length > 0);

                    const relatedOrdersResponse = await crmOrdersInfo({ agentUserId: orderInfo.agentUserId });
                    const relatedOrders = relatedOrdersResponse.data || [];
                    setRelatedOrdersCount(relatedOrdersResponse.total || 0);

                    const hasActive = relatedOrders.some(order => order.activeOrders);
                    setHasActiveOrders(hasActive);
                } catch (error) {
                    console.error("Error fetching order info:", error);
                }
            };

            fetchInfo();
        }
    }, [loading, orderInfo.agentUserId, orderInfo.orderNumber]);

    return (
        <>
            {loading || !orderInfo || Object.keys(orderInfo).length === 0 ? (
                <SkeletonCard />
            ) : (
                <Card
                    size="small"
                    bordered={false}
                    style={{ height: '100%' }}
                    title={
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <div
                                    onClick={() => setKey(prevKey => prevKey + 1)}
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                >
                                    Информация

                                    {orderInfo.bankNamePars.map((bank, index) => (
                                        <BankIcon key={index} bank={bank} size={20} style={{verticalAlign: 'unset' }}/>
                                    ))}
                                    {formatNumber(orderInfo.totalPrice, true)}
                                </div>
                                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title="Информация о контрагенте">
                                        <InfoCircleOutlined
                                            style={{ color: agentInfoStatus ? 'green' : 'gray', marginLeft: 8 }}
                                            onClick={() => setIsAgentInfoModalVisible(true)}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Пометка к ордеру">
                                        <FileTextOutlined
                                            style={{ color: orderNotesStatus ? 'green' : 'gray', marginLeft: 8 }}
                                            onClick={() => setIsNotesModalVisible(true)}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Смежные ордера">
                                        {relatedOrdersCount === null ? (
                                            <Skeleton.Avatar
                                                active
                                                size="small"
                                                shape="circle"
                                                style={{ width: 20, height: 20, marginLeft: 8  }}
                                            />
                                        ) : (
                                            <Badge
                                                count={relatedOrdersCount}
                                                overflowCount={99}
                                                style={{ backgroundColor: hasActiveOrders ? 'red' : 'green', marginLeft: 8 }}
                                                onClick={() => setIsOrderHistoryModalVisible(true)}
                                            >
                                            </Badge>
                                        )}
                                    </Tooltip>
                                </div>
                            </div>
                        </>
                    }
                >
                    <List>
                        {renderInfoItem('Дата', formattedDate(orderInfo.tsCreate))}
                        {renderInfoItem('Ордер', orderInfo.orderNumber, `${orderInfo.userNickName} ${orderInfo.marketName}`, orderInfo.orderNumber)}
                        {renderInfoItem('Актив', `${formatNumber(orderInfo.amount, true, orderInfo.asset)} по ${formatNumber(orderInfo.price, true)}`)}
                        {orderInfo.tradeType === 'BUY'
                            ? renderInfoItem(
                                <span>Карта <BankIcon bank={orderInfo.realCard} size={22} /></span>,
                                orderInfo.payCard,
                                orderInfo.agentFIO,
                                orderInfo.payCard
                            )
                            : renderInfoItem('ФИО', orderInfo.agentFIO, null)
                        }
                        {renderInfoItem('Сумма', formatNumber(orderInfo.totalPrice, true), null, orderInfo.totalPrice)}
                        <List.Item>
                            <BtnStatusInfo orderInfo={orderInfo} done={done}/>
                        </List.Item>
                    </List>
                </Card>
            )}
            <OrderHistoryModal
                visible={isOrderHistoryModalVisible}
                onClose={() => setIsOrderHistoryModalVisible(false)}
                agentUserId={orderInfo.agentUserId}
            />
            <NotesModal
                visible={isNotesModalVisible}
                onClose={() => setIsNotesModalVisible(false)}
                orderNumber={orderInfo.orderNumber}
            />
            <AgentInfoModal
                visible={isAgentInfoModalVisible}
                onClose={() => setIsAgentInfoModalVisible(false)}
                agentUserId={orderInfo.agentUserId}
            />
        </>
    );
});

export default OrderInfo;