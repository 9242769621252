import React, { useState, useEffect} from 'react';
import { Layout, Menu, Drawer, Button, Card, Typography, List, Modal,Tag } from 'antd';
import { UserOutlined, MenuOutlined, CloseOutlined,DeleteOutlined,SoundOutlined } from '@ant-design/icons';
import { NavLink, useLocation } from 'react-router-dom';
import routeConfig from '../routesConfig';
import authStore from "../state/AuthStore";
import {tradeStore} from "../state/TradeStore";
import {observer} from "mobx-react";
import {formatNumber} from "../components/numericFunc";
import ConsoleMain from "../components/OrderDetail/ConsoleMain";
import {getNickNameByUserNumber} from "../config/constantsOld";
import MerchStore from "../state/MerchStore";

const { Header, Sider } = Layout;
const { Text } = Typography;

const NotificationList = observer(() => {
    const reversedNotifications = [...tradeStore.notifications].reverse();
    const [soundStatus, setSoundStatus] = useState(tradeStore.soundStatus);

    const handleSoundToggle = () => {
        tradeStore.setSoundStatus(!soundStatus);
        setSoundStatus(!soundStatus);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);  // Добавлено новое состояние
    const openModal = (data) => {
        setModalData(data);
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const renderNotification = (notification) => {
        const { orderStatus, tradeType, totalPrice, timestamp, userNumber, orderNumber } = notification;
        let statusText = '';
        let sumColor = '';
        let statusColor = '';

        switch (orderStatus) {
            case 'COMPLETE':
                statusText = 'Завершен';
                statusColor = 'green';

                break;
            case 'APPEAL':
                statusText = 'Апелляция';
                statusColor = 'red';

                break;
            case 'PAID':
                statusText = 'Оплачен';
                statusColor = 'gray';

                break;
            case 'CANCEL':
                statusText = 'Отмена';
                statusColor = 'gold';

                break;
            default:
                statusText = 'Неизвестно';
                statusColor = 'default';

                break;
        }

        sumColor = tradeType === 'BUY' ? 'green' : 'red'; // Задаем цвет суммы в зависимости от типа ордера

        return (
            <List.Item
                className={`notification-item`}
           >
                <List.Item.Meta
                    title={
                        <div   style={{cursor: 'pointer' }}  onClick={() => openModal({ tradeType, userNumber, orderNumber })}>
                            <Text
                                style={{ color: sumColor, cursor: 'pointer' }}
                                onClick={() => openModal({ tradeType, userNumber, orderNumber })}
                            >
                                {`${formatNumber(totalPrice)} `}
                            </Text>
                            {orderStatus === 'CANCEL' && (
                                <>
                                    <Text>{MerchStore.getNickNameByUserNumber(userNumber)}</Text>
                                </>
                            )}
                        </div>
                    }
                    description={
                        <div>
                            <Text>{timestamp} </Text>

                            <Text> - </Text>
                            <Tag color={statusColor}>{statusText}</Tag> {/* Выводим текстовое представление статуса с тегом */}
                        </div>
                    }
                />
            </List.Item>
        );
    };

    return (
        <>
            <Card
                title="PUSH"
                size="small"
                extra={
                <>
                    <>
                        <Button type="link" onClick={handleSoundToggle}>
                            <SoundOutlined style={{ color: soundStatus ? 'green' : 'red' }} />
                        </Button>
                        <Button type="link" onClick={() => tradeStore.clearNotifications()}>
                            <DeleteOutlined />
                        </Button>

                    </>
                </>

            }
                style={{
                    width: 'auto',

                    maxHeight: 'calc(60vh - 200px)', // Устанавливаем максимальную высоту
                    overflow: 'auto' // Добавляем скролл, если контент не помещается
                }}
            >
                <List
                    dataSource={reversedNotifications}
                    renderItem={(notification) => renderNotification(notification)}
                />
            </Card>
            <Modal
                title="Детали заказа"
                open={isModalOpen}
                onCancel={closeModal}
                footer={null}
                width={800}
                style={{ alignItems: 'center', justifyContent: 'center', top: 8 }}
            >
                {modalData && (
                    <ConsoleMain
                        tradeType={modalData.tradeType}
                        userNumber={modalData.userNumber}
                        orderNumber={modalData.orderNumber}
                        key={refreshKey}
                        setKey={setRefreshKey}
                        sizeChat={250}
                    />
                )}
            </Modal>
        </>


    );
});

const MySider = observer(({ isMobile }) => {
    const { user, logout } = authStore;
    const [drawerVisible, setDrawerVisible] = useState(false);
    const location = useLocation();
    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };
    useEffect(() => {
    }, [location.pathname]);
    const closeDrawer = () => {
        setDrawerVisible(false);
    };
    const isNotificationVisible = ['/nocard', '/pay', '/reception'].includes(location.pathname);

    const menuItems = routeConfig.map((route, index) => {
        if (route.children) {
            const subMenuItems = route.children.map((childRoute, childIndex) => ({
                label: <NavLink to={childRoute.path} onClick={closeDrawer}>{childRoute.label}</NavLink>,
                key: childRoute.path,
                icon: childRoute.icon,
            }));
            return {
                label: route.label,
                key: route.path || `submenu-${index}`,
                icon: route.icon,
                children: subMenuItems,
            };
        } else {
            return {
                label: <NavLink to={route.path} onClick={closeDrawer}>{route.label}</NavLink>,
                key: route.path,
                icon: route.icon,
            };
        }
    });
    menuItems.push({
        label: user.email,
        key: 'sub1',
        icon: <UserOutlined />,
        children: [
            { label: <NavLink to="/profile" onClick={closeDrawer}>Профиль</NavLink>, key: 'profile' },
            { label: 'Выйти', key: 'logout', onClick: logout },
        ],
    });
    return (
        <>
            {isMobile ? (
                <>
                    <Header style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1000,
                        height: '6vh',
                        background: 'rgba(0, 0, 0, 0.2)',
                        backdropFilter: 'blur(5px)',
                        color: '#ffffff' // Цвет текста
                    }}>
                        <Button type="primary" onClick={toggleDrawer} style={{ position: 'absolute', right: 10 }}>
                            <MenuOutlined style={{ color: '#ffffff' }} /> {/* Цвет иконки */}
                        </Button>
                    </Header>
                    <Drawer
                        title={<span style={{ fontWeight: 'bold', color: '#ffffff' }}>Меню</span>}
                        placement="left"
                        closable={true}
                        onClose={toggleDrawer}
                        open={drawerVisible}
                        closeIcon={<CloseOutlined style={{ fontSize: 20, color: '#ffffff' }} />}
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', color: '#ffffff' }}
                    >
                        <Menu theme="dark" mode="inline" items={menuItems} selectedKeys={[location.pathname]} />

                    </Drawer>

                </>
            ) : (
                <Sider collapsible collapsed={drawerVisible} onCollapse={setDrawerVisible}>
                    <div className="logo" />
                    <Menu theme="dark" mode="inline" items={menuItems} selectedKeys={[location.pathname]} />

                    {isNotificationVisible && !drawerVisible && (
                        <div style={{ position: 'absolute', bottom: 40, width: '100%' }}>
                            <NotificationList />
                        </div>
                    )}
                </Sider>

            )}

        </>
    );
});

export default MySider;
