import React, { useEffect, useState } from 'react';
import { Table, Tooltip,Skeleton  } from 'antd';
import { OneColCenter } from "../../Layouts/formRow/2col";
import MerchStore from "../../state/MerchStore";
import {fetchUnlockT1} from "../../config/api/apiGeneral";
import {formatNumber} from "../../components/numericFunc";
const formatTimestamp = ts => {
    const date = new Date(ts);
    return date.toLocaleString();
};
const calculateTotals = (data) => {
    const totals = {
        key: 'totals',
        nickName: 'Итого',
        total: 0,
    };

    for (let i = 1; i <= 24; i++) {
        totals[`h${i}`] = { total: 0, orders: [] };
    }

    data.forEach(item => {
        if (item.total !== 'loading') {
            totals.total += item.total;
            for (let i = 1; i <= 24; i++) {
                totals[`h${i}`].total += item[`h${i}`].total;
            }
        }
    });

    return totals;
};

const MerchTable = () => {
    const [data, setData] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState({});
    const merchNumbers = MerchStore.userNumbers;

    useEffect(() => {
        const filteredNumbers = merchNumbers.filter(item => item.marketNumber === 2);

        // Инициализация данных и статуса загрузки для каждого пользователя
        const initialData = filteredNumbers.map(item => {
            return {
                key: item.userNumber,
                nickName: item.nickName,
                total: 'loading',
                ...Array.from({ length: 24 }, (_, i) => ({
                    [`h${i + 1}`]: { total: 0, orders: [] }
                })).reduce((acc, cur, i) => ({ ...acc, [`h${i + 1}`]: cur[`h${i + 1}`] }), {})
            };
        });
        setData(initialData);

        const initialLoadingStatus = {};
        filteredNumbers.forEach(item => {
            initialLoadingStatus[item.userNumber] = true;
        });
        setLoadingStatus(initialLoadingStatus);

        const fetchData = async () => {
            // Получение данных для каждого подходящего userNumber
            const results = await Promise.all(filteredNumbers.map(item => fetchUnlockT1(item.userNumber)));

            // Обновление данных после загрузки
            const updatedData = filteredNumbers.map((item, index) => {
                const response = results[index];
                if (response.code === 1000) {
                    const result = response.data;
                    const hoursData = {};
                    for (let i = 1; i <= 24; i++) {
                        hoursData[`h${i}`] = {
                            total: result.unlock[`${i}h`]?.total || 0,
                            orders: result.unlock[`${i}h`]?.orders || []
                        };
                    }
                    setLoadingStatus(prevStatus => ({ ...prevStatus, [item.userNumber]: false }));
                    return {
                        key: item.userNumber,
                        nickName: item.nickName,
                        total: result.total,
                        ...hoursData,
                    };
                }
                return {
                    key: item.userNumber,
                    nickName: item.nickName,
                    total: 'loading',
                    ...Array.from({ length: 24 }, (_, i) => ({
                        [`h${i + 1}`]: { total: 0, orders: [] }
                    })).reduce((acc, cur, i) => ({ ...acc, [`h${i + 1}`]: cur[`h${i + 1}`] }), {})
                };
            });

            const totals = calculateTotals(updatedData.filter(item => item.total !== 'loading'));
            setData([...updatedData, totals]);

            const updatedLoadingStatus = {};
            filteredNumbers.forEach(item => {
                updatedLoadingStatus[item.userNumber] = false;
            });
            setLoadingStatus(updatedLoadingStatus);
        };

        fetchData();
    }, [merchNumbers]);

    const columns = [
        {
            title: 'Никнейм',
            dataIndex: 'nickName',
            key: 'nickName',
            fixed: 'left',
            width: 150,
        },
        ...Array.from({ length: 24 }, (_, i) => ({
            title: `${i + 1} час`,
            dataIndex: `h${i + 1}`,
            key: `h${i + 1}`,
            width: 100,
            render: ({ total, orders }) => {
                if (total === 0) {
                    return <span>{total}</span>;
                }
                const orderDetails = orders.map(order => (
                    <div key={order.tsEnd}>
                        <div>Сумма: {formatNumber(order.amount, true, "USDT")}</div>
                        <div>Время: {formatTimestamp(order.tsEnd)}</div>
                    </div>
                ));
                return (
                    <Tooltip title={orderDetails}>
                        <span>{formatNumber(total, false)}</span>
                    </Tooltip>
                );
            },
        })),
        {
            title: 'Итого',
            dataIndex: 'total',
            key: 'total',
            fixed: 'right',
            width: 100,
            render: (total, record) => (
                loadingStatus[record.key] && record.key !== 'totals'
                    ? <Skeleton.Input style={{ width: 100 }} active size="small" />
                    : formatNumber(total, false)
            ),
        },
    ];

    return (
        <OneColCenter
            col1={() => (
                <Table
                    dataSource={data}
                    columns={columns}
                    scroll={{ x: 3000 }} // Добавляем горизонтальную прокрутку, если нужно
                />
            )}
        />
    );
};

export default MerchTable;
