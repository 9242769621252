import { makeAutoObservable } from "mobx";
import { auth } from '../config/google-fire/firebase-config';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { sendSessionDataToServer, checkActiveSession,fetchUserInfo } from '../config/crm/apiCRM';
import merchStore from "./MerchStore";
const generateSessionId = () => {
    return Math.random().toString(36).substring(2);
};

const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
    const browser = userAgent.includes("Firefox") ? "Firefox" :
        userAgent.includes("Chrome") ? "Chrome" :
            userAgent.includes("Safari") ? "Safari" : "Other";
    const os = userAgent.includes("Win") ? "Windows" :
        userAgent.includes("Mac") ? "MacOS" :
            userAgent.includes("Linux") ? "Linux" : "Other";

    return { browser, os };
};

class AuthStore {
    user = null;
    loading = true;
    dataUser = null;
    token = null;
    merchStore = null; // Добавляем ссылку на MerchStore


    constructor() {
        makeAutoObservable(this);
        this.loadDataUserFromLocalStorage();
        this.merchStore = merchStore;

        this.observeAuthChanges();
        onAuthStateChanged(auth, (user) => {
            this.setUserStand(user);
            if (user) {
                // Здесь можно также обновить dataUser, если это необходимо
            } else {
                this.dataUser = null;
            }
        });
    }
    setUserStand(user) {
        this.user = user;
    }
    setLoad(loading) {
        this.loading = loading;
    }
    setDataUserNull(dataUser) {
        this.dataUser = dataUser;
    }

    loadDataUserFromLocalStorage() {
        const storedDataUser = localStorage.getItem('dataUser');
        if (storedDataUser) {
            this.dataUser = JSON.parse(storedDataUser);
        }
    }
    setDataUser(dataUser) {
        this.dataUser = dataUser;
        const datik = JSON.stringify(dataUser)
        localStorage.setItem('dataUser', datik);
        localStorage.setItem('team', dataUser.team);
    }
    get isAuthenticated() {
        return !!this.user;
    }

    observeAuthChanges() {
        onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                const isActiveSession = await checkActiveSession(currentUser.uid, localStorage.getItem('sessionId'));
                if (isActiveSession) {
                    this.setUserStand(currentUser);
                } else {
                    this.setUserStand(null);
                    this.setDataUserNull(null);
                    localStorage.removeItem('sessionId');
                    localStorage.removeItem('uid');
                }
            } else {
                this.setUserStand(null);
                this.setDataUserNull(null);
            }
            this.setLoad(false);
        });
    }


  login =    async(email, password)=> {
        try {
            this.setLoad(true);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const uid = userCredential.user.uid;
            const newSessionId = generateSessionId();
            const sessionData = {
                uid: uid,
                loginTime: Date.now(),
                logoutTime: null,
                deviceInfo: getDeviceInfo(),
                sessionId: newSessionId,
            };

            await sendSessionDataToServer(sessionData, 'login');
            localStorage.setItem('sessionId', newSessionId);
            localStorage.setItem('uid', uid);
            this.setUserStand(userCredential.user);
            const infoDataUser = await fetchUserInfo(this.user.uid)
            this.setDataUser(infoDataUser.data)
            this.merchStore.getDataMerch();

        } catch (error) {
            throw error;
        } finally {
            this.setLoad(false);
        }
    }

    logout = async() => {
        try {
            this.setLoad(true);

            const sessionData = {
                uid: this.user?.uid,
                logoutTime: Date.now(),
                logoutType: "user",
            };

            await sendSessionDataToServer(sessionData, 'logout');
            await signOut(auth);

            localStorage.clear();  // Очистка всех данных localStorage
            this.setUserStand(null);
            this.setDataUserNull(null);
        } catch (error) {
            console.error('Ошибка при выходе из системы:', error);
        } finally {
            this.setLoad(false);
        }
    }
}

const authStore = new AuthStore();
export default authStore;

