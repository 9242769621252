import {message, notification} from 'antd';
import MerchStore from "../../state/MerchStore";

export const addToast = (type, message = 'Уведомление', description) => {
    notification[type]({
        message: message,
        description: description,
        duration: 1.5,  // Задаём длительность отображения уведомления в 1 секунду
    });
};

export const addToastWar = (description) => {
    notification["warning"]({
        message: "Внимание!!!",
        description: (
            <div>
                {description.map((line, index) => (
                    <p key={index} style={{ margin: 0 }}>
                        {line}
                    </p>
                ))}
            </div>
        ),
        placement: "topLeft",
        duration: 3,  // Задаём длительность отображения уведомления
    });
};
export const addToastUser = (userNumber, type, description) => {
    const userInfo = MerchStore.getUserInfoByUserNumber(userNumber)
    addToast(type, `${userInfo.marketName} ${userInfo.nickName}`,description);
};

export const addMessageUser = (userNumber, type, description) => {
    const userInfo = MerchStore.getUserInfoByUserNumber(userNumber)
    const info = `${userInfo.marketName} ${userInfo.nickName} : ${description}`
    message[type](info,1);
};


export const addMessage = (type, description) => {
    message[type](description,1);
};
