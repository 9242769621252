import React, { useEffect, useState } from 'react';
import {Tabs, Tooltip, Skeleton, Card, Button, Row, Col, Avatar, Modal, Input,Tag} from 'antd';

import {
    fetchMerchMailInfo,
    fetchMerchMarketInfo,
    fetchMerchGoogleInfo,
    fetchRebootProxy,fetchInfoProxy
} from "../../config/crm/apiMerch";

import {handleCopy} from "../../components/LogicComponents";
import { MarketIcon, renderCryptoAddress} from "../../components/CommonComponents";
import { SendOutlined, CopyOutlined, KeyOutlined,UserOutlined,LockOutlined,IeOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import authStore from "../../state/AuthStore";
import {infoSession, loadSession, uploadSession,fetchProxyValid} from "../../config/api/apiGeneral";
import {addToast} from "../../components/Notification/Toast";
import MerchStore from "../../state/MerchStore";
import { observer } from 'mobx-react';

const { TabPane } = Tabs;
const { Meta } = Card;
const { TextArea } = Input;

const UpdateTimeCell = ({ userNumber }) => {
    const [loading, setLoading] = useState(true);
    const [updateTime, setUpdateTime] = useState('');
    const formattedDate = (timestamp) => {
        if (!timestamp || isNaN(new Date(timestamp).getTime())) {
            return 'Нет данных';
        }
        return new Intl.DateTimeFormat('ru-RU', {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', second: '2-digit'
        }).format(new Date(timestamp));
    };


    useEffect(() => {
        // Немедленно вызываемая асинхронная функция внутри useEffect
        (async () => {
            try {
                const sessionInfo = await infoSession(userNumber);
                setUpdateTime(formattedDate(sessionInfo.updateTime));
            } catch (error) {
                setUpdateTime('Ошибка загрузки');
            } finally {
                setLoading(false);
            }
        })();

    }, [userNumber]); // Зависимости useEffect

    if (loading) {
        return <Skeleton.Button active={true} size="small"  block={true} />

    }
    return updateTime;
};

const ProxyTime = ({ userNumber }) => {
    const [loading, setLoading] = useState(true);
    const [updateTime, setUpdateTime] = useState('');
    const getStatus = (time) => {
        if (time <= 1.3) {
            return { text: 'Отличное соединение', color: 'green' };
        } else if (time <= 3) {
            return { text: 'Хорошее соединение', color: 'yellow' };
        } else if (time <= 5) {
            return { text: 'Более-менее соединение', color: 'orange' };
        } else if (time <= 10) {
            return { text: 'Плохое соединение', color: 'pink' };
        } else {
            return { text: 'Критическое соединение', color: 'red' };
        }
    };



    useEffect(() => {
        // Немедленно вызываемая асинхронная функция внутри useEffect
        (async () => {
            try {
                const sessionInfo = await fetchProxyValid(userNumber);
                setUpdateTime(sessionInfo.time);
            } catch (error) {
                setUpdateTime('Ошибка загрузки');
            } finally {
                setLoading(false);
            }
        })();

    }, [userNumber]); // Зависимости useEffect

    if (loading) {
        return <Skeleton.Button active={true} size="small"  block={true} />

    }
    if (updateTime === 'Ошибка загрузки') {
        return <div>Ошибка загрузки</div>;
    }
    const { text, color } = getStatus(updateTime);

    return (
        <div>
            <b>Статус:</b><Tag color={color}>{text}</Tag> <br/> <b>Время отклика:</b> {updateTime} секунд
        </div>
    );
};

const ModalSessionLoad = ({ userNumber, nickName }) => {
    const [sessionData, setSessionData] = useState('');

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const json = await loadSession(userNumber); // Получаем объект JSON
                const jsonString = JSON.stringify(json, null, 2); // Преобразовываем JSON в строку с форматированием
                setSessionData(jsonString);
            } catch (error) {
                await addToast('error','Ошибка',`Не удалось загрузить сессию ${nickName}`);
            }



        };

        fetchSession();
    }, [userNumber]); // Зависимость от userNumber гарантирует обновление данных при изменении этого параметра

    return (
        <div>
            <TextArea
                value={sessionData}
                rows={8}
                placeholder="Сессия"
                style={{ marginBottom: '1rem' }}
            />
        </div>
    );
};
const ModalSessionSend = ({ userNumber, nickName, marketNumber, marketName }) => {
    const [session, setSession] = useState('');
    const [token, setToken] = useState('');
    const handleSubmit = async () => {
        if (!userNumber) return;

        // Проверяем, не пустая ли строка в полях сессии и токена
        if (!session.trim() || ((marketNumber === 1 || marketNumber === 4) && !token.trim())) {
            await addToast('warning','Внимание','Поля сессии и токена не могут быть пустыми');
            return;
        }

        try {

            const sessionFileName = `${userNumber}_session.json`;
            const sessionBlob = new Blob([session.trim()], { type: 'application/json' });
            const sessionFormData = new FormData();
            sessionFormData.append('file', sessionBlob, sessionFileName);

            const sessionResponse = await uploadSession(sessionFormData);
            if (sessionResponse.status) {
                await addToast('success','Успешно',`Сессия для пользователя ${nickName} успешно отправлена`);
            } else {
                await addToast('error','Ошибка',`Не удалось отправить сессию для пользователя ${nickName}.`);
                return; // Прерываем выполнение, если отправка сессии не удалась
            }


            if (marketNumber === 1 || marketNumber === 4) {
                const tokenFileName = `${userNumber}_token.json`;
                const tokenBlob = new Blob([JSON.stringify({ token: token.trim() })], { type: 'application/json' });
                const tokenFormData = new FormData();
                tokenFormData.append('file', tokenBlob, tokenFileName);

                const tokenResponse = await uploadSession(tokenFormData);
                if (tokenResponse.status) {
                    await addToast('success','Успешно',`Токен для пользователя ${nickName} успешно отправлена`);

                } else {
                    await addToast('error','Ошибка',`Не удалось отправить токен для пользователя ${nickName}`);
                }
            }
            setSession('');
            setToken('');

        } catch (error) {
            await addToast('error','Ошибка',`Ошибка при отправке данных для пользователя ${nickName}`);
        }
    };
    return <div>
        <TextArea
            value={session}
            onChange={e => setSession(e.target.value)}
            rows={8}
            placeholder="Сессия"
            style={{ marginBottom: '1rem' }}
        />

        {(marketNumber === 1 || marketNumber === 4) && (
            <Input
                value={token}
                onChange={e => setToken(e.target.value)}
                placeholder="Токен"
                style={{ marginBottom: '1rem' }}
            />
        )}

        <Button type="primary" onClick={handleSubmit}>
            Отправить
        </Button>
    </div>
};
const CryptoInfo = ({ type, value, nickname, marketName, handleCopy, renderCryptoAddress }) => {
    return value ? (
        <span onClick={() => handleCopy(value, `${type} ${nickname} ${marketName}`)} style={{ cursor: 'pointer' }}>
            <b>{type}:</b> {renderCryptoAddress(value)}
        </span>
    ) : (
        <span>
            <b>{type}:</b> Отсутствует
        </span>
    );
};

const RebootProxyButton = ({ userNumber, userNickname }) => {
    const [loading, setLoading] = useState(false);
    const [timestamp, setTimestamp] = useState('');
    const [error, setError] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const handleRebootClick = async () => {
        setError(false);
        setLoading(true);
        setModalVisible(true); // Показываем модальное окно сразу

        try {
            const proxyData = await fetchInfoProxy(userNumber);
            setTimestamp(new Date(proxyData.data.timestamp).toLocaleString());
            setLoading(false);
        } catch (error) {
            console.error('Ошибка при загрузке данных прокси:', error);
            setError(true);
            setLoading(false);
        }
    };

    const handleOk = async () => {
        try {
            await fetchRebootProxy(userNumber);
            await addToast('success', `Proxy ${userNickname} перезагрузка в процессе`, `Чуть-чуть подождать, пока система включится`);
            setModalVisible(false); // Закрываем модальное окно после успешной перезагрузки
        } catch (error) {
            await addToast('error', `Proxy ${userNickname}`, `Не удалось перезагрузить`);
            console.error('Ошибка при выполнении запроса:', error);
        }
    };

    return (
        <>
            <Tooltip title="Перезагрузка Proxy" placement="bottom">
                <IeOutlined key="reboot" onClick={handleRebootClick} />
            </Tooltip>
            {modalVisible && (
                <Modal
                    title={`Вы уверены, что хотите перезагрузить прокси ${userNickname}?`}
                    visible={modalVisible}
                    onOk={handleOk}
                    onCancel={() => setModalVisible(false)}
                    icon={<ExclamationCircleOutlined />}
                >
                    <div>
                        {loading ? (
                            <Skeleton.Input style={{ width: 200 }} active size="small" />
                        ) : (
                            <p>Время последней перезагрузки: {timestamp}</p>
                        )}
                        <p>Это действие перезапустит выбранный прокси и может привести к временной недоступности.</p>
                    </div>
                </Modal>
            )}
        </>
    );
};
const SelectionUser = ({ user, roleType  }) => {
    const [modalInfo, setModalInfo] = useState({ market: null, type: null });
    if (!user) {
        return <p>Пользователь не найден</p>;
    }

    const handleMailClick = async (typeData) => {
        try {
            const response = await fetchMerchMailInfo({ userId: user.userId, typeData });
            if (response && response.data) {
                const copyMessage = typeData === 'login'
                    ? `Скопирован логин почты ${user.alias}`
                    : `Скопирован пароль от почты ${user.alias}`;

                handleCopy(response.data, copyMessage, false);
            }
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };

    const handleMarketClick = async (userNumber, userNickname, marketName, typeData) => {
        try {
            const response = await fetchMerchMarketInfo({ userNumber: userNumber, typeData });
            if (response && response.data) {
                const copyMessage = typeData === 'login'
                    ? `Скопирован логин ${marketName} ${userNickname}`
                    : `Скопирован пароль ${marketName} ${userNickname}`;

                handleCopy(response.data, copyMessage, false);
            }
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };


    const handleGoogleClick = async (userNumber, userNickname,marketName) => {
        try {
            const response = await fetchMerchGoogleInfo(userNumber);
            if (response && response.data) {
                handleCopy(response.data, `Скопирован 2FA ${marketName} ${userNickname}`, );
            }
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };



    const openModal = (market, type) => {
        setModalInfo({ market, type });
    };
    const closeModal = () => {
        setModalInfo({ market: null, type: null });
    };

    const isModalOpen = modalInfo.market && modalInfo.type;
    const modalTitlePrefix = modalInfo.type === 'load' ? 'Взятие' : 'Загрузка';
    const ModalContentComponent = modalInfo.type === 'load' ? ModalSessionLoad : ModalSessionSend;


    return (
        <Row gutter={[16, 16]}>

            <Col xs={24} sm={12} md={12} lg={6} xl={6}>

                <Card
                    style={{ backgroundColor: user.colorGlass }}
                    bordered={false}
                    actions={[
                        <Tooltip title="Эл. почта" placement="bottom">
                        <UserOutlined key="login" onClick={() => handleMailClick('login')} />
                        </Tooltip>,
                        ...(roleType === "ADMIN" ? [ <Tooltip title="Пароль от почты" placement="bottom"><LockOutlined key="password" onClick={() => handleMailClick('password')} /> </Tooltip>] : [])
                    ]}
                >
                    <Meta
                        avatar={<Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />}
                        title={`${user.userId} - ${user.alias}`}
                        description={(
                            <>
                                <span>
                                        <b>Имя:</b> {user.fio}
                                </span>
                                <br/>
                                {user.telegram ?
                                    (
                                        <span className="my-copy" onClick={() => handleCopy(`@${user.telegram}`)}>
                                            <b>TG:</b> @{user.telegram}
                                        </span>
                                    ) : (
                                        <span>
                                            <b>TG:</b> Отсутствует
                                        </span>
                                    )}
                                <br/>
                                <span>
                                    <b>Телефон:</b> {user.phone}
                                </span>
                                <br/>
                                <span>
                                    <b>Команда:</b> {user.team}
                                </span>

                            </>
                        )}
                    />
                </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <Card
                    bordered={false}
                    actions={[
                        <RebootProxyButton userNumber={user.userId} userNickname={user.alias} />,
                    ]}
                >
                    <Meta
                        title={"Прокси"}
                        description={(
                            <>
                                 <span className="my-copy" onClick={() => handleCopy(user.proxy)}>
                                    <b>Proxy:</b> {user.proxy}
                                </span>
                                <br/>
                                <ProxyTime userNumber={user.userId}/>


                            </>
                        )}
                    />
                </Card>
            </Col>
            {user.markets.map((market, index) => (
                <Col key={index} xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Card
                        bordered={false}
                        actions={[
                            ...(roleType === "ADMIN" ? [
                                <Tooltip title="Логин" placement="bottom">
                                    <UserOutlined key="login" onClick={() => handleMarketClick(market.userNumber, market.nickName, market.marketName, 'login')} />
                                </Tooltip>,
                                <Tooltip title="Пароль" placement="bottom">
                                    <LockOutlined key="password" onClick={() => handleMarketClick(market.userNumber, market.nickName, market.marketName, 'password')} />
                                </Tooltip>
                            ] : []),
                            <Tooltip title="2FA Ключ" placement="bottom">
                                <KeyOutlined key="key" onClick={() => handleGoogleClick(market.userNumber, market.nickName, market.marketName)} />
                            </Tooltip>,
                            <Tooltip title="Отправить сессию" placement="bottom">
                                <SendOutlined key="sendSession" onClick={() => openModal(market, 'send')} />
                            </Tooltip>,
                            <Tooltip title="Сохранить сессию" placement="bottom">
                                <CopyOutlined key="session" onClick={() => openModal(market, 'load')} />
                            </Tooltip>

                        ]}>
                        <Meta title={`${market.marketName} - ${market.nickName}`}
                              avatar={<MarketIcon market={market.marketNumber}/> }
                            description={(
                                <>
                                    <CryptoInfo
                                        type="BEP-20"
                                        value={market.cryptoBEP20}
                                        nickname={market.nickName}
                                        marketName={market.marketName}
                                        handleCopy={handleCopy}
                                        renderCryptoAddress={renderCryptoAddress}
                                    />
                                    <br/>
                                    <CryptoInfo
                                        type="TRC-20"
                                        value={market.cryptoTRC20}
                                        nickname={market.nickName}
                                        marketName={market.marketName}
                                        handleCopy={handleCopy}
                                        renderCryptoAddress={renderCryptoAddress}
                                    />
                                    <br/>

                                    <CryptoInfo
                                        type="UID"
                                        value={market.payUID}
                                        nickname={market.nickName}
                                        marketName={market.marketName}
                                        handleCopy={handleCopy}
                                        renderCryptoAddress={renderCryptoAddress}
                                    />
                                    <br/>
                                    <span>
                                    <b>Обновление:</b>   <UpdateTimeCell userNumber={market.userNumber} />
                                    </span>
                                </>
                            )}
                        >
                        </Meta>
                    </Card>
                </Col>
            ))}

            {isModalOpen && (
                <Modal
                    title={`${modalTitlePrefix} сессии для ${modalInfo.market.nickName} ${modalInfo.market.marketName}`}
                    visible={isModalOpen}
                    onOk={closeModal}
                    onCancel={closeModal}
                >
                    <ModalContentComponent
                        userNumber={modalInfo.market.userNumber}
                        marketName={modalInfo.market.marketName}
                        nickName={modalInfo.market.nickName}
                        marketNumber={modalInfo.market.marketNumber}
                    />
                </Modal>
            )}
        </Row>
    );
};



const MerchantPage = observer(() => {
    const { dataUser } = authStore;
    const roleType = dataUser.roleType
    const loading =  MerchStore.loadingData
    const merchants = MerchStore.dataMerchants

    // useEffect(() => {
    //     fetchMerchOnline();
    // }, []); // Пустой массив зависимостей гарантирует, что эффект выполнится только один раз после первого рендеринга

    const renderSkeletonTabs = () => {
        return [...Array(3)].map((_, index) => (
            <TabPane tab={<Skeleton.Input size={"small"} style={{ width: 100 }} active />} key={index}>
                <Row gutter={[16, 16]}>
                    {[...Array(4)].map((_, cardIndex) => (
                        <Col key={cardIndex} xs={24} sm={12} md={12} lg={6} xl={6}>
                            <Card>
                                <Skeleton loading={true} avatar active>
                                    <Meta
                                        avatar={<Avatar />}
                                        title="Demo Title"
                                        description="Demo Description"
                                    />
                                </Skeleton>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </TabPane>
        ));
    };

    if (loading) {
        return <Tabs defaultActiveKey="0">{renderSkeletonTabs()}</Tabs>;
    }
    const tabsItems = merchants.map(merchant => ({
        label: merchant.alias,
        key: merchant.userId,
        children: <SelectionUser user={merchant} roleType={roleType}/>
    }));
    return (
            <div>
                <Tabs  items={tabsItems}  />
            </div>
    );
});

export default MerchantPage;
