import { makeAutoObservable, reaction } from 'mobx';
import authStore from "./AuthStore";
import {fetchBotList, fetchBotUpdate, fetchBotToggle} from "../config/api/apiBot";
import {message} from "antd";
class BotsStore {
    listBots = [];

    constructor() {
        makeAutoObservable(this);
        reaction(() => authStore.user, user => {
            if (user) {
                this.loadingListBots();
            }
        });
    }

    async loadingListBots() {
        try {
            const response = await fetchBotList();
            if (response && response.adsConfigs) {
                this.listBots = response.adsConfigs;
            } else {
                console.error('No data received from fetchBotList');
            }
        } catch (error) {
            console.error('Error loading bot list:', error);
        }
    }


    async toggleBot(adsNumber, adsStatusBot) {
        try {
            const body = {
                adsNumber: adsNumber,
                adsStatusBot: adsStatusBot,
            };
            const response = await fetchBotToggle(body);
            if (response && response.status) {
                this.listBots = this.listBots.map((bot) =>
                    bot.adsNumber === adsNumber ? { ...bot, isRunning: !bot.isRunning } : bot
                );
            } else {
                // Обработка ошибки
            }
        } catch (error) {
            // Обработка ошибки
        }
    }

    async toggleAuto(adsNumber, adsStatus) {
        this.listBots = this.listBots.map((bot) => {
            if (bot.adsNumber === adsNumber) {
                if (bot.autoConfig == null) {
                    message.info('Отсутствует конфигурация автокурса');
                    return bot;
                }
                return { ...bot, autoCourse: !bot.autoCourse };
            }
            return bot;
        });
    }

    // ... другие методы

    // Методы для обновления состояния
    updateBotMinPrice(adsNumber, newPrice) {
        this.listBots = this.listBots.map((bot) =>
            bot.adsNumber === adsNumber ? { ...bot, priceMinMax: newPrice } : bot
        );
    }

    updateBotLimit(adsNumber, newLimit) {
        this.listBots = this.listBots.map((bot) =>
            bot.adsNumber === adsNumber ? { ...bot, botLimit: newLimit } : bot
        );
    }

    async  btnQuickUpdate(adsNumber)  {
        const botOne = this.listBots.find((bot) => bot.adsNumber === adsNumber);
        if (botOne.botLimit == null ||botOne.botLimit !==0 && botOne.botLimit < 500) {
            message.info('Минимальный лимит должен быть равен 0 или больше или равен 500');
            return;
        }

        if (botOne.autoCourse === false && (botOne.priceMinMax == null || botOne.priceMinMax < 0.95)) {
            message.info('Цена должна быть больше или равна 0.95');
            return;
        }

        try {
            const body = {
                adsNumber: adsNumber,
                botLimit: botOne.botLimit,
                ...(botOne.autoCourse ? { autoCourse: true } : { priceMinMax: botOne.priceMinMax, autoCourse: false }),
            };
            const response = await fetchBotUpdate(body);
            if (response && response.status) {
                message.success('Успех');
            } else {
                // Обработка ошибки
            }
        } catch (error) {
            // Обработка ошибки
        }
    }
}
const botsStore = new BotsStore();
export default botsStore;
