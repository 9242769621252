import React from 'react';
import { OneColCenter } from "../../Layouts/formRow/2col";
import BalanceComponent from "./BalanceComp";


function BalancePage() {
    return (
    <OneColCenter
        col1={() => (
           <BalanceComponent size={6}/>
        )}
    />

    );
}

export default BalancePage;
