import { makeAutoObservable, reaction } from 'mobx';

class CalcP2PStore {
    steps = 2;
    asset = 'USDT';
    secondAsset = 'BTC';
    deposit = 100000;
    buyRate = 50;
    conversionRate = 1;
    sellRate = 60;
    isMakerBuy = true;
    isMakerSell = true;
    exchange = 'Binance';
    EXCHANGES = {
        Binance: { maker: 0.07, taker: 0, spot: 0.1 },
        ByBit: { maker: 0, taker: 0, spot: 0.1 },
        HTX: { maker: 0, taker: 0, spot: 0.1 },
        Commex: { maker: 0, taker: 0, spot: 0.1 },
    };
    ASSETS = {
        USDT: 2,
        BTC: 6,
        ETH: 6,
        BNB: 6
    };
    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.steps,
            (steps) => {
                if (steps === 2) {
                    this.setSecondAsset(this.asset);
                }
            }
        );

        reaction(
            () => [this.asset, this.secondAsset],
            ([newAsset, newSecondAsset]) => {
                if (newAsset === newSecondAsset) {
                    this.setSecondAsset(Object.keys(this.ASSETS).find(key => key !== newAsset));
                }
            }
        );
    }

    // Setters
    setSteps(value) {
        this.steps = value;
    }

    setAsset(value) {
        this.asset = value;
    }

    setSecondAsset(value) {
        this.secondAsset = value;
    }

    setDeposit(value) {
        this.deposit = value;
    }

    setBuyRate(value) {
        this.buyRate = value;
    }

    setConversionRate(value) {
        this.conversionRate = value;
    }

    setSellRate(value) {
        this.sellRate = value;
    }

    setIsMakerBuy(value) {
        this.isMakerBuy = value;
    }

    setIsMakerSell(value) {
        this.isMakerSell = value;
    }

    setExchange(value) {
        this.exchange = value;
    }
    switchAssets = () => {
        const tempAsset = this.asset;
        this.asset = this.secondAsset;
        this.secondAsset = tempAsset;

        const tempRate = this.buyRate;
        this.buyRate = this.sellRate;
        this.sellRate = tempRate;
    };
    // Business logic
    fetchConversionRate = async () => {
        try {
            let symbol = `${this.asset}${this.secondAsset}`;
            if (!["BTCUSDT", "ETHUSDT", "BNBUSDT", "ETHBTC", "BNBBTC", "BNBETH"].includes(symbol)) {
                symbol = `${this.secondAsset}${this.asset}`;
            }

            const response = await fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`);
            const data = await response.json();

            if (data && data.price) {
                this.setConversionRate(parseFloat(data.price));
            }
        } catch (error) {
            console.error("Ошибка при загрузке курса конвертации:", error);
            // Обработка ошибок или отображение сообщения пользователю
        }
    };
    calculateDetails() {
        const buyCommissionPercent = this.isMakerBuy ? this.EXCHANGES[this.exchange].maker : this.EXCHANGES[this.exchange].taker;
        const sellCommissionPercent = this.isMakerSell ? this.EXCHANGES[this.exchange].maker : this.EXCHANGES[this.exchange].taker;
        const spotCommissionPercent = this.EXCHANGES[this.exchange].spot;

        const assetsBought = this.deposit / this.buyRate;
        const buyCommission = assetsBought * buyCommissionPercent / 100;
        const assetsAfterBuyCommission = assetsBought - buyCommission;

        const conversionDirection = `${this.asset}${this.secondAsset}`;
        const directConversion = ["BTCUSDT", "ETHUSDT", "BNBUSDT"].includes(conversionDirection);
        let convertedAssets, conversionCommission;
        if (this.steps === 3) {
            convertedAssets = directConversion ?
                assetsAfterBuyCommission * this.conversionRate :
                assetsAfterBuyCommission / this.conversionRate;
            conversionCommission = convertedAssets * spotCommissionPercent / 100;
            convertedAssets -= conversionCommission;
        } else {
            convertedAssets = assetsAfterBuyCommission;
            conversionCommission = 0;
        }

        const revenueBeforeSellCommission = convertedAssets * this.sellRate;
        const sellCommission = revenueBeforeSellCommission * sellCommissionPercent / 100;
        const revenueAfterSellCommission = revenueBeforeSellCommission - sellCommission;

        const profit = revenueAfterSellCommission - this.deposit;
        const profitPercentage = (profit / this.deposit) * 100;


        return {
            assetsBought: assetsBought.toFixed(this.ASSETS[this.asset]),
            buyCommission: buyCommission.toFixed(this.ASSETS[this.asset]),
            assetsAfterBuyCommission: assetsAfterBuyCommission.toFixed(this.ASSETS[this.asset]),
            convertedAssets: convertedAssets.toFixed(this.ASSETS[this.secondAsset]),
            conversionCommission: conversionCommission.toFixed(this.ASSETS[this.secondAsset]),
            revenueBeforeSellCommission: revenueBeforeSellCommission.toFixed(2),
            sellCommission: sellCommission.toFixed(2),
            revenueAfterSellCommission: revenueAfterSellCommission.toFixed(2),
            profit: profit.toFixed(2),
            profitPercentage: profitPercentage.toFixed(2)
        };
    }
}

export default new CalcP2PStore();