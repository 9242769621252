import React, { useState, useEffect } from 'react';
import { Form, Input, Table,Button, Select, DatePicker, Tag, Skeleton } from 'antd';
import {fetchSessionHistory, fetchTeam, fetchUserInfo, fetchUserUpdateSalary} from "../../config/crm/apiCRM";
import moment from 'moment';
import 'moment/locale/ru';
import { TwoColLayout } from "../../Layouts/formRow/2col";
import { addToast } from "../../components/Notification/Toast";
import { bankOptions, cryptoOptions } from "../../config/constants";
import {tradeStore} from "../../state/TradeStore";
import MerchStore from "../../state/MerchStore";
import authStore from "../../state/AuthStore";
moment.locale('ru'); // Замените 'ru' на нужный вам язык

const TeamForm = ({ userInfo }) => {
    const { dataUser } = authStore;
    const roleType = dataUser.roleType
    let  teamMapping = {
        "1": "Команда А",
        "2": "Команда Б",
        "3": "Команда B",
        "4": "Команда М",
    };
    if (roleType === "ADMIN") {
        teamMapping = { ...teamMapping, "0": "Киборг" };
    }
    const [form] = Form.useForm();
    const { Option } = Select;
    useEffect(() => {
        // Устанавливаем начальное значение для команды на основе userInfo
        const teamName = teamMapping[userInfo.team];
        form.setFieldsValue({ team: teamName });
    }, [userInfo, form]);

    const onFinish2 = async (values) => {
        const teamKey = Object.keys(teamMapping).find(key => teamMapping[key] === values.team);
        const requestBody = {
            uid: userInfo.uid,
            team: teamKey,
        };
        try {
            const answer = await fetchTeam(requestBody);
            console.log(answer); // Выводим ответ для проверки
            if (answer.code === 1000 && answer.status === true) {
                addToast('success', 'Успех', 'Команда успешно изменена');
                localStorage.setItem('team', teamKey);
                MerchStore.getDataMerch()
                tradeStore.setDataOrders([])
            } else if (answer.code === 1000 && answer.status === false) {
                // Показываем уведомление с предупреждением
                addToast('warning', 'Внимание', 'Вы выбрали ту же команду');
            } else {
                // Показываем уведомление об ошибке, если код ответа не 1000 или другие необработанные случаи
                addToast("error", 'Ошибка', "Попробуйте ещё раз");
            }
        } catch (error) {
            addToast("error",'Ошибка',"Попробуйте ещё раз");
        }
    };

    return (
        <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            form={form}
            onFinish={onFinish2}
        >
            <Form.Item
                name="team"
                label="Команда"
                rules={[{ required: true, message: 'Пожалуйста, выберите команду!' }]}
            >
                <Select placeholder="Выберите команду">
                    {Object.entries(teamMapping).map(([key, value]) => (
                        <Option key={key} value={value}>{value}</Option>
                    ))}
                </Select>
            </Form.Item>
            {/* Добавляем кнопку для отправки формы */}
            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                    Изменить команду
                </Button>
            </Form.Item>
        </Form>
    );
};
const ProfileForm = ({ userInfo }) => {
    const [form] = Form.useForm();
    const [salaryType, setSalaryType] = useState('');
    const [bankType, setBankType] = useState('');
    const [bankSBP, setBankSBP] = useState('');

    useEffect(() => {
        if (userInfo) {
            setSalaryType(userInfo.salaryType);
            setBankType(userInfo.bankType);
            form.setFieldsValue({
                ...userInfo,
                birthdate: userInfo.birthdate ? moment(userInfo.birthdate) : null,
            });
            if (userInfo.bankType === 'spb') {
                setBankSBP(userInfo.bankSBP); // Устанавливаем значение для bankSBP, если оно есть
            }
        }
    }, [userInfo, form]);

    const handleSalaryTypeChange = (value) => {
        setSalaryType(value);
    };

    const handleBankTypeChange = (value) => {
        setBankType(value);
        if (value !== 'spb') {
            setBankSBP(''); // Очищаем значение bankSBP, если выбран не spb
        }
    };

    const getBankFieldLabel = () => {
        const bank = bankOptions.find(option => option.value === bankType);
        return bank ? bank.secondaryLabel : 'Детали платежа';
    };


    const onFinish = async (values) => {
        const updatedUserData = {
            uid: userInfo.uid, // Берем uid из userInfo
            salaryType: values.salaryType,
        };
        if (values.salaryType === 'bank') {
            updatedUserData.bankType = values.bankType;
            updatedUserData.bankDetails = values.bankDetails;
        } else if (values.salaryType === 'crypto') {
            updatedUserData.cryptoNetwork = values.cryptoNetwork;
            updatedUserData.cryptoAddress = values.cryptoAddress;
        }
        if (values.bankType === 'spb') {
            updatedUserData.bankSBP = bankSBP; // Добавляем bankSBP в данные пользователя
        }
        try {
            await fetchUserUpdateSalary(updatedUserData);
            addToast('success', 'Успех', 'Данные обновлены');
        } catch (error) {
            addToast("error",'Ошибка',"Попробуйте ещё раз сохранить данные");
        }
    };


    return (
            <Form

                labelCol={{
                span: 8,
            }}
                  wrapperCol={{
                      span: 16,
                  }} layout="horizontal" form={form} onFinish={onFinish}>
                <Form.Item name="lastName" label="Фамилия">
                    <Input disabled/>
                </Form.Item>
                <Form.Item name="firstName" label="Имя">
                    <Input disabled/>
                </Form.Item>
                <Form.Item name="middleName" label="Отчество">
                    <Input disabled/>
                </Form.Item>
                <Form.Item name="email" label="Email">
                    <Input disabled/>
                </Form.Item>
                <Form.Item name="birthdate" label="Дата рождения">
                    <DatePicker format="DD-MM-YYYY" disabled />
                </Form.Item>
                <Form.Item name="telegram" label="Телеграм">
                    <Input disabled/>
                </Form.Item>
                <Form.Item name="salaryType" label="Тип зарплаты">
                    <Select onChange={handleSalaryTypeChange}>
                        <Select.Option value="bank">Банковский перевод</Select.Option>
                        <Select.Option value="crypto">Крипто перевод</Select.Option>
                    </Select>
                </Form.Item>

                {salaryType === 'bank' && (
                    <>
                        <Form.Item name="bankType" label="Тип банка">
                            <Select onChange={handleBankTypeChange}>
                                {bankOptions.map(option => (
                                    <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {bankType === 'spb' && (
                            <Form.Item name="bankSBP" label="Название банка">
                                <Input value={bankSBP} onChange={(e) => setBankSBP(e.target.value)} />
                            </Form.Item>
                        )}
                        <Form.Item name="bankDetails" label={getBankFieldLabel()}>
                            <Input />
                        </Form.Item>
                    </>
                )}
                {salaryType === 'crypto' && (
                    <>
                        <Form.Item name="cryptoNetwork" label="Тип сети">
                            <Select>
                                {cryptoOptions.map(option => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="cryptoAddress" label="Адрес кошелька">
                            <Input />
                        </Form.Item>
                    </>
                )}
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Обновить
                    </Button>
                </Form.Item>
            </Form>

    );
};


const HistoryTable = ({ historyInfo }) => {
    const columns = [
        {
            title: 'Устройство',
            key: 'device',
            render: record => `${record.deviceInfo.os} / ${record.deviceInfo.browser}`,
        },
        {
            title: 'IP',
            dataIndex: 'ipAddress',
            key: 'ipAddress',
        },
        {
            title: 'Время входа',
            dataIndex: 'loginTime',
            key: 'loginTime',
            render: time => moment(time).format('DD-MM-YYYY HH:mm:ss'),
        },
        {
            title: 'Время выхода',
            dataIndex: 'logoutTime',
            key: 'logoutTime',
            render: time => time ? moment(time).format('DD-MM-YYYY HH:mm:ss') : '',
        },
        {
            title: 'Тип выхода',
            key: 'logoutType',
            render: record => {
                let color = record.logoutType === 'user' ? 'green' : 'red';
                let text = record.logoutType === 'user' ? 'Пользователь' : 'Система';

                if (!record.logoutTime) {
                    color = 'blue';
                    text = 'Активный';
                }

                return <Tag color={color}>{text}</Tag>;
            }
        }
    ];

    return <Table dataSource={historyInfo}  columns={columns} rowKey="_id"  scroll={{ x: 'max-content' }} />;
};

const ProfilePage = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [historyInfo, setHistoryInfo] = useState([]);

    useEffect(() => {
        const uid = localStorage.getItem('uid');
        if (!uid) {
            return; // Выходим из useEffect, если UID отсутствует
        }
        const fetchUserData = async () => {
            try {
                const userData = await fetchUserInfo(uid); // Предполагается, что fetchUserInfo возвращает данные пользователя
                const historyData = await fetchSessionHistory(uid)
                setUserInfo(userData.data)
                setHistoryInfo(historyData.data)
            } catch (error) {
                console.error("Ошибка при загрузке данных пользователя:", error);
            }
        };
        fetchUserData();
    }, []);



    return (
        <TwoColLayout
            col1={() => (
                <>
                    {userInfo ? (
                        <>
                        <ProfileForm  userInfo={userInfo}/>

                        <TeamForm  userInfo={userInfo}/>
                        </>
                    ) : (
                        <Skeleton />
                    )}
                </>
            )}
            col2={() => (
                <HistoryTable historyInfo={historyInfo}/>
            )}
            sizeCol={[8, 16]} // Для равных ширин
            titleCol = {["Профиль пользователя", "Данные активности"]} // Для равных ширин
        />
    );
};
export default ProfilePage;