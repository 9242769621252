import {observer} from "mobx-react";
import React, { useState,useEffect} from "react";
import AdsStore from "../../../state/AdsStore";
import {Button, Card, Col, Dropdown , InputNumber, Menu, Spin , Row, Space, Switch} from "antd";
import {CalculatorOutlined, RiseOutlined, UserOutlined,ControlOutlined,RobotOutlined,EnterOutlined,RetweetOutlined  } from "@ant-design/icons";
import { BotSettingsDrawer, BotSettingsDrawOldConfig } from "./BotSettingsDrawer";
import { addToastUser } from "../../../components/Notification/Toast";
import { fetchBotConfig } from "../../../config/api/apiBot";
import {addMessage} from "../../../components/Notification/Toast";
import GlassPriceStore from "../../../state/GlassPriceStore";




export const ControlPanel = observer(({ title, userNumber, adsList, marketName,tradeType,loadingList }) => {
    const [adsNumber, setAdsNumber] = useState(null);
    const [adsAsset, setAdsAsset] = useState(null);
    const [configServer, setConfigServer] = useState(null);
    const [adsSwitch, setAdsSwitch] = useState(false);
    const [minAmount, setMinAmount] = useState(null);
    const [maxAmount, setMaxAmount] = useState(null);
    const [price, setPrice] = useState(0);
    const [valueAd, setValueAd] = useState(0);
    const [pay, setPay] = useState([])

    const [botSettingsVisible, setBotSettingsVisible] = useState(false);
    const filteredAdsList = adsList
        ? adsList.filter(ad =>
            ad.pay.includes(GlassPriceStore.bank) &&
            GlassPriceStore.asset.includes(ad.asset)
        )
        : [];

    useEffect(() => {
        const key = `selectedAd_${userNumber}_${tradeType}`;
        const savedAdsNumber = localStorage.getItem(key);

        let resData = null;

        if (savedAdsNumber !== null) {
            // Запрос деталей объявления только если есть сохранённый номер объявления
            const savedAd = AdsStore && AdsStore.getAdDetails(userNumber, savedAdsNumber);
            if (savedAd && savedAd.pay.includes(GlassPriceStore.bank) && savedAd.asset === GlassPriceStore.asset) {
                resData = savedAd;
            }
        }

        if (!resData && filteredAdsList.length > 0) {
            const defaultAd = filteredAdsList.find(ad => ad.adsStatus === 1) || filteredAdsList[0];
            if (defaultAd) {
                resData = AdsStore.getAdDetails(userNumber, defaultAd.adsNumber);
            }
        }

        if (resData) {
            // Если есть данные объявления, устанавливаем их
            setAdsAsset(resData.asset);
            setAdsSwitch(resData.adsStatus);
            setAdsNumber(resData.adsNumber);
            setMinAmount(resData.minAmount);
            setMaxAmount(resData.maxAmount);
            setPrice(resData.price);

            let valueAsNumber = parseFloat(resData.value);
            let roundedValueAd = valueAsNumber;

            if (!isNaN(valueAsNumber)) {
                roundedValueAd = resData.asset === 'USDT' ? valueAsNumber.toFixed(2)
                    : (resData.asset === 'BTC' || resData.asset === 'ETH') ? valueAsNumber.toFixed(6)
                        : valueAsNumber;
            }

            setValueAd(parseFloat(roundedValueAd));
            setPay(resData.pay);

            // Сохраняем выбранное объявление
            localStorage.setItem(key, resData.adsNumber);
        } else {
            // Устанавливаем значения по умолчанию, если ничего не найдено
            setAdsAsset("USDT");
            setAdsSwitch(0);
            setAdsNumber(0);
            setMinAmount(0);
            setMaxAmount(0);
            setPrice(0);
            setValueAd(0);
            setPay([]);
        }
    }, [adsList, GlassPriceStore.bank,GlassPriceStore.asset]); //
    const showDrawer = async () => {
        if (adsNumber === null || adsNumber === undefined) {
            addToastUser(userNumber, 'error', 'Необходимо выбрать объявление');
            return null;
        }
        try {
            const res = await fetchBotConfig({ adsNumber: adsNumber });
            if (res.status) {
                setConfigServer(res.adsConfig);
            }
        } catch {
        } finally {
            setBotSettingsVisible(true);
        }
    };

    const onClose = () => {
        setBotSettingsVisible(false);
    };
    if (adsList === null || adsList === undefined) {
        return null; // Возврат компонента null, если adsList равен null или undefined
    }


    const loadingStatus = () => {
        return AdsStore.isLoadingForAd(userNumber, adsNumber);
    };
    const handleButtonClick = async (e) => {
        await btnAdsLoading(e.key)
    };
    const btnAdsLoading = async (adsNumber) => {
        try {
            const key = `selectedAd_${userNumber}_${tradeType}`;
            localStorage.setItem(key, adsNumber);
            const resData = AdsStore.getAdDetails(userNumber, adsNumber)
            if (resData) {
                setAdsAsset(resData.asset);
                setAdsSwitch(resData.adsStatus);
                setAdsNumber(resData.adsNumber);
                setMinAmount(resData.minAmount);
                setMaxAmount(resData.maxAmount);
                setPrice(resData.price);

                let valueAsNumber = parseFloat(resData.value);
                let roundedValueAd = valueAsNumber;

                if (!isNaN(valueAsNumber)) { // Проверяем, что это действительно число
                    roundedValueAd = resData.asset === 'USDT' ? valueAsNumber.toFixed(2)
                        : (resData.asset === 'BTC' || resData.asset === 'ETH') ? valueAsNumber.toFixed(6)
                            : valueAsNumber;
                }

                setValueAd(parseFloat(roundedValueAd)); // Устанавливаем значение после округления
                setValueAd(roundedValueAd);
                setPay(resData.pay);
            } else {
                // Обработка случая, когда данные объявления не найдены
            }
            await AdsStore.loadAdDetails(userNumber, adsNumber);
        } catch (error) {
            await addToastUser(userNumber,'error', "Ошибка загрузки объявления")
        } finally {
            const resData = AdsStore.getAdDetails(userNumber, adsNumber)
            if (resData) {
                setAdsAsset(resData.asset);
                setAdsSwitch(resData.adsStatus);
                setAdsNumber(resData.adsNumber);
                setMinAmount(resData.minAmount);
                setMaxAmount(resData.maxAmount);
                setPrice(resData.price);

                let valueAsNumber = parseFloat(resData.value);
                let roundedValueAd = valueAsNumber;

                if (!isNaN(valueAsNumber)) { // Проверяем, что это действительно число
                    roundedValueAd = resData.asset === 'USDT' ? valueAsNumber.toFixed(2)
                        : (resData.asset === 'BTC' || resData.asset === 'ETH') ? valueAsNumber.toFixed(6)
                            : valueAsNumber;
                }

                setValueAd(parseFloat(roundedValueAd)); // Устанавливаем значение после округления
                setValueAd(roundedValueAd);
                setPay(resData.pay);
            }
        }
    };

    const btnAdsStatus = async (newAdsStatus) => {
        if (loadingStatus()) {
            return;
        }

        try {
            // Пытаемся обновить статус объявления
            await AdsStore.updateAdStatus(userNumber, adsNumber, tradeType, newAdsStatus);
            // Если успешно, обновляем состояние
            setAdsSwitch(newAdsStatus);
        } catch (error) {

        }
    };


    const btnAdsFree = async () => {
        if (tradeType=== "SELL") {
            try {
                const rez = await AdsStore.updateAdAssetFree(userNumber, adsAsset, tradeType, marketName, adsNumber)
                setValueAd(rez);
            } catch (error) {
            } finally {
            }
        } else {
            return
        }

    };

    const menuItems = filteredAdsList.map((ad) => ({
        label: ad.adsLabel, // Используйте adsNumber в качестве label
        key: ad.adsNumber, // Используйте adsNumber в качестве key
        icon: <UserOutlined />,
    }));

    const menu = (
        <Menu onClick={handleButtonClick}>
            {menuItems.map((item) => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
        </Menu>
    );

    const menuItemsPay = pay.map((item, index) => (
        <Menu.Item key={index}>{item}</Menu.Item>
    ));

    const menuPay = (
        <Menu>
            {menuItemsPay}
        </Menu>
    );


    const SwitchComponent = ({ checked, onChange }) => {
        return (
            <Switch
                checkedChildren="Вкл"
                unCheckedChildren="Выкл"
                checked={checked}
                loading={loadingStatus()}
                onChange={onChange}
            />
        );
    };

    const ButtonComponent = ({label, onClick, onContextMenu,icon=null}) => {
        return(
            <Button disabled={loadingStatus()}
                    type="primary"
                    icon={icon}
                    onClick={() => onClick()}
                    onContextMenu={(e) => {
                        e.preventDefault(); // Это предотвратит появление стандартного контекстного меню
                        onContextMenu();
                    }}
            >
                {label}
            </Button>
        )
    }

    return (
        <>
            <Spin spinning={loadingStatus()}>
                <Card
                    size='small'
                    loading={!loadingList}
                    title={
                        <>
                            {adsAsset != null &&
                                <Dropdown overlay={menuPay}>
                                    <Button size='small'>
                                        {pay.length > 0 ? pay[0] : "Оплата"}
                                    </Button>
                                </Dropdown>
                            }
                        </>
                    }
                    extra={
                        <>
                            <Dropdown.Button size='small' overlay={menu}>
                                {adsNumber || "Объявление"}
                            </Dropdown.Button>
                        </>
                    }
                >
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Space direction="horizontal">
                                            <SwitchComponent
                                                checked={adsSwitch === 1}
                                                onChange={(checked) => {
                                                    const newAdsStatus = checked ? 1 : 2; // Если включено, то 1, иначе 2
                                                    btnAdsStatus(newAdsStatus);
                                                }}
                                            />
                                            <Button size='small'
                                                    type="primary"
                                                    icon={<RobotOutlined />}
                                                    onClick={showDrawer}
                                            />

                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Space.Compact
                                size="small"
                                block
                            >
                                <InputNumber
                                    min={0.1}
                                    placeholder={"Цена"}
                                    step={adsAsset === 'USDT' ? 0.01 : adsAsset === 'BTC' ? 1000 : adsAsset === 'ETH' ? 100 : 1}
                                    value={price}
                                    precision={2}

                                    onChange={(value) => {
                                        setPrice(value);
                                        if (AdsStore.isTriangle === 0) {
                                            let newQt;
                                            if (adsAsset === 'USDT') {
                                                newQt = Math.ceil(minAmount / value); // Округление до ближайшего большего целого числа
                                            } else if (adsAsset === 'BTC' || adsAsset === 'ETH') {
                                                newQt = parseFloat((minAmount / value).toFixed(5)); // Округление до 5 знаков после запятой
                                            } else {
                                                newQt = minAmount / value; // Для других активов (если есть)
                                            }
                                            setValueAd(newQt);
                                        }
                                    }}
                                    onWheel={(e) => {
                                        e.preventDefault(); // Предотвращаем стандартное поведение прокрутки страницы

                                        // Определяем шаг в зависимости от типа актива
                                        const step = adsAsset === 'USDT' ? 0.01 : adsAsset === 'BTC' ? 1000 : adsAsset === 'ETH' ? 100 : 1;
                                        const delta = e.deltaY > 0 ? -step : step;

                                        // Вычисляем новое значение с учетом шага и округления
                                        const newValue = parseFloat((parseFloat(price) + delta).toFixed(adsAsset === 'USDT' ? 2 : 5));

                                        setPrice(newValue);

                                        if (AdsStore.isTriangle === 0) {
                                            let newQt;
                                            if (adsAsset === 'USDT') {
                                                newQt = Math.ceil(minAmount / newValue); // Округление до ближайшего большего целого числа
                                            } else if (adsAsset === 'BTC' || adsAsset === 'ETH') {
                                                newQt = parseFloat((minAmount / newValue).toFixed(5)); // Округление до 5 знаков после запятой
                                            } else {
                                                newQt = minAmount / newValue; // Для других активов (если есть)
                                            }
                                            setValueAd(newQt);
                                        }
                                    }}
                                    keyboard={false}

                                    addonBefore={<CalculatorOutlined />}
                                />

                                <ButtonComponent
                                    label={adsAsset}
                                    icon={<EnterOutlined />}
                                    onClick={() => AdsStore.updateAd(userNumber, adsNumber, tradeType,
                                        {
                                            price,
                                            maxAmount,
                                            minAmount
                                        }
                                    )}
                                    onContextMenu={() => AdsStore.updateAdFull(userNumber, adsNumber, tradeType,
                                        {
                                            price,
                                            volume: valueAd,
                                            maxAmount,
                                            minAmount,
                                        }
                                    )}
                                />
                            </Space.Compact>
                        </Col>
                        <Col span={12}>
                            <Space.Compact
                                size="small"
                                block
                            >
                                <div style={{ flexGrow: 1 }}>
                                    <InputNumber
                                        min={500}
                                        placeholder={"От"}
                                        size={"small"}
                                        step={10}
                                        precision={2}
                                        value={minAmount}
                                        onChange={(value) => {
                                            setMinAmount(value);
                                            if (AdsStore.isTriangle === 0) {
                                                const newValue = Math.ceil(value); // округление до ближайшего большего целого числа
                                                let newQt;
                                                if (adsAsset === 'USDT') {
                                                    newQt = Math.ceil(newValue / price); // Округление до ближайшего большего целого числа
                                                    if (newQt < 15) {
                                                        newQt = 15; // Устанавливаем минимум 10
                                                    }
                                                } else if (adsAsset === 'BTC' || adsAsset === 'ETH') {
                                                    newQt = parseFloat((newValue / price).toFixed(5)); // Округление до 5 знаков после запятой
                                                } else {
                                                    newQt = newValue / price; // Для других активов (если есть)
                                                }
                                                setValueAd(newQt);
                                                setMaxAmount(newValue);
                                            }
                                        }}
                                        onWheel={(e) => {
                                            e.preventDefault();
                                            const delta = e.deltaY > 0 ? -10 : 10;
                                            const newValue = parseFloat((parseFloat(minAmount) + delta).toFixed(0));

                                            setMinAmount(newValue);
                                            if (AdsStore.isTriangle === 0) {
                                                let newQt;
                                                if (adsAsset === 'USDT') {
                                                    newQt = Math.ceil(newValue / price);
                                                    if (newQt < 15) {
                                                        newQt = 15; // Устанавливаем минимум 15
                                                    }
                                                } else if (adsAsset === 'BTC' || adsAsset === 'ETH') {
                                                    newQt = parseFloat((newValue / price).toFixed(5));
                                                } else {
                                                    newQt = newValue / price;
                                                }
                                                setValueAd(newQt);
                                                setMaxAmount(newValue);
                                            }
                                        }}
                                        changeOnWheel
                                        addonBefore={
                                            <ControlOutlined
                                                onClick={async () => {
                                                    try {
                                                        const clipboardText = await navigator.clipboard.readText();
                                                        const clipboardValue = parseFloat(clipboardText);
                                                        if (!isNaN(clipboardValue)) {
                                                            setMinAmount(clipboardValue);
                                                            if (AdsStore.isTriangle === 0) {
                                                                const newValue = Math.ceil(clipboardValue);
                                                                let newQt;
                                                                if (adsAsset === 'USDT') {
                                                                    newQt = Math.ceil(newValue / price);
                                                                    if (newQt < 15) {
                                                                        newQt = 15;
                                                                    }
                                                                } else if (adsAsset === 'BTC' || adsAsset === 'ETH') {
                                                                    newQt = parseFloat((newValue / price).toFixed(5));
                                                                } else {
                                                                    newQt = newValue / price;
                                                                }
                                                                setValueAd(newQt);
                                                                setMaxAmount(newValue);
                                                            }
                                                        } else {
                                                            addMessage('error', 'Буфер обмена не содержит числовое значение')
                                                        }
                                                    } catch (error) {
                                                        addMessage('error', 'Не удалось получить данные из буфера обмена')

                                                    }
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        }
                                        style={{ width: '100%' }} // заставляет инпут занимать всю ширину контейнера
                                    />
                                </div>


                                {AdsStore.isTriangle === 1 && (
                                    <div style={{ flexGrow: 1 }}>
                                        <InputNumber
                                            style={{ textAlign: 'right', width: '100%' }}
                                            min={500}
                                            placeholder={"До"}
                                            size={"small"}
                                            step={10}
                                            precision={2}

                                            value={maxAmount}
                                            onChange={setMaxAmount}
                                            onWheel={(e) => {
                                                e.preventDefault();
                                                const delta = e.deltaY > 0 ? -10 : 10;
                                                const newValue = parseFloat((parseFloat(maxAmount) + delta).toFixed(0));
                                                setMaxAmount(newValue);
                                            }}

                                        />
                                    </div>
                                )}

                            </Space.Compact>

                        </Col>
                        <Col span={12}>
                            <Space.Compact
                                size="small"
                                block
                            >

                                <InputNumber
                                    min={0}
                                    placeholder={"Объем"}
                                    step={adsAsset === 'USDT' ? 10 : adsAsset === 'BTC' ? 0.001 : adsAsset === 'ETH' ? 0.1 : 1}
                                    value={valueAd}
                                    onChange={setValueAd}
                                    precision={adsAsset === 'USDT' ? 2 : (adsAsset === 'BTC' || adsAsset === 'ETH') ? 6 : 0}
                                    onWheel={(e) => {
                                        e.preventDefault();
                                        const delta = adsAsset === 'USDT' ? (e.deltaY > 0 ? -10 : 10)
                                            : adsAsset === 'BTC' ? (e.deltaY > 0 ? -0.001 : 0.001)
                                                : adsAsset === 'ETH' ? (e.deltaY > 0 ? -0.1 : 0.1)
                                                    : (e.deltaY > 0 ? -1 : 1);

                                        const newValue = parseFloat((parseFloat(valueAd) + delta).toFixed(adsAsset === 'USDT' ? 2 : 6));
                                        setValueAd(newValue);
                                    }}

                                    addonBefore={
                                        <RiseOutlined
                                            onClick={() => {
                                                if (tradeType === "SELL") {
                                                    let newValue = valueAd * 2;
                                                    if (adsAsset === 'USDT') {
                                                        newValue = parseFloat(newValue.toFixed(2));
                                                    } else if (adsAsset === 'BTC' || adsAsset === 'ETH') {
                                                        newValue = parseFloat(newValue.toFixed(6));
                                                    }
                                                    setValueAd(newValue);
                                                }
                                            }}
                                            style={{ cursor: tradeType === "SELL" ? 'pointer' : 'default' }} // изменяем курсор только если tradeType = SELL
                                        />
                                    }
                                />
                                <ButtonComponent
                                    label={"ADD"}
                                    icon={<RetweetOutlined />}
                                    onClick={() => AdsStore.updateAdAmount(userNumber, adsNumber, tradeType, valueAd)}
                                    onContextMenu={() => btnAdsFree()}
                                />
                            </Space.Compact>
                        </Col>
                    </Row>
                </Card>
            </Spin>
            {
                configServer == null
                    ? (
                        <BotSettingsDrawer
                            title={title}
                            adsNumber={adsNumber}
                            adsAsset={adsAsset}
                            onClose={onClose}
                            botSettingsVisible={botSettingsVisible}
                            pay={pay}
                            userNumber={userNumber}
                            price={price}
                            minAmount={minAmount}
                            tradeType={tradeType}
                            setBotSettingsVisible={setBotSettingsVisible}
                            marketName={marketName}
                        />
                    )
                    : (
                        <BotSettingsDrawOldConfig
                            title={title}
                            onClose={onClose}
                            botSettingsVisible={botSettingsVisible}
                            setBotSettingsVisible={setBotSettingsVisible}
                            pay={pay}
                            configServer={configServer}
                        />
                    )
            }

        </>
    );
})

