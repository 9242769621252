import React from 'react';
import {message} from "antd";
import Countdown from "react-countdown";

export const rendererTime = ({ minutes, seconds, completed }) => {
    if (completed) {
        return <span>00:00</span>;
    } else {
        return <span>{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>;
    }
};


export const ReverseTimer = ({date}) => {
    return <Countdown
        date={date}
        renderer={rendererTime}
    />
};



export async function handleCopy(value, content = 'Скопировано',showMessageWithValue = true) {
    try {
        console.log(value)
        await navigator.clipboard.writeText(value);
        const messageContent = showMessageWithValue ? `${content}: ${value}` : content;
        message.success(messageContent);
    } catch (err) {
        message.error('Ошибка при копировании');
    }
}


export async function handleCopyPay(value, content = 'Скопировано',onCou) {
    try {
        await navigator.clipboard.writeText(value);
        message.success(`${content}: ${value}`);
        onCou()
    } catch (err) {
        console.log(err)
        message.error('Ошибка при копировании текста');
    }
}

export async function handleCopyReplace(value, content = 'Скопировано') {
    const formattedValue = value.toString().replace(/\./g, ',');
    await handleCopy(formattedValue, content);
}

export async function handleDisabled(value) {
    try {
        message.info(`Разблокировка кнопки ${value}`);
    } catch (err) {
        message.error('Ошибка при разблокировки кнопки');
    }
}