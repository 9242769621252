import React, { useState, useEffect } from 'react';
import {Form, Input, Select, Button, ColorPicker} from 'antd';
import {createMerchant,getNewUserId} from "../../../../config/crm/apiCRM2";
import {addMessage} from "../../../../components/Notification/Toast";
import {getRandomColorHex} from "../../../../components/CommonComponents";
const { Option } = Select;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const MerchantRegistration = () => {
    const [proxyService, setProxyService] = useState('timeWeb');
    const [colorHex, setColorHex] = useState(getRandomColorHex());
    const [formatHex, setFormatHex] = useState('hex');
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({});
    const [userId, setUserId] = useState('00');

    const hexString = React.useMemo(
        () => (typeof colorHex === 'string' ? colorHex : colorHex?.toHexString()),
        [colorHex],
    );
    const fetchUserId = async () => {
        try {
            const response = await getNewUserId();
            if (response && response.code === 1000 && response.data && response.data.userId) {
                setUserId(response.data.userId);
            } else {
                setUserId('00');
            }
        } catch (error) {
            console.error("Error fetching userId:", error);
            setUserId('00');
        }
    };
    useEffect(() => {

        fetchUserId();
    }, []);
    const onFinish = async (values) => {
        console.log("Form Values:", values);  // Логируем отправляемые данные

        // Обрабатываем данные Proxy
        let proxyData = {};
        if (values.proxyService && values.proxyService !== 'other') {
            proxyData = {
                name: values.proxyService,
                address: values.proxyAddress,
                idServer: values.idServer || null,
            };
        } else if (values.proxyService === 'other') {
            proxyData = {
                name: 'other',
                address: values.proxyAddress,
                idServer: null,
            };
        }

        const requestData = {
            ...values,
            proxy: proxyData,
            colorGlass: hexString
        };

        try {
            const response = await createMerchant(requestData);
            console.log("API Response:", response);  // Логируем ответ API
            if (response && response.code === 1000) {
                addMessage('success', response.msg);
                form.resetFields();
            } else {
                addMessage('error', 'Неожиданный формат ответа');
            }

        } catch (error) {
            console.error("API Error:", error);  // Логируем ошибку API
            addMessage('error', error.response?.data?.msg || 'Не удалось создать мерчанта');
        } finally {
            fetchUserId()
        }
    };

    const onValuesChange = (changedValues, allValues) => {
        setFormValues(allValues);
    };

    const validateEmail = (rule, value) => {
        if (!value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject('Введите корректный адрес электронной почты');
    };

    const validatePhone = (rule, value) => {
        if (!value || /^\+?[1-9]\d{1,14}$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject('Введите корректный номер телефона');
    };

    const validateProxy = (rule, value) => {
        if (!value || /^(\d{1,3}\.){3}\d{1,3}:\d{1,5}$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject('Введите корректный адрес proxy в формате IP:PORT');
    };

    const validateTelegram = (rule, value) => {
        if (!value || !value.includes('@')) {
            return Promise.resolve();
        }
        return Promise.reject('Telegram не должен содержать @');
    };

    return (
                <Form
                    {...layout}
                    form={form}
                    name="register-merchant"
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    initialValues={{ proxyService: 'timeWeb', proxyAddress: '' }}
                >
                    <Form.Item
                        name="email"
                        label="Электронная почта"
                        rules={[
                            { required: true, message: 'Пожалуйста, введите электронную почту' },
                            { validator: validateEmail },
                        ]}
                        tooltip="Введите электронную почту мерчанта"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="pass_mail"
                        label="Пароль от почты"
                        rules={[{ required: true, message: 'Пожалуйста, введите пароль от почты' }]}
                        tooltip="Введите пароль от электронной почты мерчанта"
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="alias"
                        label="Псевдоним"
                        rules={[{ required: true, message: 'Пожалуйста, введите псевдоним' }]}
                        tooltip="Введите псевдоним мерчанта"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="fio"
                        label="ФИО мерчанта"
                        rules={[{ required: true, message: 'Пожалуйста, введите ФИО мерчанта' }]}
                        tooltip="Введите полное имя мерчанта"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="proxyService"
                        label="Поставщик proxy"
                        initialValue="timeWeb"
                        tooltip="Выберите поставщика proxy или укажите другой"
                    >
                        <Select defaultValue="timeWeb" onChange={value => setProxyService(value)}>
                            <Option value="timeWeb">TimeWeb</Option>
                            <Option value="other">Другой</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="proxyAddress"
                        label="Proxy"
                        rules={[
                            { required: true, message: 'Пожалуйста, введите адрес proxy' },
                            { validator: validateProxy },
                        ]}
                        tooltip="Введите адрес host:port"
                    >
                        <Input placeholder="host:port" />
                    </Form.Item>
                    {proxyService !== 'other' && (
                        <Form.Item
                            name="idServer"
                            label="ID сервера"
                            tooltip="Введите ID сервера"
                        >
                            <Input />
                        </Form.Item>
                    )}
                    <Form.Item
                        name="phone"
                        label="Телефон"
                        rules={[
                            { validator: validatePhone },
                        ]}
                        tooltip="Введите номер телефона мерчанта"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="telegram"
                        label="Telegram"
                        rules={[
                            { validator: validateTelegram },
                        ]}
                        tooltip="Введите Telegram мерчанта"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="colorGlass"
                        label="Цвет пользователя"
                        tooltip="Выберите цвет пользователя"
                    >
                        <ColorPicker
                            format={formatHex}
                            value={colorHex}
                            onChange={setColorHex}
                            onFormatChange={setFormatHex}
                        />
                        <>{hexString}</>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Зарегистрировать
                        </Button>
                    </Form.Item>
                </Form>
    );
};

export default MerchantRegistration;