import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Select, ColorPicker, Spin, Badge, Switch, Col,Row } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { fetchGetMerchants, createMarket, editMarket, deleteMarket, decryptValue, encryptValue, deleteMerchant, editMerchant, fetchGetCommands } from '../../../../config/crm/apiCRM2';
import { addMessage } from '../../../../components/Notification/Toast';
import { MarketIcon } from '../../../../components/CommonComponents';
import { marketsOptions } from "../../../../config/constants";

const { Option } = Select;

const editOptions = [
    { label: 'Почта', value: 'email' },
    { label: 'Пароль от почты', value: 'pass_mail' },
    { label: 'Псевдоним', value: 'alias' },
    { label: 'ФИО', value: 'fio' },
    { label: 'Поставщик proxy', value: 'proxyService' },
    { label: 'Телефон', value: 'phone' },
    { label: 'Telegram', value: 'telegram' },
    { label: 'Цвет пользователя', value: 'colorGlass' },
    { label: 'Команда', value: 'team' },
];

const marketEditOptions = [
    { label: 'Никнейм', value: 'nickName' },
    { label: 'Логин', value: 'marketLogin' },
    { label: 'Пароль', value: 'marketPass' },
    { label: 'API Key', value: 'raw_api_key' },
    { label: 'Secret Key', value: 'raw_secret_key' },
    { label: 'Google Key', value: 'raw_google' },
    { label: 'Фин. пароль', value: 'tradingPassword' },
    { label: 'Crypto BEP20', value: 'cryptoBEP20' },
    { label: 'Crypto TRC20', value: 'cryptoTRC20' },
    { label: 'Pay UID', value: 'payUID' },
    { label: 'Статус работы', value: 'workStatus' },
];

const MerchantManagement = () => {
    const [merchants, setMerchants] = useState([]);
    const [commands, setCommands] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [editCategory, setEditCategory] = useState(null);
    const [marketEditCategory, setMarketEditCategory] = useState(null);
    const [form] = Form.useForm();
    const [marketForm] = Form.useForm();
    const [emailValue, setEmailValue] = useState('');
    const [passValue, setPassValue] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isMarketModalVisible, setIsMarketModalVisible] = useState(false);
    const [isCreateMarketModalVisible, setIsCreateMarketModalVisible] = useState(false);
    const [selectedMarket, setSelectedMarket] = useState(null);
    const handleDecrypt = async (encryptedValue, field, isMarket = false) => {
        try {
            const response = await decryptValue({ encryptedValue });
            if (response.code === 1000) {
                if (isMarket) {
                    marketForm.setFieldsValue({ [field]: response.decryptedValue });
                } else {
                    form.setFieldsValue({ [field]: response.decryptedValue });
                    if (field === 'email') {
                        setEmailValue(response.decryptedValue);
                    } else if (field === 'pass_mail') {
                        setPassValue(response.decryptedValue);
                    }
                }
            } else {
                addMessage('error', response.msg || 'Ошибка при расшифровке значения');
            }
        } catch (error) {
            addMessage('error', error.message || 'Ошибка при расшифровке значения');
        }
    };
    const fetchMerchants = async () => {
        setLoading(true);
        try {
            const response = await fetchGetMerchants();
            if (response && response.code === 1000) {
                setMerchants(response.merchants);
            } else {
                addMessage('error', response.msg || 'Не удалось получить список мерчантов');
            }
        } catch (error) {
            addMessage('error', error.message || 'Ошибка при получении списка мерчантов');
        } finally {
            setLoading(false);
        }
    };

    const fetchCommandsList = async () => {
        setLoading(true);
        try {
            const response = await fetchGetCommands();
            if (response && response.code === 1000) {
                setCommands(response.data);
            } else {
                addMessage('error', response.msg || 'Не удалось получить список команд');
            }
        } catch (error) {
            addMessage('error', error.message || 'Ошибка при получении списка команд');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMerchants();
        fetchCommandsList();
    }, []);

    const getCommandLabel = (teamNumber) => {
        const command = commands.find(cmd => cmd.team === teamNumber);
        return command ? command.label : 'Неизвестная команда';
    };

    const handleEdit = (merchant) => {
        setSelectedMerchant(merchant);
        form.resetFields();
        setEditCategory(null);
        setEmailValue('');
        setPassValue('');
        showModal();
    };

    const handleDelete = (merchant) => {

        Modal.confirm({
            title: 'Вы уверены, что хотите удалить мерчанта?',
            content: (
                <Input
                    placeholder="Введите электронную почту для подтверждения"
                    onChange={(e) => form.setFieldsValue({ confirmEmail: e.target.value })}
                />
            ),
            onOk: async () => {
                const values = form.getFieldsValue();
                console.log(values.confirmEmail)
                if (values.confirmEmail !== merchant.email) {
                    addMessage('error', 'Неверная электронная почта для подтверждения');
                    return;
                }
                setLoading(true);
                try {
                    const response = await deleteMerchant({ userId: merchant.userId });
                    if (response && response.code === 1000) {
                        addMessage('success', response.msg || 'Мерчант успешно удален');
                        fetchMerchants();
                    } else {
                        addMessage('error', response.msg || 'Не удалось удалить мерчанта');
                    }
                } catch (error) {
                    addMessage('error', error.message || 'Ошибка при удалении мерчанта');
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    const handleSave = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);
            let updatedValues = { userId: selectedMerchant.userId, ...values };
            if (editCategory === 'email' || editCategory === 'pass_mail') {
                const encryptedValueResponse = await encryptValue({ value: values[editCategory] });
                if (encryptedValueResponse.code === 1000) {
                    updatedValues = {
                        userId: selectedMerchant.userId,
                        email_credentials: {
                            ...selectedMerchant.email_credentials,
                            [editCategory === 'email' ? 'login' : 'password']: encryptedValueResponse.encryptedValue,
                        },
                    };
                }
            }
            const response = await editMerchant(updatedValues);
            if (response && response.code === 1000) {
                addMessage('success', response.msg || 'Мерчант успешно обновлен');
            } else {
                addMessage('error', response.msg || 'Не удалось обновить мерчанта');
            }

            fetchMerchants();
            hideModal();
        } catch (error) {
            addMessage('error', error.message || 'Ошибка при сохранении мерчанта');
        } finally {
            setLoading(false);
        }
    };

    const handleCreateMarket = async () => {
        try {
            const values = await marketForm.validateFields();
            const response = await createMarket({ userId: selectedMerchant.userId, ...values });

            if (response && response.code === 1000) {
                addMessage('success', response.msg || 'Биржа успешно добавлена');
                fetchMerchants();
                hideCreateMarketModal();
            } else {
                addMessage('error', response.msg || 'Не удалось добавить биржу');
            }
        } catch (error) {
            addMessage('error', error.message || 'Ошибка при добавлении биржи');
        }
    };

    const handleSaveMarket = async () => {
        try {
            const values = await marketForm.validateFields();
            setLoading(true);
            values.workStatus = values.workStatus === 'active';
            let response;
            if (selectedMarket) {
                const editCategory = values.editCategory;
                const petya = values[editCategory];
                console.log(petya);
                const payload = { userId: selectedMerchant.userId, marketNumber: selectedMarket.marketNumber, [editCategory]: petya };
                response = await editMarket(payload);
            } else {
                response = await createMarket({ userId: selectedMerchant.userId, ...values });
            }

            if (response && response.code === 1000) {
                addMessage('success', response.msg || `Биржа успешно ${selectedMarket ? 'обновлена' : 'добавлена'}`);
                fetchMerchants();
                hideMarketModal();
            } else {
                addMessage('error', response.msg || `Ошибка при ${selectedMarket ? 'обновлении' : 'добавлении'} биржи`);
            }
        } catch (error) {
            addMessage('error', error.message || `Ошибка при ${selectedMarket ? 'обновлении' : 'добавлении'} биржи`);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteMarket = async (market, hideModal) => {
        Modal.confirm({
            title: `Вы уверены, что хотите удалить эту биржу у ${market.nickName}? `,
            onOk: async () => {
                setLoading(true);
                try {
                    const response = await deleteMarket({ userId: selectedMerchant.userId, marketNumber: market.marketNumber });
                    if (response && response.code === 1000) {
                        addMessage('success', response.msg || 'Биржа успешно удалена');
                        fetchMerchants();
                    } else {
                        addMessage('error', response.msg || 'Не удалось удалить биржу');
                    }
                } catch (error) {
                    addMessage('error', error.message || 'Ошибка при удалении биржи');
                } finally {
                    hideModal(false);
                    setLoading(false);
                }
            },
        });
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
        setEmailValue('');
        setPassValue('');
    };

    const showCreateMarketModal = (merchant) => {
        setSelectedMerchant(merchant);
        setIsCreateMarketModalVisible(true);
        setSelectedMarket(null);
        marketForm.resetFields();
    };

    const hideCreateMarketModal = () => {
        setIsCreateMarketModalVisible(false);
    };

    const showEditMarketModal = (market, merchant) => {
        setIsMarketModalVisible(true);
        setSelectedMarket(market);
        setSelectedMerchant(merchant);
        marketForm.resetFields();
    };

    const hideMarketModal = () => {
        setIsMarketModalVisible(false);
        setSelectedMarket(null);
    };

    return (
        <Spin spinning={loading}>
            <MerchantTable
                merchants={merchants}
                commands={commands}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                showCreateMarketModal={showCreateMarketModal}
                showEditMarketModal={showEditMarketModal}
                handleDeleteMarket={handleDeleteMarket}
                getCommandLabel={getCommandLabel}
            />
            <EditMerchantModal
                isModalVisible={isModalVisible}
                hideModal={hideModal}
                handleSave={handleSave}
                form={form}
                editOptions={editOptions}
                editCategory={editCategory}
                setEditCategory={setEditCategory}
                emailValue={emailValue}
                setEmailValue={setEmailValue}
                passValue={passValue}
                setPassValue={setPassValue}
                handleDecrypt={handleDecrypt}
                selectedMerchant={selectedMerchant}
                commands={commands}
            />
            <CreateMarketModal
                isModalVisible={isCreateMarketModalVisible}
                hideModal={hideCreateMarketModal}
                handleSave={handleCreateMarket}
                marketForm={marketForm}
                marketOptions={marketsOptions}
            />
            <EditMarketModal
                isModalVisible={isMarketModalVisible}
                hideModal={hideMarketModal}
                handleSave={handleSaveMarket}
                handleDeleteMarket={handleDeleteMarket}
                marketForm={marketForm}
                marketOptions={marketEditOptions}
                marketEditCategory={marketEditCategory}
                setMarketEditCategory={setMarketEditCategory}
                handleDecrypt={handleDecrypt}
                selectedMarket={selectedMarket}
            />
        </Spin>
    );
};

const MerchantTable = ({ merchants, handleEdit, handleDelete, showCreateMarketModal, showEditMarketModal, getCommandLabel }) => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'Псевдоним',
            dataIndex: 'alias',
            key: 'alias',
        },
        {
            title: 'ФИО',
            dataIndex: 'fio',
            key: 'fio',
        },
        {
            title: 'Proxy',
            dataIndex: 'proxy',
            key: 'proxy',
            render: (text, record) => record.proxy.address
        },
        {
            title: 'Команда',
            dataIndex: 'team',
            key: 'team',
            render: (team) => getCommandLabel(team)
        },
        {
            title: 'Биржи',
            dataIndex: 'markets',
            key: 'markets',
            render: (markets, merchant) => {
                return markets && markets.length > 0
                    ? markets.map((market, index) => (
                        <span key={index} style={{ marginRight: '8px' }}>
                            <Badge
                                status={market.workStatus ? "success" : "error"} dot={true}
                            >
                                <Button type="link" icon={<MarketIcon market={market.marketNumber} />} onClick={() => showEditMarketModal(market, merchant)} />
                            </Badge>
                        </span>
                    ))
                    : 'Не подключены';
            }
        },
        {
            title: 'Действия',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button type="link" icon={<PlusOutlined />} onClick={() => showCreateMarketModal(record)} />
                    <Button type="link" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
                    <Button type="link" icon={<DeleteOutlined />} danger onClick={() => handleDelete(record)} />
                </>
            ),
        },
    ];

    return <Table columns={columns}  scroll={{
        x: "100%",
    }} dataSource={merchants} rowKey="userId" />;
};

const EditMerchantModal = ({
                               isModalVisible, hideModal, handleSave, form, editOptions, editCategory, setEditCategory,
                               emailValue, setEmailValue, passValue, setPassValue, handleDecrypt, selectedMerchant, commands
                           }) => {
    const fieldConfigs = [
        {
            category: 'email',
            render: () => (

        <Row gutter={[16,16]}>
            <Col span={16}>
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: 'Пожалуйста, введите электронную почту' },
                        { type: 'email', message: 'Введите корректный адрес электронной почты' },
                    ]}
                >
                    <Input
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        value={emailValue}
                        onChange={(e) => setEmailValue(e.target.value)}
                    />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Button style={{width: '100%'}} onClick={() => handleDecrypt(selectedMerchant.email_credentials["login"], 'email')}>Показать почту</Button>
            </Col>
        </Row>


            )
        },
        {
            category: 'pass_mail',
            render: () => (
                <>
                    <Row gutter={[16,16]}>
                        <Col span={16}>
                            <Form.Item
                                name="pass_mail"
                                rules={[{ required: true, message: 'Пожалуйста, введите пароль от почты' }]}
                            >
                                <Input
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    value={passValue}
                                    onChange={(e) => setPassValue(e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button style={{width: '100%'}} onClick={() => handleDecrypt(selectedMerchant.email_credentials["password"], 'pass_mail')}>Показать пароль</Button>
                        </Col>
                    </Row>

                </>
            )
        },
        { category: 'alias', name: 'alias', label: 'Псевдоним', type: 'text', initialValue: selectedMerchant ? selectedMerchant.alias : '', rules: [{ required: true, message: 'Пожалуйста, введите псевдоним' }] },
        { category: 'fio', name: 'fio', label: 'ФИО', type: 'text', initialValue: selectedMerchant ? selectedMerchant.fio : '', rules: [{ required: true, message: 'Пожалуйста, введите ФИО мерчанта' }] },
        {
            category: 'proxyService',
            render: () => (
                <>
                    <Form.Item
                        name="proxyService"
                        initialValue={selectedMerchant ? selectedMerchant.proxyService : ''}
                    >
                        <Select onChange={value => form.setFieldsValue({ proxyService: value })}>
                            <Option value="timeWeb">TimeWeb</Option>
                            <Option value="other">Другой</Option>
                        </Select>
                    </Form.Item>
                    {form.getFieldValue('proxyService') === 'other' ? (
                        <Form.Item name="proxyAddress" label="Proxy" initialValue={selectedMerchant ? selectedMerchant.proxyAddress : ''}>
                            <Input placeholder="host:port" />
                        </Form.Item>
                    ) : (
                        <>
                            <Form.Item name="proxyAddress" label="Proxy" initialValue={selectedMerchant ? selectedMerchant.proxyAddress : ''}>
                                <Input placeholder="host:port" />
                            </Form.Item>
                            <Form.Item name="idServer" label="ID сервера" initialValue={selectedMerchant ? selectedMerchant.idServer : ''}>
                                <Input />
                            </Form.Item>
                        </>
                    )}
                </>
            )
        },
        { category: 'phone', name: 'phone', label: 'Телефон', type: 'text', initialValue: selectedMerchant ? selectedMerchant.phone : '' },
        { category: 'telegram', name: 'telegram', label: 'Telegram', type: 'text', initialValue: selectedMerchant ? selectedMerchant.telegram : '' },
        {
            category: 'colorGlass',
            render: () => (
                <Form.Item
                    name="colorGlass"
                    initialValue={selectedMerchant ? selectedMerchant.colorGlass : ''}
                >
                    <ColorPicker format="hex" value={form.getFieldValue('colorGlass')} onChange={value => form.setFieldsValue({ colorGlass: value.toHexString() })} />
                </Form.Item>
            )
        },
        {
            category: 'team',
            name: 'team',
            label: 'Команда',
            type: 'select',
            options: commands.map(command => ({ value: command.team, label: command.label })),
            initialValue: selectedMerchant ? selectedMerchant.team : '',
            rules: [{ required: true, message: 'Пожалуйста, выберите команду' }]
        },
        {
            category: 'workStatus',
            render: () => (
                <Form.Item
                    name="workStatus"
                    initialValue={selectedMerchant ? selectedMerchant.workStatus : false}
                >
                    <Switch
                        checkedChildren="Активный"
                        unCheckedChildren="Неактивный"
                        defaultChecked={selectedMerchant ? selectedMerchant.workStatus : false}
                        onChange={checked => form.setFieldsValue({ workStatus: checked })}
                    />
                </Form.Item>
            )
        }
    ];

    const renderFormField = ({ name, label, type = 'text', initialValue, rules = [], options }) => {
        if (type === 'password') {
            return (
                <Form.Item
                    name={name}
                    label={label}
                    rules={rules}
                    initialValue={initialValue}
                >
                    <Input.Password
                        placeholder={`Введите ${label.toLowerCase()}`}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
            );
        }

        if (type === 'select') {
            return (
                <Form.Item
                    name={name}
                    label={label}
                    rules={rules}
                    initialValue={initialValue}
                >
                    <Select>
                        {options.map(option => (
                            <Option key={option.value} value={option.value}>{option.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
            );
        }

        return (
            <Form.Item
                name={name}
                label={label}
                rules={rules}
                initialValue={initialValue}
            >
                <Input placeholder={`Введите ${label.toLowerCase()}`} />
            </Form.Item>
        );
    };

    return (
        <Modal
            title="Редактировать мерчанта"
            open={isModalVisible}
            onOk={handleSave}
            onCancel={hideModal}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="editCategory"
                    label="Выберите категорию для редактирования"
                    rules={[{ required: true, message: 'Пожалуйста, выберите категорию' }]}
                >
                    <Select onChange={setEditCategory}>
                        {editOptions.map(option => (
                            <Option key={option.value} value={option.value}>{option.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
                {fieldConfigs.map(config =>
                    editCategory === config.category && (config.render ? config.render() : renderFormField(config))
                )}
            </Form>
        </Modal>
    );
};

const CreateMarketModal = ({ isModalVisible, hideModal, handleSave, marketForm, marketOptions }) => {

    const fieldConfigs = [
        { name: 'marketNumber', label: 'Биржа', type: 'select', options: marketOptions, required: true, message: 'Пожалуйста, выберите номер биржи' },
        { name: 'nickName', label: 'Никнейм', type: 'text', required: true, message: 'Пожалуйста, введите никнейм' },
        { name: 'marketLogin', label: 'Логин', type: 'text', required: true, message: 'Пожалуйста, введите логин' },
        { name: 'marketPass', label: 'Пароль', type: 'password', required: true, message: 'Пожалуйста, введите пароль' },
        { name: 'raw_api_key', label: 'API Key', type: 'text',  message: 'Пожалуйста, введите API Key' },
        { name: 'raw_secret_key', label: 'Secret Key', type: 'text',  message: 'Пожалуйста, введите Secret Key' },
        { name: 'raw_google', label: 'Google Key', type: 'text', required: true, message: 'Пожалуйста, введите Google Key' },
        { name: 'tradingPassword', label: 'Фин. пароль', type: 'password', required: true, message: 'Пожалуйста, введите фин. пароль' },
        { name: 'cryptoBEP20', label: 'Crypto BEP20', type: 'text' },
        { name: 'cryptoTRC20', label: 'Crypto TRC20', type: 'text' },
        { name: 'payUID', label: 'Pay UID', required: true,type: 'text' },
        { name: 'workStatus', label: 'Статус работы', type: 'select', options: [{ value: true, label: 'Активный' }, { value: false, label: 'Неактивный' }], required: true, message: 'Пожалуйста, выберите статус работы' }
    ];

    const renderFormField = ({ name, label, type, options, required, message }) => {
        const rules = required ? [{ required: true, message }] : [];

        if (type === 'password') {
            return (
                <Form.Item
                    name={name}
                    label={label}
                    rules={rules}
                >
                    <Input.Password
                        placeholder={`Введите ${label.toLowerCase()}`}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
            );
        }

        if (type === 'select') {
            return (
                <Form.Item
                    name={name}
                    label={label}
                    rules={rules}
                >
                    <Select>
                        {options.map(option => (
                            <Option key={option.value} value={option.value}>{option.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
            );
        }

        return (
            <Form.Item
                name={name}
                label={label}
                rules={rules}
            >
                <Input placeholder={`Введите ${label.toLowerCase()}`} />
            </Form.Item>
        );
    };
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },

    };
    return (
        <Modal
            title={<div style={{ textAlign: 'center', width: '100%' }}>Добавить биржу</div>}
            open={isModalVisible}
            onOk={handleSave}
            onCancel={hideModal}
            style={{top: 20}}
        >
            <Form form={marketForm}   {...layout}>
                {fieldConfigs.map(config => renderFormField(config))}
            </Form>
        </Modal>
    );
};

const EditMarketModal = ({
                             isModalVisible, hideModal, handleSave, handleDeleteMarket, marketForm, marketOptions,
                             marketEditCategory, setMarketEditCategory, handleDecrypt, selectedMarket
                         }) => {
    const fieldConfigs = [
        { category: 'nickName', name: 'nickName', label: 'никнейм', placeholder: 'Введите торговый никнейм' },
        { category: 'marketLogin', name: 'marketLogin', label: 'логин', placeholder: 'Введите логин от биржи', type: 'password', decryptionKey: 'login' },
        { category: 'marketPass', name: 'marketPass', label: 'пароль', placeholder: 'Введите пароль от биржи', type: 'password', decryptionKey: 'password' },
        { category: 'raw_api_key', name: 'raw_api_key', label: 'API Key', placeholder: 'Введите API Key', type: 'password', decryptionKey: 'apiKey' },
        { category: 'raw_secret_key', name: 'raw_secret_key', label: 'Secret Key', placeholder: 'Введите Secret-Key', type: 'password', decryptionKey: 'secretKey' },
        { category: 'raw_google', name: 'raw_google', label: 'Google Key', placeholder: 'Введите Google Authenticator', type: 'password', decryptionKey: 'googleKey' },
        { category: 'tradingPassword', name: 'tradingPassword', label: 'фин. пароль', placeholder: 'Введите фин. пароль', type: 'password', decryptionKey: 'tradingPassword' },
        { category: 'cryptoBEP20', name: 'cryptoBEP20', label: 'адресс сети BEP-20', placeholder: 'Введите адресс сети BEP-20' },
        { category: 'cryptoTRC20', name: 'cryptoTRC20', label: 'адресс сети TRC-20', placeholder: 'Введите адресс сети TRC-20' },
        { category: 'payUID', name: 'payUID', label: 'ID пользователя', placeholder: 'Введите ID пользователя' },
        { category: 'workStatus', name: 'workStatus', label: 'рабочий статус', type: 'switch' }
    ];

    const renderFormField = ({ name, label, placeholder, type = 'text', decryptionKey }) => {
        if (type === 'password') {
            return (
                <> <Row gutter={[16,16]}>
                    <Col span={16}>
                        <Form.Item
                            name={name}
                            initialValue={selectedMarket ? selectedMarket.data_credentials[name] : ''}
                            rules={[{ required: true, message: `Пожалуйста, введите ${label.toLowerCase()}` }]}
                        >
                            <Input
                                placeholder={placeholder}
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Button style={{width: '100%'}} onClick={() => handleDecrypt(selectedMarket.data_credentials[decryptionKey], name, true)}>Загрузить {label}</Button>
                    </Col>
                </Row>


                </>
            );
        }

        if (type === 'switch') {
            return (
                <Form.Item
                    name={name}
                    initialValue={selectedMarket ? selectedMarket[name] : false}
                >
                    <Switch
                        checkedChildren="Активный"
                        unCheckedChildren="Неактивный"
                        defaultChecked={selectedMarket ? selectedMarket[name] : false}
                        onChange={checked => marketForm.setFieldsValue({ [name]: checked })}
                    />
                </Form.Item>
            );
        }

        return (
            <Form.Item
                name={name}
                initialValue={selectedMarket ? selectedMarket[name] : ''}
                rules={[{ required: true, message: `Пожалуйста, введите ${label.toLowerCase()}` }]}
            >
                <Input placeholder={placeholder} />
            </Form.Item>
        );
    };

    return (
        <Modal
            title={
                <div style={{ textAlign: 'center', width: '100%' }}>
                    Редактировать биржу {selectedMarket?.marketName} - {selectedMarket?.nickName}
                </div>
            }
            style={{top: 20}}

            open={isModalVisible}
            onOk={handleSave}
            onCancel={hideModal}
            width={600}
            footer={[
                <Button key="delete" danger onClick={() => handleDeleteMarket(selectedMarket, hideModal)}>
                    Удалить
                </Button>,
                <Button key="cancel" onClick={hideModal}>
                    Отмена
                </Button>,
                <Button key="save" type="primary" onClick={handleSave}>
                    Сохранить
                </Button>
            ]}
        >
            <Form form={marketForm} layout="vertical">
                <Form.Item
                    name="editCategory"
                    label="Выберите категорию для редактирования"
                    rules={[{ required: true, message: 'Пожалуйста, выберите категорию' }]}
                >
                    <Select onChange={setMarketEditCategory}>
                        {marketOptions.map(option => (
                            <Option key={option.value} value={option.value}>{option.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
                {fieldConfigs.map(config =>
                    marketEditCategory === config.category && renderFormField(config)
                )}
            </Form>
        </Modal>
    );
};

export default MerchantManagement;