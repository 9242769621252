import React from 'react';
import {Col, Row, Card} from 'antd';


export const TwoColLayout = ({ col1, col2, sizeCol = [12, 12], titleCol = ["", ""] }) => {
    const [col1Span, col2Span] = sizeCol;
    const [col1Title, col2Title] = titleCol;
    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={col1Span} lg={col1Span} xl={col1Span} style={{ display: 'flex', flexDirection: 'column' }}>

                {col1Title ? (
                    <Card size="small" title={col1Title} className="my-box-style">
                        {col1()}
                    </Card>
                ) : (
                    col1()
                )}

            </Col>
            <Col xs={24} sm={24} md={col2Span} lg={col2Span} xl={col2Span} style={{ display: 'flex', flexDirection: 'column'}}>
                {col2Title ? (
                    <Card size="small" title={col2Title} className="my-box-style">
                        {col2()}
                    </Card>
                ) : (
                    col2()
                )}
            </Col>
        </Row>
    );
};


export const OneColCenter = ({ col1 }) => {
    return (
        <Row  gutter={[16, 16]} justify="center" style={{ marginTop: '20px' }}>
            <Col xs={24} sm={24} md={22} lg={22} xl={20}>
                {col1()}
            </Col>
        </Row>
    );
};