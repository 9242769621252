import React, { useState, useEffect } from 'react';
import { Select, Card, InputNumber, Button, Table,Row, Col,Tooltip } from 'antd';
import {OneColCenter} from "../../Layouts/formRow/2col";
import {fetchGlassDensity} from "../../config/api/apiGlass";
import {assetsOptions, glassStatusOptions, glassUsersOptions,payOptionsArray} from "../../config/constants";
const { Option } = Select;
const markets = {
    1: { label: "Binance" },
    2: { label: "ByBit" },
    3: { label: "HTX" },
    4: { label: "Commex" },
    best : { label: "Лучшая" }
};


const DensityGlassPage = () => {
    const [loading,setLoading] = useState(false)
    const [filters, setFilters] = useState({
        asset: "USDT", // Значение по умолчанию для asset
        pay: ["Tinkoff"],
        isMerchant: "all",
        rate: null,
        orderCount: null,
        online : "all"
    });
    const [data, setData] = useState([]);
    const [dataBest, setDataBest] = useState([]);
    const handleChange = (name, value) => {
        setFilters({ ...filters, [name]: value });
    };

    const handleSubmit = async () => {
        const preparedFilters = {
            ...filters,
            rate: filters.rate === "" || isNaN(filters.rate) ? null : filters.rate,
            orderCount: filters.orderCount === "" || isNaN(filters.orderCount) ? null : filters.orderCount,
            online: filters.online === "all" ? null : filters.online,
            isMerchant: filters.isMerchant === "all" ? null : filters.isMerchant,
        };
        try {
            setLoading(true)
            const response = await fetchGlassDensity(preparedFilters);
            setData(response.data);
            setDataBest(response.best);
        } catch (error) {
            console.error('Ошибка при отправке запроса:', error);

        } finally {
            setLoading(false)
        }
    };
    useEffect(() => {
        handleSubmit();
    }, []); // Пустой массив зависимостей означает, что эффект запустится только при монтировании компонента

    const columns = [
        {
            title: 'Цена',
            dataIndex: 'amount',
            key: 'amount',
            render: text => text ? `${Number(text).toLocaleString('ru-RU')}` : 'N/A',
        },
        {
            title: 'Продажа',
            dataIndex: 'price_sell',
            key: 'price_sell',
            render: (text, record) => (
                <>
                    {text ? (
                        <>
                            <Tooltip placement="left" title={`${record.user_sell.nickName} ${record.user_sell.rate} % (${record.user_sell.orderCount})`}>
                                {Number(text).toLocaleString('ru-RU')} ₽
                            </Tooltip>
                        </>
                    ) : (
                        'N/A'
                    )}
                </> )

        },
        {
            title: 'Покупка',
            dataIndex: 'price_buy',
            key: 'price_buy',
            render: (text, record) => (
                <>
                    {text ? (
                        <>
                            <Tooltip placement="left" title={`${record.user_buy.nickName} ${record.user_buy.rate} % (${record.user_buy.orderCount})`}>
                                {Number(text).toLocaleString('ru-RU')} ₽
                            </Tooltip>
                        </>
                    ) : (
                        'N/A'
                    )}
                </> )

        },

        {
            title: 'Плотность',
            dataIndex: 'count_buy',
            key: 'count_buy',
            render: (_, record) => (
                <>
                    <span style={{ color: 'red' }}>{record.count_sell}</span>
                    <span> / </span>
                    <span style={{ color: 'green' }}>{record.count_buy}</span>
                </> )
        },
        {
            title: 'Плотность 2',
            dataIndex: 'count_buy_limit',
            key: 'count_buy_limit',
            render: (_, record) => (
                <>
                    <span style={{ color: 'red' }}>{record.count_sell_limit}</span>
                    <span> / </span>
                    <span style={{ color: 'green' }}>{record.count_buy_limit}</span>


                </> )
        },
        {
            title: 'Спред',
            dataIndex: 'percent',
            key: 'percent',
            render: text => text ? `${Number(text).toLocaleString('ru-RU')} %` : 'N/A',
        },
    ];

    const columnsBest = [
        {
            title: 'Цена',
            dataIndex: 'amount',
            key: 'amount',
            render: text => text ? `${Number(text).toLocaleString('ru-RU')}` : 'N/A',
        },
        {
            title: 'Биржа',
            dataIndex: 'user_sell',
            key: 'user_sell',
            render: (user_sell) => (
                <>
                    {user_sell ? (
                        `${markets[user_sell.marketNumber].label}`
                    ) : (
                        'N/A'
                    )}
                </> )

        },
        {
            title: 'Продажа',
            dataIndex: 'price_sell',
            key: 'price_sell',
            render: (text, record) => (
                <>
                    {text ? (
                        <>
                            <Tooltip placement="left" title={`${record.user_sell.nickName} ${record.user_sell.rate} % (${record.user_sell.orderCount})`}>
                                {Number(text).toLocaleString('ru-RU')} ₽
                            </Tooltip>
                        </>
                    ) : (
                        'N/A'
                    )}
                </> )

        },
        {
            title: 'Плотность',
            dataIndex: 'count_buy',
            key: 'count_buy',
            render: (_, record) => (
                <>
                    <span style={{ color: 'red' }}>{record.count_sell}</span>
                    <span> / </span>
                    <span style={{ color: 'green' }}>{record.count_buy}</span>


                </> )
        },
        {
            title: 'Покупка',
            dataIndex: 'price_buy',
            key: 'price_buy',
            render: (text, record) => (
                <>
                    {text ? (
                        <>
                            <Tooltip placement="left" title={`${record.user_buy.nickName} ${record.user_buy.rate} % (${record.user_buy.orderCount})`}>
                                {Number(text).toLocaleString('ru-RU')} ₽
                            </Tooltip>
                        </>
                    ) : (
                        'N/A'
                    )}
                </> )

        },
        {
            title: 'Биржа',
            dataIndex: 'user_buy',
            key: 'user_buy',
            render: (user_buy) => (
                <>
                    {user_buy ? (
                        `${markets[user_buy.marketNumber].label}`
                    ) : (
                        'N/A'
                    )}
                </> )

        },
        {
            title: 'Спред',
            dataIndex: 'percent',
            key: 'percent',
            render: text => text ? `${Number(text).toLocaleString('ru-RU')} %` : 'N/A',
        },
    ];
    return (
        <OneColCenter
            col1={() => (
                <div>
                    <div style={{ margin: 'auto', paddingBottom:16 }}>
                        <Row gutter={[16, 16]} style={{paddingBottom:16 }}>
                            <Col xs={24} sm={6} md={6} lg={3}>
                                <Select
                                    placeholder="Asset"
                                    style={{ width: '100%' }}
                                    value={filters.asset}
                                    onChange={(value) => handleChange('asset', value)}
                                    options={assetsOptions}
                                />
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={3}>
                                <Select
                                    onChange={(value) => handleChange('isMerchant', value)}
                                    value={filters.isMerchant}
                                    style={{ width: '100%' }}
                                    options={glassUsersOptions}
                                />

                            </Col>

                            <Col xs={24} sm={6} md={6} lg={3}>
                                <Select
                                    onChange={(value) => handleChange('online', value)}
                                    value={filters.online}
                                    style={{ width: '100%' }}
                                    options={glassStatusOptions}
                                />
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={2}>
                                <InputNumber
                                    placeholder="Рейтинг от"
                                    style={{ width: '100%' }}
                                    min={0}      // Минимальное значение
                                    max={100}    // Максимальное значение
                                    step={1}     // Шаг
                                    value={filters.rate}
                                    onChange={(value) => handleChange('rate', value)}
                                />
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={2}>
                                <InputNumber
                                    placeholder="Кол-во ордеров"
                                    style={{ width: '100%' }}
                                    min={0}         // Минимальное значение
                                    max={50000}     // Максимальное значение
                                    step={100}      // Шаг
                                    value={filters.orderCount}
                                    onChange={(value) => handleChange('orderCount', value)}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={6}>
                                <Select mode="multiple" placeholder="Способ оплаты" style={{ width: "100%" }}
                                        onChange={(value) => handleChange('pay', value)}
                                        value={filters.pay}
                                >
                                    {payOptionsArray.map(item => (
                                        <Option key={item.value} value={item.value}       style={{ backgroundColor: item.color ? item.color : 'transparent' }}>{item.label}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={4}>
                                <Button type="primary" onClick={handleSubmit} style={{ width: '100%' }}>Поиск</Button>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[16, 16]}>
                        {Object.keys(data).map((marketKey) => (
                            <Col key={marketKey} xs={24} sm={24} md={12}>
                                <Card title={`${markets[marketKey].label}`} style={{ marginBottom: '16px' }}>
                                    <Table
                                        dataSource={data[marketKey]}
                                        columns={columns}
                                        pagination={false}
                                        scroll={{ x: 'max-content'}}
                                        rowKey={(record) => record.amount}
                                    />
                                </Card>
                            </Col>
                        ))}
                        <Col xs={24} sm={24} md={24}>
                            <Card title={"Лучшая"} style={{ marginBottom: '16px' }}>
                                <Table
                                    dataSource={dataBest}
                                    columns={columnsBest}
                                    pagination={false}
                                    scroll={{ x: 'max-content'}}
                                    rowKey="amount"
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        />

    );
};

export default DensityGlassPage;
