import React, { useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import authStore from "../state/AuthStore";
import BtcSpinner from './BtcSpinner';

const AuthenticatedRoute = observer(({ children, allowedRoles }) => {
    const { isAuthenticated, dataUser, loading } = authStore;
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading && !isAuthenticated) {
            navigate('/login');
        }
    }, [loading, isAuthenticated, navigate]);

    if (loading) {
        return <BtcSpinner />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }


    if (!dataUser || allowedRoles.length === 0 || allowedRoles.includes(dataUser.roleType)) {
        return <>{children}</>;
    } else {
        return <Navigate to="/home" replace />;
    }
});

export default AuthenticatedRoute;
