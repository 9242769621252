import { formatDistanceToNow} from 'date-fns';
import { ru } from 'date-fns/locale';

export const formatLastSeen = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${formatDistanceToNow(date, { addSuffix: true, locale: ru })}`;
};

export const formattedDate = (dateTimeStr) => {
    const date = new Date(dateTimeStr);
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    return date.toLocaleString('ru-RU', options);
}