import {API_URL, API_PUSH} from "../configUrls";
import {postApiRequest, getApiRequest} from "../apiParams";


export const uploadChatFile = async (formData) => {
    const uploadUrl = `${API_URL}/utils/update-file`;
    try {
        // В этом случае мы передаём formData и дополнительные заголовки напрямую
        const data = await postApiRequest(uploadUrl, formData, {
            'Content-Type': 'multipart/form-data'
        });
        return {
            imageName: data.img_name,
            imageUrl: data.img_url
        };
    } catch (error) {
        console.error('Ошибка при загрузке изображения:', error);
        throw error;
    }
};


export const uploadSession = (formData) => {
    return postApiRequest(`${API_URL}/utils/add-session`,formData, {
        'Content-Type': 'multipart/form-data'
    });
};

export const infoSession = (userNumber) => {
    return getApiRequest(`${API_URL}/utils/info-session/${userNumber}`, );
};

export const loadSession = (userNumber) => {
    return getApiRequest(`${API_URL}/utils/load-session/${userNumber}`, );
};

export const fetchAverage = (userNumber,asset,startDate, endDate) => {
    return postApiRequest(`${API_URL}/info/average-user`, { userNumber, asset,startDate,endDate });
};

export const fetchOrderDetail = (userNumber, orderNumber) => {
    return postApiRequest(`${API_URL}/order/detail`, { userNumber, orderNumber });
};

export const fetchOrderDone = (activeInfo) => {
    const { tradeType, userNumber, orderNumber, market, payType, payCode } = activeInfo;
    let endpoint = '';
    let data = { userNumber, orderNumber };

    switch (tradeType) {
        case 'BUY':
            endpoint = '/order/done-buy';
            if (market === 2) {
                Object.assign(data, { payType, payCode });
            }
            if (market === 4) {
                Object.assign(data, { payCode });
            }
            break;
        case 'SELL':
            endpoint = '/order/done-sell';
            break;
        default:
            throw new Error('Неизвестный тип торговли');
    }

    return postApiRequest(`${API_URL}${endpoint}`, data);
};

export const fetchChatList = (userNumber, orderNumber) => {
    return postApiRequest(`${API_URL}/order/chat-list`, { userNumber, orderNumber });
};

// export const fetchGoogleCode = (userNumber) => {
//     return postApiRequest(`${API_URL}/utils/google-2fa`, { userNumber });
// };



export const fetchBalanceUser = (userNumber,balanceType, asset) => {
    return postApiRequest(`${API_URL}/info/balance-user`, { userNumber, balanceType,asset });
};


export const fetchHistoryOrders = (body) => {
    return postApiRequest(`${API_URL}/orders/history`, body);
};

export const fetchLoadingOrders = (tradeType,team) => {
    return postApiRequest(`${API_URL}/orders/pending`, { tradeType, team });

};



export const fetchChatImage = async (userNumber, imageUrl) => {
    return postApiRequest(`${API_URL}/order/chat-img`, { userNumber, imageUrl });
};


export const fetchChatPdf = async (userNumber, imageUrl) => {
    return postApiRequest(`${API_URL}/order/chat-pdf`, { userNumber, imageUrl });
};

export const fetchPushLoadingAll = () => {
    return getApiRequest(`${API_PUSH}/telegram_list_all/`, );

};

export const fetchPushLoading = (balance) => {
    return postApiRequest(`${API_PUSH}/telegram_list/`, { balance });

};
export const fetchPushDel = (id) => {
    return postApiRequest(`${API_PUSH}/telegram_del/`, { id });

};


export const fetchMerchOnline = () => {
    return postApiRequest(`${API_URL}/utils/go-online`, { userNumber: "401" });

};

export const fetchAddBlock = (body) => {
    return postApiRequest(`${API_URL}/utils/go-add-block`, body);

};



export const fetchChatFile = async (userNumber, imageUrl) => {
    return postApiRequest(`${API_URL}/order/chat-file`, { userNumber, imageUrl });
};


export const fetchAgentInfo = async (userNumber, orderNumber,uid) => {
    return postApiRequest(`${API_URL}/order/agent-info`, { userNumber, orderNumber,uid });
};

export const fetchAgentReviews = async (userNumber ,uid,type,page) => {
    return postApiRequest(`${API_URL}/order/agent-reviews`, { userNumber, type,uid,page});
};

export const fetchProxyValid = (userNumber) => {
    return getApiRequest(`${API_URL}/utils/proxy-valid/${userNumber}`, );
};

export const fetchAntiFile = async (imageUrl) => {
    return postApiRequest(`${API_URL}/utils/anti`, { imageUrl });

};

export const fetchConvertPDF = async (imageUrl) => {
    return postApiRequest(`${API_URL}/utils/convertPDF`, { imageUrl });

};

export const fetchPersonalInfo = (userNumber) => {
    return postApiRequest(`${API_URL}/info/personal`,{ userNumber } );
};


export const fetchUnlockT1= (userNumber) => {
    return postApiRequest(`${API_URL}/info/t1-unlock`,{ userNumber } );
};
export const fetchCoinToCoin= (body) => {
    return postApiRequest(`${API_URL}/info/coin-to-coin`,body );
};

export const fetchWorkStatus= (body) => {
    return postApiRequest(`${API_URL}/info/work-status`,body );
};

export const fetchWorkGo= (body) => {
    return postApiRequest(`${API_URL}/info/work-status-go`,body );
};


export const fetchReitCancel= (userNumber) => {
    return postApiRequest(`${API_URL}/info/reit-cancel`,{ userNumber } );
};


export const PersonalInfo = (userNumber) => {
    return getApiRequest(`${API_URL}/info/personal/${userNumber}`, );
};
