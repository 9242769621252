import { makeAutoObservable, reaction } from 'mobx';
import { fetchAdsList, fetchAdsDetail, fetchAdsStatus, fetchAdsUpdate, fetchAdsUpdateAmount, fetchArrayAdsLoading } from "../config/api/apiAds";
import { fetchBalanceUser } from "../config/api/apiGeneral";
import merchStore from "./MerchStore";
import authStore from "./AuthStore";
import {addMessageUser, addToastUser,addToast} from "../components/Notification/Toast";

class AdsStore {
    adsData = {};
    loadingStatus = {};
    triangleMode = 0; // Изначальное значение 0
    constructor() {
        makeAutoObservable(this);
        reaction(() => authStore.user, user => {
            if (user) this.initializeAdsData();
        });
    }

    initializeAdsData() {
        merchStore.userNumbers.forEach(user => {
            this.adsData[user.userNumber] = { ads: { BUY: [], SELL: [] }, status: false };
        });
    }
    toggleTriangleMode() {
        this.triangleMode = (this.triangleMode + 1) % 2;
    }

    // Геттер для получения текстового значения и цвета кнопки в зависимости от triangleMode
    get buttonProps() {
        switch (this.triangleMode) {
            case 0:
                return { text: "Округление", color: "default" };
            case 1:
                return { text: "Классика", color: "primary" };
            default:
                return { text: "Округление", color: "default" };
        }
    }
    get isTriangle() {
        return this.triangleMode
    }

    // Метод для переключения значения triangleMode

    async loadAllAds() {
        try {
            const team = localStorage.getItem('team');
            const response = await fetchArrayAdsLoading(team);
            Object.entries(response.data).forEach(([key, value]) => {
                this.adsData[key] = value;
            });
            await addToast('success','Объявление загружены')
        } catch (error) {
            console.error("Ошибка при загрузке всех объявлений:", error);
        }
    }

    async loadAdsList(userNumber) {
        try {
            const response = await fetchAdsList(userNumber);
            this.setAdsData(userNumber, response.data.BUY, response.data.SELL);
        } catch (error) {
            console.error("Ошибка при загрузке списка объявлений:", error);
        }
    }

    setAdsData(userNumber, buyData, sellData) {
        this.adsData[userNumber] = { ads: { BUY: buyData, SELL: sellData } };
    }

    getAdsData(userNumber) {
        return this.adsData[userNumber]?.ads || { BUY: [], SELL: [] };
    }

    setLoadingForAd(userNumber, adsNumber, loading) {
        const key = `${userNumber}-${adsNumber}`;
        this.loadingStatus[key] = loading;
    }

    isLoadingForAd(userNumber, adsNumber) {
        const key = `${userNumber}-${adsNumber}`;
        return this.loadingStatus[key] || false;
    }
    getAdDetails(userNumber, adsNumber) {
        if (!this.adsData[userNumber]) {
            return null;
        }

        const userData = this.adsData[userNumber]["ads"];
        if (!userData) {
            return null;
        }

        // Поиск объявления в buyData и sellData
        const adDetails = [...userData.BUY, ...userData.SELL].find(ad => ad.adsNumber === adsNumber);

        if (!adDetails) {
            return null;
        }

        return adDetails;
    }

    updateAdDetailsTrade(userNumber, adsNumber, tradeType, adDetails) {
        if (!this.adsData[userNumber]["ads"]) {
            this.adsData[userNumber]["ads"] = { BUY: [], SELL: [] };
        }

        let adIndex = this.adsData[userNumber]["ads"][tradeType].findIndex(ad => ad.adsNumber === adsNumber);

        // Если объявление уже существует, обновляем его
        if (adIndex >= 0) {
            for (let key in adDetails) {
                if (adDetails.hasOwnProperty(key)) {
                    this.adsData[userNumber]["ads"][tradeType][adIndex][key] = adDetails[key];
                }
            }
        }
    }

    determineTradeType(adData) {
        if (adData.tradeType === 'BUY') {
            return 'BUY';
        } else if (adData.tradeType === 'SELL') {
            return 'SELL';
        } else {
            console.error("Неизвестный тип торговли:", adData.tradeType);
            return null;
        }
    }


    async loadAdDetails(userNumber, adsNumber) {
        this.setLoadingForAd(userNumber, adsNumber, true);
        try {
            const response = await fetchAdsDetail(userNumber, adsNumber);
            this.updateAdDetails(userNumber, adsNumber, response.data);
        } catch (error) {
            addToastUser(userNumber, 'error', `Ошибка загрузки объявления ${adsNumber}`)
        } finally {
            this.setLoadingForAd(userNumber, adsNumber, false);
        }
    }

    updateAdDetails(userNumber, adsNumber, adDetails) {
        const tradeType = this.determineTradeType(adDetails);
        const ads = this.adsData[userNumber]?.ads || { BUY: [], SELL: [] };
        const adIndex = ads[tradeType].findIndex(ad => ad.adsNumber === adsNumber);

        if (adIndex >= 0) {
            Object.assign(ads[tradeType][adIndex], adDetails);
        } else {
            ads[tradeType].push(adDetails);
        }

        this.adsData[userNumber].ads = ads;
    }


    async updateAd(userNumber, adsNumber, tradeType, adData) {
        this.setLoadingForAd(userNumber, adsNumber, true);
        try {
             await fetchAdsUpdate({
                userNumber,
                adsNumber,
                ...adData
            });
            this.updateAdDetailsTrade(userNumber, adsNumber, tradeType, adData);
        } catch (error) {
            addToastUser(userNumber, 'error', error.message || 'Ошибка при обновлении объявления')
        } finally {
            this.setLoadingForAd(userNumber, adsNumber, false);
        }
    }

    async updateAdFull(userNumber, adsNumber, tradeType, adData) {
        this.setLoadingForAd(userNumber, adsNumber, true);
        try {
            await fetchAdsUpdate({
                userNumber,
                adsNumber,
                ...adData
            });
            this.updateAdDetailsTrade(userNumber, adsNumber, tradeType, adData);
        } catch (error) {
            addToastUser(userNumber, 'error', error.message || error.message || 'Ошибка при полном обновлении объявления')
        } finally {
            this.setLoadingForAd(userNumber, adsNumber, false);
        }
    }

    async updateAdAssetFree(userNumber, adsAsset, tradeType, marketNumber, adsNumber) {
        this.setLoadingForAd(userNumber, adsNumber, true);
        let valueToAdd = 0
        try {
            const response = await fetchBalanceUser(userNumber, 5, adsAsset);
            valueToAdd = parseFloat(response.data[adsAsset]["free"]);

            if (marketNumber === 1 || marketNumber === 4) {
                const responseAds = await fetchAdsDetail(userNumber, adsNumber);
                valueToAdd += parseFloat(responseAds.data.value);
            }

            this.updateAdDetailsTrade(userNumber, adsNumber, tradeType, { value: valueToAdd });

            addMessageUser(userNumber,'success',`Свободно ${valueToAdd} ${adsAsset}`)
        } catch (error) {
            addToastUser(userNumber, 'error', error.message || error.message || 'Ошибка при обновлении свободных активов')
        } finally {
            this.setLoadingForAd(userNumber, adsNumber, false);
            return valueToAdd
        }
    }

    async updateAdStatus(userNumber, adsNumber, tradeType, newAdsStatus) {
        this.setLoadingForAd(userNumber, adsNumber, true);
        try {
            const response = await fetchAdsStatus(userNumber, adsNumber, newAdsStatus);
            if (response.code !== 1000) {
                throw new Error('Запрос на изменение статуса завершился неудачно');
            }

            this.updateAdDetailsTrade(userNumber, adsNumber, tradeType, { adsStatus: newAdsStatus });
        } catch (error) {
            addToastUser(userNumber, 'error', error.message || 'Ошибка при изменении статуса объявления');
            throw error; // Пробрасываем ошибку дальше, чтобы она была обработана в btnAdsStatus
        } finally {
            this.setLoadingForAd(userNumber, adsNumber, false);
        }
    }

    async updateAdAmount(userNumber, adsNumber, tradeType, volume) {
        this.setLoadingForAd(userNumber, adsNumber, true);
        try {
            await fetchAdsUpdateAmount({
                userNumber,
                adsNumber,
                volume
            });
            this.updateAdDetailsTrade(userNumber, adsNumber, tradeType, { volume });
        } catch (error) {
            addToastUser(userNumber, 'error', error.message || error.message || 'Ошибка при обновлении объема объявления')

        } finally {
            this.setLoadingForAd(userNumber, adsNumber, false);
        }
    }
}

const adsStore = new AdsStore();
export default adsStore;
