import React, { useState } from 'react';
import { OneColCenter } from "../../../Layouts/formRow/2col";
import { Button, Modal } from 'antd';
import MerchantManagement from "./components/MerchantManagement";
import CommandManagement from "./components/CommandPage";
import MerchantRegistration from "./components/MerchantRegistration";

export function MercantPanel() {
    const [isCommandModalVisible, setIsCommandModalVisible] = useState(false);
    const [isMerchantRegistrationModalVisible, setIsMerchantRegistrationModalVisible] = useState(false);
    const [renderFlag, setRenderFlag] = useState(false);

    const showCommandModal = () => {
        setIsCommandModalVisible(true);
    };

    const hideCommandModal = () => {
        setIsCommandModalVisible(false);
    };

    const showMerchantRegistrationModal = () => {
        setIsMerchantRegistrationModalVisible(true);
    };

    const hideMerchantRegistrationModal = () => {
        setIsMerchantRegistrationModalVisible(false);
        // Trigger re-rendering of MerchantManagement
        setRenderFlag(!renderFlag);
    };

    return (
        <OneColCenter
            col1={() => (
                <>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                        <Button type="primary" onClick={showMerchantRegistrationModal}>
                            Добавить мерчанта
                        </Button>
                        <Button type="default" onClick={showCommandModal}>
                            Управление командами
                        </Button>
                    </div>
                    <MerchantManagement key={renderFlag} />
                    <Modal
                        style={{
                            top: 20,
                        }}
                        open={isCommandModalVisible}
                        onCancel={hideCommandModal}
                        footer={null}
                        width={800}
                    >
                        <CommandManagement/>
                    </Modal>
                    <Modal
                        style={{
                            top: 20,
                        }}
                        title="Регистрация мерчанта"
                        open={isMerchantRegistrationModalVisible}
                        onCancel={hideMerchantRegistrationModal}
                        footer={null}
                    >
                        <MerchantRegistration />
                    </Modal>
                </>
            )}
        />
    );
}
