import {API_URL} from "../configUrls";
import {postApiRequest} from "../apiParams";



export const fetchAdsList = (userNumber) => {
    return postApiRequest(`${API_URL}/ads/list-detail`, { userNumber });
};

export const fetchAdsDetail = (userNumber, adsNumber) => {
    return postApiRequest(`${API_URL}/ads/detail`, { userNumber, adsNumber });
};


export const fetchAdsStatus = (userNumber, adsNumber, advStatus) => {
    return postApiRequest(`${API_URL}/ads/update-status`, { userNumber, adsNumber, advStatus });
};

export const fetchAdsUpdate = (body) => {
    return postApiRequest(`${API_URL}/ads/update`, body);
};

export const fetchAdsUpdateAmount = (body) => {
    return postApiRequest(`${API_URL}/ads/update-amount`, body);
};



export const fetchArrayAdsLoading = (team) => {
    return postApiRequest(`${API_URL}/ads-array/list-detail`, {team});

};