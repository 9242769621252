import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col, Typography} from 'antd';
const { Title, Paragraph } = Typography;


const Home = () => {
    const navigate = useNavigate();
    const fullText = "Слушай, приятель, в жизни есть два важных правила: первое - никогда не оставляй свой пивной бокс без присмотра, а второе - всегда держи свой мотоцикл под контролем...";
    const [text, setText] = useState('');
    const [typing, setTyping] = useState(true);

    useEffect(() => {
        if (typing) {
            if (text.length < fullText.length) {
                setTimeout(() => {
                    setText(fullText.slice(0, text.length + 1));
                }, 50); // Скорость печатания
            } else {
                setTyping(false); // Завершаем печатание, когда весь текст отобразился
            }
        }
    }, [text, typing]);
    // Массив данных для карточек
    const cardsData = [
        {
            title: "Оплата ордеров",
            description: "С пулеметом в руках, становлюсь грозой на поле боя... Оплачу все ордера, я махом и без боя",
            image: "/assets/img/1672730923_foni-club-p-oboi-gachimuchi-41.jpg",
            link: "/pay"
        },
        {
            title: "Обработка заявок",
            description: "Все говорят, что саперы сумасшедшие, но мы просто привыкли обращаться с взрывчаткой...",
            image: "../../assets/img/1672730928_foni-club-p-oboi-gachimuchi-46.jpg",
            link: "/reception"
        },
        {
            title: "Редактор цен",
            description: "Командир не только приказывает, но и вдохновляет. Он несет ответственность за своих солдат...",
            image: "../../assets/img/1672730883_foni-club-p-oboi-gachimuchi-1.jpg",
            link: "/price"
        },
        // Добавьте больше карточек здесь, если нужно
    ];

    return (
        <div>

            <Row justify="center" gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title level={2}>Выбери ПО</Title>
                    <Paragraph className="desktop-only">
                        {text}
                        {typing && <span className="typing-cursor">|</span>}
                    </Paragraph>
                </Col>
            </Row>

            <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                {cardsData.map((card, index) => (
                    <Col key={index} xs={24} sm={12} md={8} onClick={() => navigate(card.link)}>
                        <Card
                            hoverable
                            className="home-custom-card"
                            cover={<img alt={card.title} src={card.image} style={{ height: '400px', objectFit: 'cover' }} />}
                        >
                            <Card.Meta title={card.title} description={card.description} />
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Home;
