import { makeAutoObservable, runInAction } from "mobx";
import {fetchOrderDetail, fetchLoadingOrders, fetchOrderDone} from "../config/api/apiGeneral";
import { message } from "antd";
import { addToast } from "../components/Notification/Toast";
import notificationSound from "../assets/audio/paid_order.mp3"
import soundCansel from "../assets/audio/cancel_order.mp3"
import soundAppeal from "../assets/audio/new_sell.mp3"
class TradeStore {
    dataOrders = [];
    loading = false;
    loadingList = false;
    globalTradeType = 'BUY'
    notificationSound = new Audio(notificationSound);
    soundCancel = new Audio(soundCansel);
    soundAppeal = new Audio(soundAppeal);
    notifications = [];
    soundStatus = false
    constructor() {
        makeAutoObservable(this);

    }


    setSoundStatus() {
        this.soundStatus = !this.soundStatus;
        if (this.soundStatus === true) {
            this.notificationSound.play()
            message.success(`Звуковые уведомления включены`,2);

        } else  {
            message.error(`Звуковые уведомления отключены`,2);

        }
    }

    setGlobalTradeType(tradeType) {

        this.globalTradeType = tradeType;
    }

    setDataOrders(data) {
        this.dataOrders.replace(data);
    }

    // setDataOrders(data) {
    //     // Создаем новый массив, повторяющий исходные данные 10 раз
    //     const data10 = Array.from({ length: 10 }, () => data).flat();
    //
    //     // Заменяем имеющиеся данные в this.dataOrders новыми данными, умноженными на 10
    //     this.dataOrders.replace(data10);
    // }

    setLoading(loading) {
        this.loading = loading;
    }


    setLoadingList(loadingList) {
        this.loadingList = loadingList;
    }
    handleOrderDone = async (item) => {
        let codePay = 0;
        try {
            const response = await fetchOrderDone(item);
            codePay = response.code
            if (response.code === 1000) {
                tradeStore.dataOrders = tradeStore.dataOrders.map(order =>
                    order.orderNumber === item.orderNumber ? { ...order, orderStatus: 'PAID',
                        tsPaid: Date.now(),
                        tsNotifyPaidEnd: Date.now() + order.timeOrder *60*1000 } : order
                );
                await addToast('success','Успешно',`${item.orderNumber} (${item.totalPrice} рублей)  ${response.msg}.`);
            } else if (response.code === 1222) {
                // Обновляем статус конкретного заказа на 'PAID'
                tradeStore.dataOrders = tradeStore.dataOrders.map(order =>
                    order.orderNumber === item.orderNumber ? { ...order, orderStatus: 'PAID' } : order
                );

                await addToast('warning','Внимание',`${item.orderNumber} (${item.totalPrice} рублей)  ${response.msg}.`);
            } else {
                await addToast('error','Ошибка',`${item.orderNumber} (${item.totalPrice} рублей)  ${response.msg}.`);
            }
        } catch (error) {
            await addToast('warning', 'Внимание', `${error}.`);
        }
        return codePay
    };

    updateNote = async (orderNumber, status)  =>{
        this.setDataOrders(this.dataOrders.map(order => {
            if (order.orderNumber === orderNumber) {
                return { ...order, disabledNote: status };
            }
            return order;
        }));
    }

    orderDetailInfoReturn = async (userNumber, orderNumber) => {
        try {
            const response = await fetchOrderDetail(userNumber, orderNumber);
            const myOrder = response.data;
            const orderIndex = this.dataOrders.findIndex(order => order.orderNumber === orderNumber);

            if (orderIndex !== -1) {
                const order = this.dataOrders[orderIndex];

                if (order.orderStatus !== myOrder.orderStatus) {
                    // Случай изменения статуса на PAID
                    if (myOrder.orderStatus === 'PAID') {
                        order.orderStatus = 'PAID';
                        order.tsPaid = myOrder.timeStamp;
                        order.tsNotifyPaidEnd = myOrder.timeStamp + myOrder.timeOrder * 60 * 1000;
                    }
                    // Случай изменения статуса на APPEAL
                    else if (myOrder.orderStatus === 'APPEAL') {
                        order.orderStatus = 'APPEAL';
                        order.tsAppeal = myOrder.timestamp;
                    }
                    // Случаи удаления заказа
                    else if (['CANCEL', 'CANCEL_SYS', 'COMPLETE'].includes(myOrder.orderStatus)) {
                        const order = this.dataOrders[orderIndex];
                        if (order) {
                            this.dataOrders.splice(orderIndex, 1);
                        } else {
                            console.warn(`Order with number ${myOrder.orderNumber} not found in dataOrders.`);
                        }
                    }
                    else {
                        // Применение нового статуса, если он отличается и не требует специальной обработки
                        order.orderStatus = myOrder.orderStatus;
                    }
                }
            }
            return myOrder;
        } catch (error) {
            await addToast('error', 'Информация о ордере', `${error}.`);
            return {};
        } finally {
            this.updateDataOrdersOnDetail(orderNumber);
        }
    }

    upLoadingOrders = async () => {
        this.setLoadingList(true);
        try {
            const team = localStorage.getItem('team');
            const response = await fetchLoadingOrders(this.globalTradeType, team);
            runInAction(() => {
                this.setDataOrders(response.data);
                this.setLoadingList(false);
            });
        } catch (error) {
            runInAction(() => {
                this.setLoadingList(false);
            });
            await addToast('error', 'Список активных ордеров', `${error}.`);
        }
    };

    updateDataOrdersOnDetail(orderNumber) {
        this.setDataOrders(this.dataOrders.map(order => {
            if (order.orderNumber === orderNumber) {
                return { ...order, msgCount: 0 };
            }
            return order;
        }));
    }

    addNotification(notification) {
        const timestamp = new Date().toLocaleTimeString(); // Получаем текущее время
        const notificationWithTimestamp = { ...notification, timestamp }; // Добавляем метку времени к уведомлению

        this.notifications.push(notificationWithTimestamp);
    }

    clearNotifications() {
        this.notifications = [];
    }


    notifyOrder(order, orderStatus) {
        const { tradeType, totalPrice,userNumber,orderNumber } = order;
        switch (orderStatus) {
            case "COMPLETE":
                if (tradeType === "BUY") {
                    // message.success(`Покупка на  ${formatNumber(totalPrice)} - завершена`,15);
                    this.addNotification({orderStatus: orderStatus, tradeType: tradeType, totalPrice: totalPrice, userNumber: userNumber,orderNumber: orderNumber})

                }
                break;
            case "APPEAL":
                if (this.soundStatus) {
                    this.soundAppeal.play(); // Воспроизводим звуковое уведомление
                }

                // message.error(`${tradeType} ${formatNumber(totalPrice)} - Открыл апелляцию`,15);
                this.addNotification({orderStatus: orderStatus, tradeType: tradeType, totalPrice: totalPrice, userNumber: userNumber,orderNumber: orderNumber})

                break;
            case "CANCEL":
                if (this.soundStatus) {
                    this.soundCancel.play(); // Воспроизводим звуковое уведомление
                }

                // message.warning(`${tradeType} ${formatNumber(totalPrice)} - Отменился ордер`,15);
                this.addNotification({orderStatus: orderStatus, tradeType: tradeType, totalPrice: totalPrice, userNumber: userNumber,orderNumber: orderNumber})

                break;
            default:
                break;
        }
    }
    handleWebSocketMessage = (data) => {
        if (this.soundStatus === true) {
            this.notificationSound.play()}
        if (this.globalTradeType !== 'NIGHT' && data.tradeType !== this.globalTradeType) {
            return;
        }
        switch (data.orderStatus) {
            case 'PEND':
                this.handlePendStatus(data);
                break;
            case 'PAID':
                this.handlePaidStatus(data);
                break;
            case 'APPEAL':
                this.handleAppealStatus(data);
                break;
            case 'CANCEL':
            case 'CANCEL_SYS':
                this.handleCompleteStatus(data);
                break;
            case 'COMPLETE':
                this.handleCompleteStatus(data);
                break;
            default:
                this.handleMessages(data);
                break;
        }
    }
    updateOrFetchOrder = async (data, statusKey, timestampKey, additionalTime = false) => {
        const orderIndex = this.dataOrders.findIndex(order => order.orderNumber === data.orderNumber);

        // Обновление существующего заказа
        if (orderIndex !== -1) {
            const order = this.dataOrders[orderIndex];
            runInAction(() => {
                this.updateOrderData(order, data, statusKey, timestampKey, additionalTime);
            });
            this.notifyOrder(order, data.orderStatus);
            return;
        }

        // Загрузка и добавление нового заказа
        try {
            const detail = await this.orderDetailInfoReturn(data.userNumber, data.orderNumber);
            if (detail) {
                runInAction(() => {
                    this.dataOrders.push({...detail, orderStatus: data.orderStatus});
                });
                this.notifyOrder(detail, data.orderStatus);
            }
        } catch (error) {
            console.error("Failed to load order details:", error);
        }
    }

// Вспомогательная функция для обновления данных заказа
    updateOrderData = (order, data, statusKey, timestampKey, additionalTime) => {
        order.orderStatus = data.orderStatus;
        order[timestampKey] = data.timestamp;
        if (additionalTime) {
            const minutes = order.timeOrder * 60 * 1000; // Переводим минуты в миллисекунды
            order.tsNotifyPendEnd = data.timestamp + minutes;
        }
    }

    handlePaidStatus(data) {
        this.updateOrFetchOrder(data, 'PAID', 'tsPaid',true );
    }

    handleCancelStatus(data) {
        this.updateOrFetchOrder(data, 'CANCEL', 'tsCancel');
    }

    handleAppealStatus(data) {
        this.updateOrFetchOrder(data, 'APPEAL', 'tsAppeal');
    }

    handlePendStatus(data) {
        this.updateOrFetchOrder(data, 'PEND', 'tsPend');
    }


    handleCompleteStatus = (data) => {
        const orderIndex = this.dataOrders.findIndex(order => order.orderNumber === data.orderNumber);
        const order = this.dataOrders[orderIndex];
        if (order) {
            this.notifyOrder(order, data.orderStatus);
            this.dataOrders.splice(orderIndex, 1);
        } else {
            console.warn(`Order with number ${data.orderNumber} not found in dataOrders.`);
        }
    }
    handleMessages(data) {
        // if (this.soundStatus === true) {
        //     this.notificationSound.play()}
        if (data.name === 'message' && data.role === 'agent2') {
            this.setDataOrders(this.dataOrders.map(order => {
                if (order.orderNumber === data.orderNumber) {
                    return {
                        ...order,
                        msgCount: (order.msgCount || 0) + 1

                    };

                }

                return order;
            }));
        }
    }

}


export const tradeStore = new TradeStore();


