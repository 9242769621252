import React from 'react';
import {Row,Col} from "antd";
import {CalcComp} from "../../../CalculatorPage/CalculatorPage";
function AddCal() {
    return (
        <Row justify="center">
                <CalcComp size={24}/>
        </Row>

    );
}

export default AddCal;
