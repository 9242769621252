import React, { } from 'react';
import { observer } from 'mobx-react';
import {
    AreaChartOutlined,
    SwapOutlined,
} from '@ant-design/icons';
import { Select, InputNumber, Collapse, Card, Row, Col, Radio,Button,Tooltip} from 'antd';
import CalcP2PStore from "../../state/CalcP2PStore";
import {OneColCenter} from "../../Layouts/formRow/2col";
const { Option } = Select;


const stepOptions = [
    { label: '2 Шага', value: 2 },
    { label: '3 Шага', value: 3 },
];


export const CalcComp = observer((size) => {
    const handleExchangeChange = value => CalcP2PStore.setExchange(value);
    const handleAssetChange = value => CalcP2PStore.setAsset(value);
    const handleSecondAssetChange = value => CalcP2PStore.setSecondAsset(value);
    const handleDepositChange = value => CalcP2PStore.setDeposit(value);
    const handleBuyRateChange = value => CalcP2PStore.setBuyRate(value);
    const handleConversionRateChange = value => CalcP2PStore.setConversionRate(value);
    const handleSellRateChange = value => CalcP2PStore.setSellRate(value);
    const handleIsMakerBuyChange = checked => CalcP2PStore.setIsMakerBuy(checked);
    const handleIsMakerSellChange = checked => CalcP2PStore.setIsMakerSell(checked);
    const details = CalcP2PStore.calculateDetails();
    const selectBeforeBuy = (
        <Select onChange={handleIsMakerBuyChange}
                value={CalcP2PStore.isMakerBuy}
        >
            <Option value={true}>Мейкер</Option>
            <Option value={false}>Тейкер</Option>
        </Select>
    );

    const selectBeforeSell = (
        <Select onChange={handleIsMakerSellChange}
                value={CalcP2PStore.isMakerSell}
        >
            <Option value={true}>Мейкер</Option>
            <Option value={false}>Тейкер</Option>
        </Select>
    );
    const collapseItems = [
        {
            key: '1',
            label: `Детали расчета`,
            children: CalcP2PStore.steps === 2 ? (
                <>
                    <p>1) Куплено активов: {details.assetsBought} {CalcP2PStore.asset}</p>
                    <p>2) Комиссия за покупку: {details.buyCommission} {CalcP2PStore.asset}</p>
                    <p>3) Активы после комиссии за покупку: {details.assetsAfterBuyCommission} {CalcP2PStore.asset}</p>
                    <p>4) Доход до комиссии за продажу: {Number(details.revenueBeforeSellCommission).toLocaleString('ru-RU')} рублей</p>
                    <p>5) Комиссия за продажу: {Number(details.sellCommission).toLocaleString('ru-RU')} рублей</p>
                    <p>6) Доход после комиссии за продажу: {Number(details.revenueAfterSellCommission).toLocaleString('ru-RU')} рублей</p>
                    <p>7) Прибыль: {Number(details.profit).toLocaleString('ru-RU')} рублей</p>
                    <p>8) Прибыль (%): {details.profitPercentage}%</p>
                </>
            ) : (
                <>
                    <p>1) Куплено активов: {details.assetsBought} {CalcP2PStore.asset}</p>
                    <p>2) Комиссия за покупку: {details.buyCommission} {CalcP2PStore.asset}</p>
                    <p>3) Активы после комиссии за покупку: {details.assetsAfterBuyCommission} {CalcP2PStore.asset}</p>
                    <p>4) Конвертировано в {CalcP2PStore.secondAsset}: {details.convertedAssets}</p>
                    <p>5) Комиссия за конвертацию: {details.conversionCommission} {CalcP2PStore.secondAsset}</p>
                    <p>6) Доход до комиссии за продажу: {Number(details.revenueBeforeSellCommission).toLocaleString('ru-RU')} рублей</p>
                    <p>7) Комиссия за продажу: {Number(details.sellCommission).toLocaleString('ru-RU')} рублей</p>
                    <p>8) Доход после комиссии за продажу: {Number(details.revenueAfterSellCommission).toLocaleString('ru-RU')} рублей</p>
                    <p>9) Прибыль: {Number(details.profit).toLocaleString('ru-RU')} рублей</p>
                    <p>10) Прибыль (%): {details.profitPercentage}%</p>
                </>
            )
        }
    ];
    return (
        <>
                <Col span={size}>
                    <Card  title="Калькулятор Спреда" bordered={false} style={{textAlign:"center", marginBottom: 16}} size="small">
                        <Radio.Group
                            onChange={e => CalcP2PStore.setSteps(e.target.value)}
                            value={CalcP2PStore.steps}
                            options={stepOptions}
                            optionType="button"
                            buttonStyle="solid"
                            style={{width:"100%"}}
                        />
                    </Card>
                    <Card  title="Депозит" bordered={false} style={{textAlign:"center", marginBottom: 16}} size="small">
                        <Row gutter={16}>

                            <Col span={18}>
                                <InputNumber
                                    value= {CalcP2PStore.deposit}
                                    min={0}
                                    style={{ width: '100%' }}
                                    onChange={handleDepositChange}
                                    addonAfter="₽"
                                />

                            </Col>
                            <Col span={6}>
                                <Select value={CalcP2PStore.exchange} style={{ width: '100%' }} onChange={handleExchangeChange}>
                                    {Object.keys(CalcP2PStore.EXCHANGES).map(exchange => (
                                        <Option key={exchange} value={exchange}>{exchange}</Option>
                                    ))}
                                </Select>
                            </Col>

                        </Row>
                    </Card>

                    <Card  title="Покупка" style={{textAlign:"center", marginBottom: 16}} bordered={false} size="small">
                        <Row gutter={16}>
                            <Col span={18}>
                                <InputNumber
                                    addonBefore = {selectBeforeBuy}
                                    value={CalcP2PStore.buyRate}
                                    addonAfter="₽"
                                    min={0}
                                    step={0.01}
                                    style={{ width: '100%' }}
                                    onChange={handleBuyRateChange}
                                />
                            </Col>
                            <Col span={6}>
                                <Select value={CalcP2PStore.asset} style={{ width: '100%' }} onChange={handleAssetChange} >
                                    {Object.keys(CalcP2PStore.ASSETS).map(key => (
                                        <Option key={key} value={key}>{key}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                    </Card>
                    {CalcP2PStore.steps === 3 && (
                        <Card  title="Конвертация" style={{textAlign:"center", marginBottom: 16}} bordered={false} size="small">
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Tooltip title="Поменять местами" placement="bottom">
                                        <Button style={{ width: '100%' }} icon={<SwapOutlined />} onClick={CalcP2PStore.switchAssets}/>
                                    </Tooltip>
                                </Col>
                                <Col span={12}>
                                    <InputNumber
                                        value={CalcP2PStore.conversionRate}
                                        min={0}
                                        step={0.01}
                                        style={{ width: '100%' }}
                                        onChange={handleConversionRateChange}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Tooltip title="Автозагрузка курса" placement="bottom">
                                        <Button style={{ width: '100%' }}  icon={<AreaChartOutlined />}  onClick={CalcP2PStore.fetchConversionRate}/>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Card>
                    )}
                    <Card  title="Продажа" style={{textAlign:"center", marginBottom: 16}} bordered={false} size="small">
                        <Row gutter={16}>
                            <Col span={18}>
                                <InputNumber
                                    value={CalcP2PStore.sellRate}
                                    addonBefore = {selectBeforeSell}
                                    addonAfter="₽"
                                    min={0}
                                    step={0.01}
                                    style={{ width: '100%' }}

                                    onChange={handleSellRateChange}
                                />
                            </Col>
                            <Col span={6}>
                                {CalcP2PStore.steps === 3 && (
                                    <Select value={CalcP2PStore.secondAsset} style={{ width: '100%'}} onChange={handleSecondAssetChange}>
                                        {Object.keys(CalcP2PStore.ASSETS).filter(key => key !== CalcP2PStore.asset).map(key => (
                                            <Option key={key} value={key}>{key}</Option>
                                        ))}
                                    </Select>
                                )}
                            </Col>
                        </Row>
                    </Card>
                    <Card  title={`Профит: ${Number(details.profit).toLocaleString('ru-RU')} рублей (${details.profitPercentage}%)`} style={{textAlign:"center", marginBottom: 16}} bordered={false} size="small">
                        <Row gutter={16}>
                            <Col span={24}>
                                <Collapse items={collapseItems} />
                            </Col>
                        </Row>
                    </Card>


                </Col>
        </>

    );
});


const ExchangeCalculator = observer(() => {
    return (
        <OneColCenter
            col1={() => (
                <>
                    <Row justify="center">
                        <CalcComp size={12}/>
                    </Row>
                </>
            )}
        />

    );
});

export default ExchangeCalculator;
