import React, { useState } from 'react';
import { Card, Button, Input, Skeleton } from 'antd'; // Импорт компонентов из Ant Design
import { uploadBrokerSession } from "../../config/crm/apiBroker";
import { addToast } from "../../components/Notification/Toast";

const { TextArea } = Input; // Деструктурирование TextArea из Input

const BrokerSession = ({ team }) => {
    const [session, setSession] = useState("");

    const getName = (team) => {
        switch (team) {
            case "1":
                return "Banana";
            case "4":
                return "TolikBTC";
            case "3":
                return "Kirill AMG";
            case "2":
                return "HunterZoom";
            default:
                return "";
        }
    };

    const handleSubmit = async () => {
        if (!team) return;

        try {
            const sessionFileName = `${team}_session.json`;
            const sessionBlob = new Blob([session.trim()], { type: 'application/json' });
            const sessionFormData = new FormData();
            sessionFormData.append('file', sessionBlob, sessionFileName);

            const sessionResponse = await uploadBrokerSession(sessionFormData);
            if (sessionResponse.code === 1000) {
                await addToast('success', 'Успешно', `Сессия для команды ${team} успешно отправлена`);
            } else {
                await addToast('error', 'Ошибка', `Не удалось отправить сессию для команды ${team}.`);
                return; // Прерываем выполнение, если отправка сессии не удалась
            }

            setSession('');
        } catch (error) {
            await addToast('error', 'Ошибка', `Ошибка при отправке данных для команды ${team}`);
        }
    };

    return (
        <div style={{ marginTop: 20 }}>
            <Card>
                {team && (
                    <span>ВАЖНО ОТПРАВИТЬ ТОЛЬКО {getName(team)}</span>
                )}
                <Skeleton loading={!team}>
                    <TextArea
                        value={session}
                        onChange={e => setSession(e.target.value)}
                        rows={8}
                        placeholder="Сессия"
                        style={{ marginBottom: '1rem' }}
                    />
                </Skeleton>
            </Card>
            <Button type="primary" onClick={handleSubmit}>
                Отправить
            </Button>
        </div>
    );
};

export default BrokerSession;
