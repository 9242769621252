import React, { useState,useEffect } from 'react';
import { Table, Button, Tooltip, Col, Card, Modal, Row, Radio, Select, Input } from 'antd';
import { fetchBalanceUser, fetchCoinToCoin } from "../../config/api/apiGeneral";
import { handleCopyReplace, handleCopy } from "../../components/LogicComponents";
import { addToast } from "../../components/Notification/Toast";
import MerchStore from "../../state/MerchStore";
import { CustomCardRow } from "../../Layouts/Card/CustomCard";
import { assetsOptions, ASSETS_DEF } from "../../config/constants";
import { renderCryptoAddress } from "../../components/CommonComponents";

const { Option } = Select;

function formatAssetValue(value, asset) {
    const assetOption = assetsOptions.find(option => option.value === asset);
    const round = assetOption ? assetOption.round : 2;
    return value.toFixed(round);
}

function BalanceMarket({ dataSource, marketName, currentType }) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(dataSource);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentAsset, setCurrentAsset] = useState('');
    const [detailDataSource, setDetailDataSource] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState(null); // Для отслеживания раскрытого ряда
    const [selectedUser, setSelectedUser] = useState(null); // Для хранения выбранного пользователя для перевода
    const [amount, setAmount] = useState(''); // Для хранения введенной суммы
    const [isTransferLoading, setIsTransferLoading] = useState(false); // Индикация загрузки на кнопке "Перевести"

    const fetchData = async (type = currentType) => {
        setIsLoading(true);
        try {
            const newData = await Promise.all(
                dataSource.map(async user => {
                    try {
                        const response = await fetchBalanceUser(user.userNumber, parseInt(currentType));
                        return { ...user, assets: response.data };
                    } catch (error) {
                        await addToast("error", `Ошибка загрузки баланса`, `${user.marketName} ${user.nickName}`)
                        return { ...user, assets: ASSETS_DEF };
                    }
                })
            );
            setData(newData);
        } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []); // или другая переменная, которая изменяется при открытии компонента
    const showModal = (asset) => {
        setCurrentAsset(asset);
        setDetailDataSource(data.map(user => ({
            key: user.userNumber,
            nickName: user.nickName,
            cryptoBEP20: user.cryptoBEP20,
            cryptoTRC20: user.cryptoTRC20,
            freeze: user.assets[asset].freeze,
            free: user.assets[asset].free,
            total: user.assets[asset].total
        })));
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleTransfer = async (userNumber, asset) => {
        if (!selectedUser || !amount) {
            await addToast("error", "Ошибка", "Выберите пользователя и введите сумму");
            return;
        }

        setIsTransferLoading(true); // Начало загрузки
        const body = {
            userNumber: selectedUser, // Пользователь из селекта
            userNumberAddress: userNumber, // Пользователь, на котором раскрыли
            amount: parseFloat(amount),
            asset: asset,
        };

        try {
            const response = await fetchCoinToCoin(body);
            if (response.code === 1000) {
                await addToast("success", response.msg, ""); // Используем msg для успешного ответа
                setAmount(''); // Очистка поля после успешного перевода
            } else {
                await addToast("error", "Ошибка перевода", response.msg || "Неизвестная ошибка");
            }
        } catch (error) {
            console.error('Ошибка при отправке запроса:', error);
            await addToast("error", "Ошибка выполнения запроса", error.message);
        } finally {
            setIsTransferLoading(false); // Завершение загрузки
        }
    };

    const expandedRowRender = (record) => {
        const otherUsers = data.filter(user => user.userNumber !== record.key);

        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <span>Перевести с</span>
                <Select
                    placeholder="Выберите пользователя"
                    style={{ width: 200 }}
                    onChange={value => setSelectedUser(value)}
                >
                    {otherUsers.map(user => (
                        <Option key={user.userNumber} value={user.userNumber}>
                            {user.nickName}
                        </Option>
                    ))}
                </Select>
                <Input
                    placeholder="Сумма"
                    type="number"
                    step="0.01"
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    style={{ width: 120 }}
                />
                <Button
                    type="primary"
                    onClick={() => handleTransfer(record.key, currentAsset)}
                    loading={isTransferLoading} // Индикация загрузки на кнопке
                >
                    Перевести
                </Button>
            </div>
        );
    };

    const onExpand = (expanded, record) => {
        if (expanded) {
            setExpandedRowKey(record.key);
            setSelectedUser(null); // Сброс выбранного пользователя при раскрытии новой строки
            setAmount(''); // Сброс суммы при раскрытии новой строки
        } else {
            setExpandedRowKey(null); // Свернуть строку
        }
    };

    const detailColumns = [
        {
            title: 'Никнейм',
            width: '15%',
            dataIndex: 'nickName',
            key: 'nickName',
        },
        {
            title: 'BEP-20',
            width: '20%',
            dataIndex: 'cryptoBEP20',
            freeze: 'cryptoBEP20',
            render: (_, record) => (
                <span onClick={() => handleCopy(record.cryptoBEP20, `BEP-20 ${record.nickName} ${record.marketName}`)} style={{ cursor: 'pointer' }}>{renderCryptoAddress(record.cryptoBEP20)}</span>
            )
        },
        {
            title: 'TRC-20',
            width: '20%',
            dataIndex: 'cryptoTRC20',
            freeze: 'cryptoTRC20',
            render: (_, record) => (
                <span onClick={() => handleCopy(record.cryptoTRC20, `TRC-20 ${record.nickName} ${record.marketName}`)} style={{ cursor: 'pointer' }}>{renderCryptoAddress(record.cryptoTRC20)}</span>
            )
        },
        {
            title: 'Заморожено',
            width: '15%',
            dataIndex: 'freeze',
            freeze: 'freeze',
            render: (_, record) => (
                <span onClick={() => handleCopyReplace(record.freeze)} style={{ cursor: 'pointer' }}>{record.freeze}</span>
            )
        },
        {
            title: 'Свободно',
            width: '15%',
            dataIndex: 'free',
            render: (_, record) => (
                <span onClick={() => handleCopyReplace(record.free)} style={{ cursor: 'pointer' }}>{record.free}</span>
            )
        },
        {
            title: 'Итого',
            width: '15%',
            key: 'total',
            render: (_, record) => (
                <span onClick={() => handleCopyReplace(record.total)} style={{ cursor: 'pointer' }}><b>{record.total}</b></span>
            )
        },
    ];

    const tableData = assetsOptions.map(option => {
        const asset = option.value;

        const assetTotals = data.reduce((acc, user) => {
            acc.freeze += user.assets[asset].freeze;
            acc.free += user.assets[asset].free;
            acc.total += user.assets[asset].total;
            return acc;
        }, { freeze: 0, free: 0, total: 0 });

        return {
            key: asset,
            asset,
            freeze: formatAssetValue(assetTotals.freeze, asset),
            free: formatAssetValue(assetTotals.free, asset),
            total: formatAssetValue(assetTotals.total, asset),
        };
    });

    const columns = [
        {
            title: 'Актив',
            dataIndex: 'asset',
            key: 'asset',
            render: (_, record) => (
                <Button style={{ width: "100%" }} onClick={() => showModal(record.asset)}>{record.asset}</Button>
            ),
        },
        {
            title: 'Свободно',
            dataIndex: 'free',
            key: 'free',
            render: (_, record) => (
                <span onClick={() => handleCopyReplace(record.free)} style={{ cursor: 'pointer' }}>{record.free}</span>
            )
        },
        {
            title: 'Итого',
            dataIndex: 'total',
            key: 'total',
            render: (_, record) => (
                <Tooltip title={`Заморожено: ${record.freeze}`} placement="bottom">
                    <span onClick={() => handleCopyReplace(record.total)} style={{ cursor: 'pointer' }}><b>{record.total}</b></span>
                </Tooltip>
            )
        },
    ];

    return (
        <>
            <Card bordered={false}
                  title={marketName}
                  extra={<Button onClick={fetchData} loading={isLoading}>Обновить баланс</Button>}
            >
                <Table
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                />
                <Modal
                    title={`Подробности - ${marketName} : ${currentAsset}`}
                    open={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                    width={800}
                >
                    <Table
                        dataSource={detailDataSource}
                        columns={detailColumns}
                        expandable={{
                            expandedRowRender,
                            expandedRowKeys: [expandedRowKey], // Только одна раскрытая строка
                            onExpand: onExpand
                        }}
                    />
                </Modal>
            </Card>
        </>
    );
}

function BalanceComponent({ size }) {
    const [currentType, setCurrentType] = useState(1);

    MerchStore.userNumbers.forEach(user => {
        user.assets = user.assets || ASSETS_DEF;
    });

    const groupedByMarketNumber = {};
    MerchStore.userNumbers.forEach(user => {
        if (!groupedByMarketNumber[user.marketNumber]) {
            groupedByMarketNumber[user.marketNumber] = {
                marketName: user.marketName,
                data: []
            };
        }
        groupedByMarketNumber[user.marketNumber].data.push(user);
    });
    const resultArray = Object.values(groupedByMarketNumber);
    const optionsTypeNames = [
        { label: 'Кошелек пополнения', value: 1 },
        { label: 'T+1', value: 5 },
        { label: 'Не оплаченные ордера', value: 2 },
        { label: 'Спотовый кошелек', value: 3 },
    ];
    const onChange = ({ target: { value } }) => {
        setCurrentType(value);
    };
    return (
        <>
            <CustomCardRow
                children={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Radio.Group
                            options={optionsTypeNames}
                            value={currentType}
                            optionType="button"
                            buttonStyle="solid"
                            size="small"
                            onChange={onChange}
                        />
                    </div>

                }
                children2={
                    <>
                        <Row gutter={[16, 16]}>
                            {resultArray.map((market, index) => (
                                <Col key={index} xs={24} sm={24} md={12} lg={12} xl={size}>
                                    <BalanceMarket dataSource={market.data} marketName={market.marketName} currentType={currentType} />
                                </Col>
                            ))}
                        </Row>
                    </>
                }
            />
        </>
    );
}

export default BalanceComponent;
