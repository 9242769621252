export const userNumbers = [
    { userNumber: '101', nickName: 'VanHe1sing', market: 1, marketName: "Binance",colorGlass: '#BDECB6', proxy : "85.209.9.53:3331", cryptoBEP20: "0xd5797ee2118f015f95ed479f9c239da8822b7050", cryptoTRC20: "TJP1gCQjLcwDHMb9DMWfZgABvi8bgLzz1V" },
    { userNumber: '107', nickName: '貨幣兌換點', market: 1, marketName: "Binance",colorGlass: '#9ACEEB', proxy : "82.147.71.154:3331", cryptoBEP20: "0x9a9c475d9de083721a70cb95210386abc9006059", cryptoTRC20: "TGxNzX8A75pPTbPmGssJDPa7vtnBMTyjX3"  },
    { userNumber: '108', nickName: 'RocketJump', market: 1, marketName: "Binance",colorGlass: '#f5b0f5', proxy : "82.147.71.163:3331", cryptoBEP20: "0xcb5bd5a9de4bd3da212e7217f14f87986375a2df", cryptoTRC20: "TGyZrpH4wZQiw4SvqVLEF2qJrmyvefFtVQ"  },
    { userNumber: '109', nickName: 'Rodion111', market: 1, marketName: "Binance",colorGlass: '#FFCA86', proxy : "81.31.247.239:3331", cryptoBEP20: "0xd6f129e5fc5e3d2c2829e3b0b895363fa4da9cdc", cryptoTRC20: "TGvQXubZyRwNv5NwNJSXWxPe9ipewmcUbY"  },
    { userNumber: '201', nickName: 'VanHe1sing', market: 2, marketName: "ByBit",colorGlass: '#BDECB6', proxy : "85.209.9.53:3331", cryptoBEP20: "0xfa338e85af1fc61c0128eb6b9c5561207292b49b", cryptoTRC20: "TPtmFqBQV8YdWMvzzAVEjgQpE1EayMrAqh"  },
    { userNumber: '202', nickName: 'florgesss', market: 2, marketName: "ByBit",colorGlass: '#9ACEEB', proxy : "85.209.9.60:3331", cryptoBEP20: "0x35b3b90911413b87298d140dd2cfe2f855d62424", cryptoTRC20: "TMtqu7pbe3N6xCcDNjpnMsK5BbtbbgCS3H"   },
    { userNumber: '203', nickName: 'Effi', market: 2, marketName: "ByBit",colorGlass: '#f5b0f5', proxy : "81.31.247.194:3331", cryptoBEP20: "0xc72f8d8138faef39003a722f602eee505ce5f7dd", cryptoTRC20: "TKehsgm1K1nFFDTFRedBnmWUD7vWwbXRPn"},
    { userNumber: '301', nickName: 'VanHe1sing', market: 3, marketName: "HTX",colorGlass: '#BDECB6', proxy : "85.209.9.53:3331", cryptoBEP20: "1111", cryptoTRC20: "1111"  },
    { userNumber: '302', nickName: 'florgesss', market: 3, marketName: "HTX",colorGlass: '#9ACEEB', proxy : "85.209.9.60:3331", cryptoBEP20: "1111", cryptoTRC20: "1111"   },
    { userNumber: '401', nickName: 'VanHe1sing', market: 4, marketName: "Commex",colorGlass: '#BDECB6', proxy : "85.209.9.53:3331", cryptoBEP20: "1111", cryptoTRC20: "1111"   },
    { userNumber: '405', nickName: 'V3 EXCHANGE', market: 4, marketName: "Commex",colorGlass: '#9ACEEB', proxy : "85.209.9.209:3111", cryptoBEP20: "1111", cryptoTRC20: "1111" },

    { userNumber: '101', nickName: 'VanHe1sing', market: 1, marketName: "Binance", colorGlass: '#BDECB6', proxy : "85.209.9.53:3331", cryptoBEP20: "0xd5797ee2118f015f95ed479f9c239da8822b7050", cryptoTRC20: "TJP1gCQjLcwDHMb9DMWfZgABvi8bgLzz1V" },
    { userNumber: '107', nickName: '貨幣兌換點', market: 1, marketName: "Binance",colorGlass: '#9ACEEB', proxy : "82.147.71.154:3331", cryptoBEP20: "0x9a9c475d9de083721a70cb95210386abc9006059", cryptoTRC20: "TGxNzX8A75pPTbPmGssJDPa7vtnBMTyjX3"  },
    { userNumber: '108', nickName: 'RocketJump', market: 1, marketName: "Binance",colorGlass: '#f5b0f5', proxy : "82.147.71.163:3331", cryptoBEP20: "0xcb5bd5a9de4bd3da212e7217f14f87986375a2df", cryptoTRC20: "TGyZrpH4wZQiw4SvqVLEF2qJrmyvefFtVQ"  },
    { userNumber: '109', nickName: 'Rodion111', market: 1, marketName: "Binance",colorGlass: '#FFCA86', proxy : "81.31.247.239:3331", cryptoBEP20: "0xd6f129e5fc5e3d2c2829e3b0b895363fa4da9cdc", cryptoTRC20: "TGvQXubZyRwNv5NwNJSXWxPe9ipewmcUbY"  },
    { userNumber: '201', nickName: 'VanHe1sing', market: 2, marketName: "ByBit",colorGlass: '#BDECB6', proxy : "85.209.9.53:3331", cryptoBEP20: "0xfa338e85af1fc61c0128eb6b9c5561207292b49b", cryptoTRC20: "TPtmFqBQV8YdWMvzzAVEjgQpE1EayMrAqh"  },
    { userNumber: '202', nickName: 'florgesss', market: 2, marketName: "ByBit",colorGlass: '#9ACEEB', proxy : "85.209.9.60:3331", cryptoBEP20: "0x35b3b90911413b87298d140dd2cfe2f855d62424", cryptoTRC20: "TMtqu7pbe3N6xCcDNjpnMsK5BbtbbgCS3H"   },
    { userNumber: '203', nickName: 'Effi', market: 2, marketName: "ByBit",colorGlass: '#f5b0f5', proxy : "81.31.247.194:3331", cryptoBEP20: "0xc72f8d8138faef39003a722f602eee505ce5f7dd", cryptoTRC20: "TKehsgm1K1nFFDTFRedBnmWUD7vWwbXRPn"},
    { userNumber: '301', nickName: 'VanHe1sing', market: 3, marketName: "HTX",colorGlass: '#BDECB6', proxy : "85.209.9.53:3331", cryptoBEP20: "1111", cryptoTRC20: "1111"  },
    { userNumber: '302', nickName: 'florgesss', market: 3, marketName: "HTX",colorGlass: '#9ACEEB', proxy : "85.209.9.60:3331", cryptoBEP20: "1111", cryptoTRC20: "1111"   },
    { userNumber: '401', nickName: 'VanHe1sing', market: 4, marketName: "Commex",colorGlass: '#BDECB6', proxy : "85.209.9.53:3331", cryptoBEP20: "1111", cryptoTRC20: "1111"   },
    { userNumber: '405', nickName: 'V3 EXCHANGE', market: 4, marketName: "Commex",colorGlass: '#9ACEEB', proxy : "85.209.9.209:3111", cryptoBEP20: "1111", cryptoTRC20: "1111" }
];

export function getAllMarkets() {
    const marketsMap = new Map();
    userNumbers.forEach(user => {
        marketsMap.set(user.market, { market: user.market, marketName: user.marketName });
    });
    return Array.from(marketsMap.values());
}

export function getAllNickNameValue() {
    const marketsMap = new Map();
    userNumbers.forEach(user => {
        marketsMap.set(user.nickName, { text: user.nickName, value: user.nickName });
    });
    return Array.from(marketsMap.values());
}

export function getNickNameByUserNumber(userNumber) {
    const user = userNumbers.find(user => user.userNumber === userNumber);
    return user ? user.nickName : null;
}

export function getUserInfoByUserNumber(userNumber) {
    return userNumbers.find(user => user.userNumber === userNumber);
}

export function getAllMarketsValue() {
    const marketsMap = new Map();
    userNumbers.forEach(user => {
        marketsMap.set(user.market, { text: user.marketName, value: user.marketName });
    });
    return Array.from(marketsMap.values());
}

export function getUsersByMarket(market) {
    return userNumbers.filter(user => user.market === market);
}
export function getUsersGlass(market) {
    return userNumbers
        .filter(user => user.market === market)
        .map(user => ({ nickName: user.nickName, colorGlass: user.colorGlass }));
}



export const bankOptions = [
    { value: 'sber', label: 'Сбер', secondaryLabel: 'Карта / телефон' },
    { value: 'tinkoff', label: 'Тинькофф', secondaryLabel: 'Номер карты' },
    { value: 'spb', label: 'СБП', secondaryLabel: 'Номер телефона' },
];

export const cryptoOptions = [
    { value: 'trc20', label: 'TRC-20' },
    { value: 'bep20', label: 'BEP-20' },
];

export const statusDescriptions = {
    PEND: 'Новый ордер',
    PAID: 'Оплачен',
    COMPLETE: 'Ордер завершен',
    APPEAL: 'Апелляция',
    CANCEL: 'Отменен',
    TIMEOUT: 'Осталось мало времени',
    CANCEL_SYS: 'Отменен системой'
};


export const quickRepliesBuy = [
    { label: 'Номер карты', text: 'Отправьте пожалуйста номер банковской карты Т.инь.коф, совершаю перевод исключительно в Т.инь.коф по номеру карты' },
    { label: 'СБП', text: 'К сожалению, у меня закончился лимит на отправку через СБП. Поэтому я не смогу вам совершить отправку. Буду признателен, если вы предоставите номер карты TиH"K0фф' },
    { label: 'Благодарю за сделку', text: 'Благодарю за сделку, было приятно с Вами работать. Спасибо, что выбрали меня! Добавьте меня в избранное, буду рад Вас видеть снова' }
];

export const quickRepliesSell = [
    { label: 'Дайте чек', text: 'Предоставьте пожалуйста банковский чек(квитанцию) это поможет мне проверить поступление' },
    { label: 'Нарушили условия', text: 'Вы нарушили условия сделки, у меня указано что я не принимаю оплату от третьих лиц, оплата только личной картой'},
    { label: 'скриншот РАЙФ', text: 'Это просто картинка о переводе, в ней нет ФИО отправителя, загрузите пожалуйста в чат квитанцию(чек)'},
    { label: 'скриншот ТИНЬ', text: 'Это просто картинка о переводе, в ней нет ФИО отправителя, нажмите на иконку "документы об операции" и загрузите пожалуйста в чат квитанцию(чек)'},
    { label: 'Авторизация', text: 'Попробуйте между своими счетами в приложении TиH"KоFф перевести деньги, это обычно помогает для авторизации последнего платежа, если не поможет то тогда ждем авторизацию, необходима квитанция(чек)'},
    { label: 'Фото карты', text: 'Могу предложить Вам такой вариант сделайте фото карты (с которой был перевод) на фоне нашей с вами сделки (открытого ордера либо чата), имя держателя карты должно быть видно! Остальные данные можете закрыть'},
    { label: 'Возврат от третьего лица', text: 'Предоставьте номер карты (с которой был перевод) я верну платёж, после возврата прошу отменить ордер'},
    { label: 'Номер карты', text: 'Нажмите иконку "оплатить" и Вы увидите реквизиты ФИО и номер карты'},
    { label: 'слепой и глупый', text: 'В моём объявлении прописаны все условия сделки, указан способ оплаты и прочие условия'},
    { label: 'Морось чек', text: 'Извините за задержку, но в данный момент чек еще не сформирован, идёт авторизация, подождите не много, я загружу чек'},
    { label: 'В обработке', text: 'У Вашего платежа статус "в обработке", чтобы обработка прошла быстрее обратитесь в поддержку банка для подтверждения перевода, скажите что переводите деньги знакомому (одалживаете) про покупку крипты говорить нежелательно банк может заблокировать перевод'},
    { label: '2 ордера', text: 'У Вас открыто два одинаковых ордера, оплачен только один, оплатите второй или закройте его'},
    { label: 'СБЕР', text: 'Перевод с карты SБEP, могу принять только если ФИО карты совпадает с ФИО аккаунта биржи полностью!'},
    { label: 'Потеряшка', text: 'Этот ордер уже отменён (время вышло, возможно вы не нажали "оплачено"), вам нужно открыть новый ордер на туже сумму и нажать в нём "оплачено" так же прошу вас скинуть в чат уже нового ордера чек об оплате'},

];

export const payOptions = {
    Sberbank: { label: 'Сбербанк', color: '#B4E09F' },
    Tinkoff: { label: 'Тинькофф', color: '#E9E98B' },
    14: { label: 'Тинькофф О.', color: '#E9E98B' },
    SBP: { label: 'СБП', color: '#FFD700' },
    AlfaBank: { label: 'Альфа-Банк', color: '#FA8072' },
    RaiffeisenBank: { label: 'Райффайзенбанк', color: '#ADD8E6' },
    VTBBank: { label: 'ВТБ', color: '#20B2AA' },
    QIWI: { label: 'QIWI', color: '#FFDAB9' },
    HomeCreditBank: { label: 'Хоум Кредит', color: '#FF69B4' },
    GazpromBank: { label: 'Газпромбанк', color: '#A52A2A' },
    MTSBank: { label: 'МТС Банк', color: '#87CEEB' },
    BANK: { label: 'Банк', color: '#778899' },
    PostBank: { label: 'Почта Банк', color: '#6B8E23' },
    RosBank: { label: 'Росбанк', color: '#B8860B' },
    Umoney: { label: 'ЮMoney', color: '#FF4500' },
    SovkomBank: { label: 'Совкомбанк', color: '#8B4513' },
    OtkritieBank: { label: 'Открытие', color: '#DB7093' },
    RosselkhozBank: { label: 'Россельхозбанк', color: '#4682B4' },
    UralsibBank: { label: 'Уралсиб', color: '#D2B48C' },
    BCSBank: { label: 'БКС Банк', color: '#008080' },
    Mobiletopup: { label: 'Мобильная связь', color: '#DAA520' },
    AkBarsBank: { label: 'Ак Барс', color: '#5F9EA0' },
    RenaissanceCredit: { label: 'Ренессанс Кредит', color: '#FF8C00' },
    AdvCash: { label: 'AdvCash', color: '#FF6347' },
    Payeer: { label: 'Payeer', color: '#DA70D6' },
    BankSaintPetersburg: { label: 'Банк Санкт-Петербург', color: '#4169E1' },
    PromsvyazBank: { label: 'Промсвязьбанк', color: '#2E8B57' },
    RussianStandardBank: { label: 'Русский Стандарт', color: '#C71585' },
    UniCredit: { label: 'ЮниКредит', color: '#B22222' },
    CreditEuropeBank: { label: 'Кредит Европа Банк', color: '#8A2BE2' },
    OTPBank: { label: 'ОТП Банк', color: '#A0522D' },
    VostochnyBank: { label: 'Восточный', color: '#5F9EA0' },
    CreditMoscow: { label: 'Кредит Москва', color: '#FF69B4' },
    AbsolutBank: { label: 'Абсолют', color: '#6495ED' },
    CitiBank: { label: 'Ситибанк', color: '#FF4500' },
    RaiffeisenBankAval: { label: 'Райффайзен Аваль', color: '#008B8B' },
};