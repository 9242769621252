import {API_URL, BROKER_URL} from "../configUrls";
import {postApiRequest} from "../apiParams";

export const postBrokerOrders= (body) => {
    return postApiRequest(`${BROKER_URL}/orders`, body);
};


export const postBrokerAccept = (body) => {
    return postApiRequest(`${BROKER_URL}/accept`, body);
};

export const postBrokerDone = (body) => {
    return postApiRequest(`${BROKER_URL}/done`, body);
};

export const postBrokerInfo = (body) => {
    return postApiRequest(`${BROKER_URL}/info`, body);
};


export const postBrokerOrderInfo = (body) => {
    return postApiRequest(`${BROKER_URL}/order-detail`, body);
};

export const postBrokerViewImg = (body) => {
    return postApiRequest(`${BROKER_URL}/img-view`, body);
};

export const postBrokerApplications = (body) => {
    return postApiRequest(`${BROKER_URL}/applications`, body);
};


export const postBrokerCloseAdv = (body) => {
    return postApiRequest(`${BROKER_URL}/close-adv`, body);
};


export const postBrokerCardGroup = (body) => {
    return postApiRequest(`${BROKER_URL}/card-group`, body);
};

export const postCreateApplication  = (body) => {
    return postApiRequest(`${BROKER_URL}/new-adv`, body);
};

export const uploadBrokerSession = (formData) => {
    return postApiRequest(`${BROKER_URL}/add-session`,formData, {
        'Content-Type': 'multipart/form-data'
    });
};


export const postCancelOrder  = (body) => {
    return postApiRequest(`${BROKER_URL}/cancel`, body);
};