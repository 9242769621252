import Home from './pages/Home';
import PageHistory from './pages/HistoryPage/HistoryPage';
import PricePage from './pages/PricePage/PricePage';
import {AdminPanel, UserPanel} from './pages/AdminPage/AdminPage';
import SessionPage from './pages/SessionPage/SessionPage';
import AveragePage from "./pages/AveragePage/AveragePage";
import BalancePage from "./pages/BalancePage/BalancePage";
import TradePage from "./pages/NewPageTrade/TradePage";
import PriceNoTabsPage from "./pages/PricePage/PriceNoTabsPage";
import MassGlassPage from "./pages/MassGlassPage/MassGlassPage";
import DensityGlassPage from "./pages/MassGlassPage/DensityGlassPage";
import BlockPage from "./pages/BlockPage/BlockPage";
import CrmPage from "./pages/CRM/СrmPage";
import {MercantPanel} from "./pages/AdminPage/mercnats/panelMerchsnts";
import BrokerPage from "./pages/BrokerPage/BrokerPage";
import {
    HomeOutlined,
    DollarCircleOutlined,
    FileSyncOutlined,
    ReadOutlined,
    EditOutlined,
    FundOutlined,
    ClockCircleOutlined,
    SecurityScanOutlined,
    SplitCellsOutlined,
    CalculatorOutlined,
    BarChartOutlined,
    AimOutlined,
    WarningOutlined,
    ToolOutlined, HistoryOutlined, MoneyCollectOutlined, RadarChartOutlined,DollarOutlined
} from '@ant-design/icons';
import CalculatorPage from "./pages/CalculatorPage/CalculatorPage";
import ReitPage from "./pages/ReitPage/ReitPage";
import T1Page from "./pages/T1Page/T1Page";

const routeConfig = [
    {
        path: "/home",
        component: Home,
        allowedRoles: ["ADMIN", "PRICE", "WORKER"],
        layout: 'main',
        label: 'Главная',
        requiresWebSocket: false,
        icon: <HomeOutlined />,

    },
    {
        label: 'Классика',
        icon: <SplitCellsOutlined/> ,
        children: [
            {
                path: "/pay", // Corrected nested path
                component: () => <TradePage tradeType={"BUY"} typeTabs={0} isCardless={false}/>, // Используйте функцию для возврата JSX
                allowedRoles: ["ADMIN", "PRICE", "WORKER"],
                layout: 'main',
                label: 'Оплата',
                requiresWebSocket: true,
                icon: <DollarCircleOutlined />,
            },
            {
                path: "/reception", // Corrected nested path
                component: () => <TradePage tradeType={"SELL"} typeTabs={0} isCardless={false}/>, // Используйте функцию для возврата JSX
                allowedRoles: ["ADMIN", "PRICE", "WORKER"],
                layout: 'main',
                label: 'Приемка',
                requiresWebSocket: true,
                icon: <FileSyncOutlined />,
            },

        ],
    },
    {
        path: "/price",
        component: PricePage,
        allowedRoles: ["ADMIN","PRICE"],
        layout: 'main',
        label: 'Редактор',
        requiresWebSocket: false,
        icon: <EditOutlined />,
    },
    {
        path: "/nocard",
        component: () => <TradePage tradeType={"NIGHT"} typeTabs={1} isCardless={true}/>, // Используйте функцию для возврата JSX
        allowedRoles: ["ADMIN", "PRICE", "WORKER"],
        layout: 'main',
        label: 'Бескарточный',
        requiresWebSocket: true,
        icon: <ReadOutlined/>,
    },

    {
        label: 'Стакан',
        icon: <FundOutlined />,
        children: [
            {
                path: "/mass-glass",
                component: MassGlassPage,
                allowedRoles: ["ADMIN", "PRICE", "WORKER"],
                layout: 'main',
                label: 'Общий стакан',
                requiresWebSocket: false,
                icon: <BarChartOutlined/>,
            },
            {
                path: "/density-glass",
                component: DensityGlassPage,
                allowedRoles: ["ADMIN", "PRICE", "WORKER"],
                layout: 'main',
                label: 'Плотность',
                requiresWebSocket: false,
                icon: <AimOutlined />,
            },
        ],
    },
    {
        label: 'Утилиты',
        icon: <ToolOutlined />,
        children: [
            {
                path: "/personal-info",
                component: ReitPage,
                allowedRoles: ["ADMIN", "PRICE", "WORKER"],
                layout: 'main',
                label: 'Рейтинг',
                requiresWebSocket: false,
                icon: <MoneyCollectOutlined/>,
            },
            {
                path: "/balance",
                component: BalancePage,
                allowedRoles: ["ADMIN", "PRICE", "WORKER"],
                layout: 'main',
                label: 'Кошелек',
                requiresWebSocket: false,
                icon: <MoneyCollectOutlined/>,
            },
            {
                path: "/balance-t1",
                component: T1Page,
                allowedRoles: ["ADMIN", "PRICE", "WORKER"],
                layout: 'main',
                label: 'Т+1',
                requiresWebSocket: false,
                icon: <MoneyCollectOutlined/>,
            },
            //
            // {
            //     path: "/average",
            //     component: AveragePage,
            //     allowedRoles: ["ADMIN", "PRICE", "WORKER"],
            //     layout: 'main',
            //     label: 'СреднячОК',
            //     requiresWebSocket: false,
            //     icon: <RadarChartOutlined />,
            // },
            {
                path: "/history",
                component: PageHistory,
                allowedRoles: ["ADMIN", "PRICE", "WORKER"],
                layout: 'main',
                label: 'История',
                requiresWebSocket: false,
                icon: <HistoryOutlined />,
            },
            {
                path: "/priceFull",
                component: PriceNoTabsPage,
                allowedRoles: ["ADMIN","PRICE"],
                layout: 'main',
                label: 'Редактор FULL',
                requiresWebSocket: false,
                icon: <EditOutlined />,
            }

        ],
    },
    {
        path: "/add-block",
        component: BlockPage,
        allowedRoles: ["ADMIN", "PRICE", "WORKER"],
        layout: 'main',
        label: 'Черный Список',
        requiresWebSocket: false,
        icon: <WarningOutlined />,
    },
    {
        path: "/broker-page",
        component: BrokerPage,
        allowedRoles: ["ADMIN", "PRICE", "WORKER"],
        layout: 'main',
        label: 'Брокер',
        requiresWebSocket: false,
        icon: <DollarOutlined />,
    },
    {
        path: "/calculator",
        component: CalculatorPage,
        allowedRoles: ["ADMIN", "PRICE", "WORKER"],
        layout: 'main',
        label: 'Калькулятор',
        requiresWebSocket: false,
        icon: <CalculatorOutlined/>,
    },
    {
        path: "/session",
        component: SessionPage,
        allowedRoles: ["ADMIN", "PRICE", "WORKER"],
        layout: 'main',
        label: 'Мерчанты',
        requiresWebSocket: false,
        icon: <ClockCircleOutlined />,
    },

    {
        label: 'Админка',
        icon: <SecurityScanOutlined />,
        children: [
            {
                path: "/panel-merchant",
                component: MercantPanel,
                allowedRoles: ["ADMIN"],
                layout: 'main',
                label: 'Управление мерчантами',
                requiresWebSocket: false,
                icon: <SecurityScanOutlined />,
            },
            {
                path: "/info-users",
                component: UserPanel,
                allowedRoles: ["ADMIN"],
                layout: 'main',
                label: 'Сотрудники',
                requiresWebSocket: false,
                icon: <SecurityScanOutlined />,
            },
            {
                path: "/new-user",
                component: AdminPanel,
                allowedRoles: ["ADMIN"],
                layout: 'main',
                label: 'Новый сотрудник',
                requiresWebSocket: false,
                icon: <SecurityScanOutlined />,
            },

            {
                path: "/crm",
                component: CrmPage,
                allowedRoles: ["ADMIN"],
                layout: 'main',
                label: 'CRM',
                requiresWebSocket: false,
                icon: <ClockCircleOutlined />,
            },
        ],
    },




];

export default routeConfig;
