import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { Row, Col } from 'antd';
import { tradeStore } from "../../state/TradeStore";
import Chat from './Chat';
import OrderInfo from "./OrderInfo";
import OrderFooter from "./OrderFooter";



const ConsoleMain = observer(({tradeType, userNumber, orderNumber,componentKey, setKey,sizeChat=200,done=true}) => {
    const [orderInfo, setOrderInfo] = useState({});
    const [agentUid, setAgentUid] = useState(0);
    const [loadingOrder, setLoadingOrder] = useState(true); // Изначально устанавливаем загрузку
    const [loadingAgent, setLoadingAgent] = useState(true); // Изначально устанавливаем загрузку
    useEffect(() => {
        if (!userNumber || !orderNumber) {
            return; // Выходим из useEffect, если не хватает данных для запроса
        }
        setLoadingOrder(true); // Установка состояния загрузки в true
        setLoadingAgent(true); // Установка состояния загрузки в true
        setOrderInfo({}); // Очищаем предыдущие данные перед загрузкой новых
        setAgentUid(0);
        const getOrderInfo = async () => {
            try {
                const order = await tradeStore.orderDetailInfoReturn(userNumber, orderNumber);
                setOrderInfo(order); // Устанавливаем новые данные
                setAgentUid(order.agentUserId)
                setLoadingOrder(false);
            } catch (error) {
                setLoadingOrder(true);
            }
        };

        getOrderInfo();
    }, [userNumber, orderNumber, componentKey ]);
    return (
        <Row gutter={[16, 16]} style={{ height: '98%' }}>
            <>
                <Col xs={24} lg={12}>
                    <Row gutter={[16, 16]} style={{ height: '100%' }}>
                        <Col xs={12} sm={12} md={24} lg={24} xl={24} style={{ height: 'calc(50% - 8px)' }}>
                            <OrderInfo orderInfo={orderInfo} loading={loadingOrder} setKey={setKey} done={done}/>
                        </Col>
                        <Col xs={12} sm={12} md={24} lg={24} xl={24} style={{ height: 'calc(50% - 8px)' }}>
                            <OrderFooter userNumber={userNumber} orderNumber={orderNumber} agentUid={agentUid} loadingAgent={loadingAgent} setLoadingAgent={setLoadingAgent} tradeType={tradeType}/>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} lg={12} style={{ height: '100%' }}>
                    <Chat userNumber={userNumber} orderNumber={orderNumber} tradeType={tradeType} componentKey ={componentKey} sizeChat={sizeChat} />
                </Col>
            </>
        </Row>
    );
})

export default ConsoleMain;