import {CRM_URL} from "../configUrls";
import {postApiRequest} from "../apiParams";


export const crmAgentInfo = async (body) => {
    return postApiRequest(`${CRM_URL}/note/agent/info`, body);
};


export const crmAgentAdd = async (body) => {
    return postApiRequest(`${CRM_URL}/note/agent/add`, body);
};

export const crmAgentDel = async (body) => {
    return postApiRequest(`${CRM_URL}/note/agent/del`, body);
};


export const crmOrderInfo = async (body) => {
    return postApiRequest(`${CRM_URL}/note/order/info`, body);
};


export const crmOrderAdd = async (body) => {
    return postApiRequest(`${CRM_URL}/note/order/add`, body);
};

export const crmOrderDel = async (body) => {
    return postApiRequest(`${CRM_URL}/note/order/del`, body);
};

export const crmOrdersInfo = async (body) => {
    return postApiRequest(`${CRM_URL}/note/orders/info`, body);
};