import { makeAutoObservable } from 'mobx';

class PriceStore {
    marketActive = 2;

    constructor() {
        makeAutoObservable(this);
        const savedMarketActive = localStorage.getItem('marketActive');
        if (savedMarketActive) {
            this.marketActive = parseInt(savedMarketActive);
        }
    }


    setMarketActive(market) {
        this.marketActive = market
    }


}
const priceStore = new PriceStore();

export default priceStore;

