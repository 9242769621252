import { makeAutoObservable, reaction } from "mobx";
import authStore from './AuthStore';

import {WSS_URL} from "../config/configUrls";

class WebSocketStore {
    socket = null;
    MAX_RECONNECT_ATTEMPTS = 10;
    reconnectAttempts = 0;
    observers = []; // Список наблюдателей

    isActive = false;
    constructor() {
        makeAutoObservable(this);
    }

    observeAuthChanges() {
        reaction(
            () => authStore.user,
            (user) => {
                if (user) {
                    this.connect();
                } else {
                    this.disconnect();
                }
            }
        );
    }

    setSocket(socket) {
        this.socket = socket;
    }

    setActive(status) {
        this.isActive = status;
    }
    connect = async () => {
        console.log("Connecting to WebSocket called from:", new Error().stack);

        if (this.socket && (this.socket.readyState === WebSocket.OPEN || this.socket.readyState === WebSocket.CONNECTING)) {
            console.info('WebSocket соединение уже установлено или устанавливается.');
            return;
        }

        try {
            const socketUrl = `${WSS_URL}?team=${localStorage.getItem('team')}`;
            this.socket = new WebSocket(socketUrl);

            this.socket.onopen = () => {
                console.log(`Current WebSocket state: ${this.socket.readyState}`);

                console.info('WebSocket соединение установлено.');
                this.reconnectAttempts = 0;
            };

            this.socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                this.notifyObservers(data);
            };

            this.socket.onclose = (event) => {
                console.info(`WebSocket закрыт с кодом: ${event.code}, причина: ${event.reason}`);
                this.attemptReconnect();
            };

            this.socket.onerror = (error) => {
                console.error('Ошибка WebSocket соединения: ', error);
                this.attemptReconnect();
            };
            this.setActive(true);
        } catch (error) {
            console.error('Ошибка при подключении к WebSocket: ', error);
            this.attemptReconnect();
        }
    }

    attemptReconnect = () => {
        if (this.isActive && this.reconnectAttempts < this.MAX_RECONNECT_ATTEMPTS) {
            setTimeout(this.connect, 3000);
            this.reconnectAttempts++;
        } else {
            console.error('Достигнуто максимальное количество попыток переподключения');
        }
    }

    disconnect = () => {
        if (this.socket) {
            this.socket.close();
            this.setSocket(null);
        }
        this.setActive(false);
    }

    // listenAndSendToTradeStore() {
    //     if (this.socket) {
    //         this.socket.onmessage = (event) => {
    //             const data = JSON.parse(event.data);
    //             tradeStore.handleWebSocketMessage(data); // Передаем данные в TradeStore для обработки
    //         };
    //     }
    // }

    onMessage(callback) {
        if (this.socket) {
            this.socket.onmessage = callback;
        }
    }
    offMessage(callback) {
        if (this.socket && this.socket.onmessage === callback) {
            this.socket.onmessage = null;
        }
    }
    addObserver(observer, observerId) {
        this.observers.push({ observer, observerId });
    }

    removeObserver(observerId) {
        this.observers = this.observers.filter(obs =>
            !(obs.observerId === observerId)
        );
    }

    notifyObservers(data) {
        this.observers.forEach(({ observer }) => {
            observer(data);
        });
    }
}

export default new WebSocketStore();

