import React, { useEffect, useState } from 'react';
import { Card, Skeleton, Tabs, List, Pagination, Badge, Col, Row, Modal, Button,Drawer } from 'antd';
import { addToast,addToastWar } from "../Notification/Toast";
import { fetchAgentInfo, fetchAgentReviews } from "../../config/api/apiGeneral";
import { formatLastSeen } from "../dateFunc";
import { formatNumber } from "../numericFunc";

const { TabPane } = Tabs;

const AgentInfoCards = ({ agentInfo }) => {
    const data = [
        { title: 'Ордеров за 30 д.', value: formatNumber(agentInfo.recentFinishCount, false), key: '1' },
        { title: 'Созд аккаунт.', value: formatNumber(agentInfo.accountCreateDays, true, "д."), key: '9' },
        {
            title: 'Всего',
            value: (
                <>
                    <span>{formatNumber(agentInfo.totalFinishCount, false)} </span> (
                    <span style={{ color: 'green' }}>{formatNumber(agentInfo.totalFinishBuyCount, false)}</span>/
                    <span style={{ color: 'red' }}>{formatNumber(agentInfo.totalFinishSellCount, false)}</span>)
                </>
            ),
            key: '2'
        },
        { title: 'Эра Р2Р.', value: formatNumber(agentInfo.firstTradeDays, true, "д."), key: '10' },
        {
            title: 'Время сделки',
            value: (
                <>
                    <span style={{ color: 'green' }}>{formatNumber(agentInfo.averageTransferTime, true, "м.")}</span> /
                    <span style={{ color: 'red' }}> {formatNumber(agentInfo.averageReleaseTime, true, "м.")}</span>
                </>
            ),
            key: '7'
        },
        {
            title: 'Активность',
            value: agentInfo.isOnline ? 'Онлайн' : `${formatLastSeen(agentInfo.lastLogoutTime)}`,
            key: '11'
        }
    ];

    return (
        <Row gutter={[8, 8]}>
            {data.map(item => (
                <Col key={item.key} xs={24} sm={12} md={12}>
                    <Card
                        size="small"
                        title={item.title}
                        bordered={false}
                        style={{
                            header: { background: item.color ? item.color : 'none', color: 'black' },
                            body: { fontSize: 14, fontWeight: 'bold' }
                        }}
                    >
                        {item.value}
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

const OrderFooter = ({ userNumber, orderNumber, agentUid, loadingAgent, setLoadingAgent,tradeType }) => {
    const [activeKey, setActiveKey] = useState("1");
    const [agentInfo, setAgentInfo] = useState({});
    const [reviews, setReviews] = useState([]);
    const [loadingReviews, setLoadingReviews] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalReviews, setTotalReviews] = useState(0);

    useEffect(() => {
        if (!userNumber || !orderNumber) {
            return; // Выходим из useEffect, если не хватает данных для запроса
        }
        const sendInfo = async () => {
            if (agentUid === 0) {
                setAgentInfo({});
                return;
            }
            try {
                setActiveKey("1");
                setLoadingAgent(true);
                const response = await fetchAgentInfo(userNumber, orderNumber, agentUid);
                const data = response.data;
                setAgentInfo(data);

                if (tradeType === 'SELL' && Array.isArray(data.warning) && data.warning.length > 0) {
                    await addToastWar(data.warning)
                }
            } catch (error) {
                await addToast('error', 'Ошибка при получении информации о пользователе', `${error}.`);
            } finally {
                setLoadingAgent(false);
            }
        };

        sendInfo();
    }, [agentUid]);

    const handleTabChange = (key) => {
        setActiveKey(key);
        if (key === "2" || key === "3") {
            fetchReviews(key === "2" ? 'good' : 'bad');
        }
    };

    const fetchReviews = async (type, page = 1) => {
        setLoadingReviews(true);
        try {
            const response = await fetchAgentReviews(userNumber, agentUid, type, page);
            setReviews(response.data.info);
            setTotalReviews(response.data.count);
            setCurrentPage(page);
        } catch (error) {
            console.error('Error fetching reviews:', error);
        } finally {
            setLoadingReviews(false);
        }
    };

    const tabItems = [
        {
            label: (
                <>
                    <div style={{ display: 'inline-block', marginRight: 8 }}>Рейтинг</div>
                    <Badge count={agentInfo.recentRate || 0} showZero style={{ backgroundColor: '#52c41a' }} overflowCount={100}/>
                </>
            ),
            key: '1',
            children: agentInfo.nickName ? (
                <AgentInfoCards agentInfo={agentInfo} />
            ) : (
                <Skeleton active />
            )
        },
        {
            label: (
                <>
                    <div style={{ display: 'inline-block', marginRight: 8 }}>Лайк</div>
                    <Badge count={agentInfo.goodAppraiseCount || 0} showZero style={{ backgroundColor: '#52c41a' }} overflowCount={999}/>
                </>
            ),
            key: '2',
            children: loadingReviews ? (
                <Skeleton active />
            ) : (
                <>
                    <div style={{ flexGrow: 1, overflowY: 'auto', maxHeight: '250px' }}>
                        <List
                            itemLayout="horizontal"
                            dataSource={reviews}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={`${item.nickName}${item.merchant ? ' (мерч)' : ''}`}
                                        description={
                                            <>
                                                <div>{item.message}</div>
                                                <div>{new Date(item.timeStamp).toLocaleString()}</div>
                                            </>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '4px' }}>
                        <Pagination
                            current={currentPage}
                            pageSize={10}
                            total={totalReviews}
                            onChange={(page) => fetchReviews('good', page)}
                            showSizeChanger={false}  // Отключение выбора размера страницы
                            showLessItems={true}  // Уменьшает количество видимых кнопок

                        />
                    </div>
                </>
            )
        },
        {
            label: (
                <>
                    <div style={{ display: 'inline-block', marginRight: 8 }}>Дизлайк</div>
                    <Badge count={agentInfo.badAppraiseCount || 0} showZero style={{ backgroundColor: '#ff4d4f' }} overflowCount={999}/>
                </>
            ),
            key: '3',
            children: loadingReviews ? (
                <Skeleton active />
            ) : (
                <>
            <div style={{ flexGrow: 1, overflowY: 'auto', maxHeight: '250px' }}>
                <List
                    itemLayout="horizontal"
                    dataSource={reviews}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={`${item.nickName}${item.merchant ? ' (мерч)' : ''}`}
                                description={
                                    <>
                                        <div>{item.message}</div>
                                        <div>{new Date(item.timeStamp).toLocaleString()}</div>
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                />
            </div>
            <div style={{ textAlign: 'center', marginTop: '4px' }}>
                <Pagination
                    current={currentPage}
                    pageSize={10}
                    total={totalReviews}
                    onChange={(page) => fetchReviews('bad', page)}
                    showSizeChanger={false}  // Отключение выбора размера страницы
                    showLessItems={true}  // Уменьшает количество видимых кнопок

                />
            </div>
        </>

            )
        }
    ];

    return (
        <>
            <Card bordered={false} size="small" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Tabs defaultActiveKey="1" onChange={handleTabChange} activeKey={activeKey} items={tabItems} />
            </Card>
        </>
    );
};

export default OrderFooter;