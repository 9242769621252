import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { OneColCenter } from "../../Layouts/formRow/2col";
import MerchStore from "../../state/MerchStore";
import { PersonalInfo } from "../../config/api/apiGeneral";

const fetchPersonalInfo = async (userNumber) => {
    try {
        const data = await PersonalInfo(userNumber);
        return data;
    } catch (error) {
        return { error: true, message: error.message };
    }
};

const ReitPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedMarket, setSelectedMarket] = useState(null);

    const marketFilter = MerchStore.getAllMarketsValue();
    const merchNumbers = MerchStore.userNumbers;

    useEffect(() => {
        const initialData = merchNumbers.map(user => ({
            marketName: user.marketName,
            nickName: user.nickName,
            userNumber: user.userNumber,
            goodAppraiseRate: '',
            recentRate: '',
            recentFinishCount: '',
            userType: '',
            vipLevel: '',
            paymentRealName: '',
            averageReleaseTime: '',
            averageTransferTime: ''
        }));

        setData(initialData);

        const fetchData = async () => {
            setLoading(true);

            const promises = initialData.map(async (user) => {
                const info = await fetchPersonalInfo(user.userNumber);
                if (info.error) {
                    return {
                        ...user,
                        goodAppraiseRate: '',
                        recentRate: '',
                        recentFinishCount: '',
                        userType: '',
                        vipLevel: '',
                        paymentRealName: '',
                        averageReleaseTime: '',
                        averageTransferTime: ''
                    };
                }
                const data = info.data;
                return {
                    ...user,
                    goodAppraiseRate: data.goodAppraiseRate || '',
                    recentRate: data.recentRate || '',
                    recentFinishCount: data.recentFinishCount || '',
                    userType: data.authStatus === 1 ? 'мерчант' : 'кепка',
                    vipLevel: data.vipLevel !== undefined ? data.vipLevel.toString() : '',
                    paymentRealName: data.paymentRealNameUneditable ? 'Да' : 'Нет',
                    averageReleaseTime: data.averageReleaseTime || '',
                    averageTransferTime: data.averageTransferTime || ''
                };
            });

            const results = await Promise.all(promises);
            setData(results);
            setLoading(false);
        };

        fetchData();
    }, [merchNumbers]);

    const getCellStyle = (condition) => ({
        backgroundColor: condition ? '#ffcccc' : 'transparent'
    });

    const columns = [
        {
            title: 'Биржа',
            dataIndex: 'marketName',
            key: 'marketName',
        },
        {
            title: 'Пользователь',
            fixed: 'left',
            dataIndex: 'nickName',
            key: 'nickName',
            render: (text, record) => `${record.nickName}`

        },
        {
            title: 'Тип',
            dataIndex: 'userType',
            key: 'userType',
        },
        {
            title: 'Ордеров 30д',
            dataIndex: 'recentFinishCount',
            width: "10%",
            key: 'recentFinishCount',
            render: (text, record) => (
                <div style={getCellStyle(record.userType === 'мерчант' && record.recentFinishCount < 600)}>
                    {record.recentFinishCount}
                </div>
            )
        },
        {
            title: '% Исполнения',
            dataIndex: 'recentRate',
            width: "10%",

            key: 'recentRate',
            render: (text, record) => (
                <div style={getCellStyle(record.userType === 'мерчант' && record.recentRate < 95)}>
                    {record.recentRate} %
                </div>
            )
        },

        {
            title: 'Отзывы',
            dataIndex: 'goodAppraiseRate',
            width: "10%",

            key: 'goodAppraiseRate',
            render: (text, record) => (
                <div style={getCellStyle(record.userType === 'мерчант' && record.goodAppraiseRate < 95)}>
                    {record.goodAppraiseRate} %
                </div>
            )
        },

        {
            title: 'Время выпуска',
            dataIndex: 'averageReleaseTime',
            width: "10%",

            key: 'averageReleaseTime',
            render: (text, record) => (
                <div style={getCellStyle(record.userType === 'мерчант' && record.averageReleaseTime > 5)}>
                    {record.averageReleaseTime}
                </div>
            )
        },
        {
            title: 'Время оплаты',
            dataIndex: 'averageTransferTime',
            width: "10%",

            key: 'averageTransferTime',
            render: (text, record) => (
                <div style={getCellStyle(record.userType === 'мерчант' && record.averageTransferTime > 5)}>
                    {record.averageTransferTime}
                </div>
            )
        },        {

            title: 'ВИП',
            width: "10%",
            dataIndex: 'vipLevel',
            key: 'vipLevel',
        },
    ];

    const handleTableChange = (pagination, filters) => {
        if (filters.marketName && filters.marketName.length > 0) {
            setSelectedMarket(filters.marketName[0]);
        } else {
            setSelectedMarket(null);
        }
    };

    return (
        <OneColCenter
            col1={() => (
                <>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="userNumber"
                        onChange={handleTableChange}
                        scroll={{ x: 'max-content' }}
                        loading={loading}
                    />
                </>
            )}
        />
    );
};

export default ReitPage;
