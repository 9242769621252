import React, { useState } from 'react';
import { Table, Tag , Modal, Badge } from 'antd';
import {ChatButton, MarketIcon} from "../../../components/CommonComponents";
import ConsoleMain from "../../../components/OrderDetail/ConsoleMain";
const  OrderTable =({ tableData, currentPage, handlePageClick, itemsPerPage, totalOrders,setItemsPerPage, loadingTable })  => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeInfo, setActiveInfo] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);

    const toggleModal = (item) => {
        setActiveInfo(item);
        setIsModalOpen(!isModalOpen);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const getStatusBadge = (status) => {
        switch (status) {
            case 'COMPLETE':
                return <Badge status="success" text="Завершено" />;
            case 'PEND':
                return <Badge status="warning" text="Ожидание оплаты" />;
            case 'PAID':
                return <Badge status="warning" text="Ожидание актива" />;
            case 'CANCEL':
                return <Badge status="error" text="Отменен" />;
            case 'CANCEL_SYS':
                return <Badge status="error" text="Отменен с." />;
            default:
                return <Badge status="default" text={status} />;
        }
    };
    const columns = [
        {
            title: 'Дата',
            width: '15%',
            fixed: 'left',
            dataIndex: 'tsCreate',
            key: 'tsCreate',
            render: tsCreate => new Date(tsCreate).toLocaleString(),
        },
        {
            title: 'Ордер',
            width: '10%',
            dataIndex: 'tradeType',
            key: 'orderNumber',
            render: (tradeType, record) => (
                <Tag  color={tradeType === 'BUY' ? '#87d068' : '#f50'}>
                    {record.orderNumber}
                </Tag>
            ),
        },
        {
            title: 'Статус',
            width: '15%',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            render: getStatusBadge,
        },
        {
            title: 'Маркет',
            width: '5%',
            dataIndex: 'market',
            key: 'market',
            render: market => <MarketIcon market={market} />,
        },
        {
            title: 'Никнейм',
            width: '10%',
            dataIndex: 'userNickName',
            key: 'userNickName',
        },
        {
            title: 'Сумма',
            width: '10%',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            render: totalPrice => Number(totalPrice).toLocaleString('ru-RU') + ' ₽',
        },
        {
            title: 'Курс',
            width: '10%',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Объем',
            width: '10%',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount, record) => `${amount} ${record.asset}`,
        },
        {
            title: 'Чат',
            key: 'chat',
            render: (text, record) => (

            <ChatButton
                msgCount={record.msgCount}
                onClick={() => {
                    toggleModal(record)
                }}
        />
            ),
        },
    ];
    const paginationConfig = {
        current: currentPage,
        total: totalOrders,
        pageSize: itemsPerPage,
        onChange: (page, pageSize) => {
            handlePageClick(page);
            setItemsPerPage(pageSize);
        },
        showSizeChanger: true,
        showQuickJumper: true, // Добавляем функциональность Quick Jumper

    };
    return (
        <>
            <Table
                dataSource={tableData}
                loading={loadingTable}
                columns={columns}
                pagination={paginationConfig}
                scroll={{ x: 'max-content', y: '68vh' }}
                sticky
            />

            {activeInfo && (
                <Modal
                    open={isModalOpen}
                    onCancel={closeModal}
                    footer={null}
                    width={800}
                    style={{ alignItems: 'center', justifyContent: 'center', top: 8 }}
                >
                    {activeInfo && (
                        <ConsoleMain
                            tradeType={activeInfo.tradeType}
                            userNumber={activeInfo.userNumber}
                            orderNumber={activeInfo.orderNumber}
                            key={refreshKey}
                            setKey={setRefreshKey}
                            sizeChat={250}
                        />
                    )}
                </Modal>
            )}
        </>
    );
}

export default OrderTable;
