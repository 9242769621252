import {auth} from "./google-fire/firebase-config";
import axios from "axios";

let cachedToken = null;
let tokenExpirationTime = null;

const getUserToken = async () => {
    const currentTime = new Date().getTime();

    // Проверяем, действителен ли кэшированный токен и не истек ли его срок
    if (cachedToken && tokenExpirationTime > currentTime) {
        return cachedToken;
    }

    // Получаем новый токен
    const userToken = await auth.currentUser?.getIdToken(true);
    if (!userToken) {
        throw new Error('Пользователь не аутентифицирован');
    }

    // Обновляем кэшированный токен и время его истечения
    cachedToken = userToken;
    // Вы можете настроить время истечения токена в зависимости от вашего бэкенда
    tokenExpirationTime = currentTime + (720 * 60 * 1000); // Например, 1 час

    return userToken;
};

export const postApiRequest = async (my_url, body, additionalHeaders = {}) => {
    try {
        const userToken = await getUserToken();
        if (!userToken) {
            throw new Error('Пользователь не аутентифицирован');
        }
        const response = await axios.post(my_url, body, {
            headers: {
                'Authorization': `Bearer ${userToken}`,
                ...additionalHeaders
            }
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при отправке запроса:', error);
        if (error.response && error.response.data) {
            // Безопасно проверяем наличие свойства 'msg'
            const errorMessage = error.response.data.msg || 'Произошла ошибка при выполнении запроса';
            throw new Error(errorMessage);
        } else {
            // Если нет сообщения об ошибке от сервера, выбрасываем общее сообщение
            throw new Error('Произошла ошибка при выполнении запроса');
        }
    }
};

export const getApiRequest = async (my_url, additionalHeaders = {}) => {
    try {
        const userToken = await getUserToken();
        if (!userToken) {
            throw new Error('Пользователь не аутентифицирован');
        }
        const response = await axios.get(my_url, {
            headers: {
                'Authorization': `Bearer ${userToken}`,
                ...additionalHeaders
            }
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при отправке запроса:', error);
        if (error.response && error.response.data) {
            // Безопасно проверяем наличие свойства 'msg'
            const errorMessage = error.response.data.msg || 'Произошла ошибка при выполнении запроса';
            throw new Error(errorMessage);
        } else {
            // Если нет сообщения об ошибке от сервера, выбрасываем общее сообщение
            throw new Error('Произошла ошибка при выполнении запроса');
        }
    }
};

export const getUserIp = async () => {
    try {
        const response = await axios.get('https://httpbin.org/ip');
        return response.data.origin; // IP-адрес пользователя
    } catch (error) {
        console.error('Ошибка при получении IP-адреса:', error);
        return "0.0.0.0"; // Возвращаем заполнитель в случае ошибки
    }
};