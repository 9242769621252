import React, { } from 'react';
import {Row, Col} from 'antd'
import BrokerOrders from "./BrokerOrders";
import BrockerGeneral from "./BrokerGeneral";
const Broker = ({size=12}) => {
    return (

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={size}>
                    <BrokerOrders/>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={size}>
                    <BrockerGeneral/>
                </Col>
            </Row>

    );
};

export default Broker;
