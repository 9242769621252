import React, { useState, useEffect } from 'react';
import { Skeleton, Card, Row, Col } from 'antd';
import { postBrokerInfo } from "../../config/crm/apiBroker";
import { formatNumber } from "../../components/numericFunc";
import {addToast} from "../../components/Notification/Toast";

const cardInfoList = [
    { key: 'username', title: 'Аккаунт', formatter: value => value },
    { key: 'conversion', title: 'Конверсия', formatter: value => `${value} %` },
    { key: 'dailyTurnover', title: 'Оборот', formatter: formatNumber },
    { key: 'course', title: 'Курс', formatter: formatNumber },
    { key: 'balance', title: 'Баланс', formatter: formatNumber },
    { key: 'usdt', title: 'USDT по Балансу', formatter: (balance, course) => formatNumber(balance / course, true, "$") }
];

const InfoCard = ({ title, value, loading, size }) => {
    return (
        <Card size={size} title={title}>
            {loading ? (
                <Skeleton.Input active={true} size={'small'} />
            ) : (
                <span>{value}</span>
            )}
        </Card>
    );
};

const BrokerInfo = ({ team }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        username: '',
        conversion: null,
        dailyTurnover: null,
        course: null,
        balance: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            const body = { team };
            try {
                setLoading(true);
                const response = await postBrokerInfo(body);
                if (response.code === 1000) {
                    setData({
                        username: response.data.username,
                        course: response.data.course_data.course,
                        balance: response.data.balance,
                        conversion: response.data.invoice_conversion !== null ? response.data.invoice_conversion : 100,
                        dailyTurnover: response.data.daily_statistic.daily_invoice_turnover
                    });
                } else
                    await addToast('warning', 'Ошибка', 'Получение информации');
            } catch (error) {
                await addToast('warning', 'Внимание', `${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [team]);

    return (
        <Row gutter={[16,16]}>
            {cardInfoList.map(cardInfo => (
                <Col span={8} key={cardInfo.key}>
                    <InfoCard
                        title={cardInfo.title}
                        value={cardInfo.key === 'usdt' ? cardInfo.formatter(data.balance, data.course) : cardInfo.formatter(data[cardInfo.key])}
                        loading={loading}
                        size="small"
                    />
                </Col>
            ))}
        </Row>
    );
};

export default BrokerInfo;
