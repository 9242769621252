import React, { useState } from 'react';
import {  Row, Col,Tabs } from 'antd';
import '../ControlPrice.css'
import AddBots from "./addBots"
import AddGoogle from "./addGoogle";
import BalanceComp from "./addBalance";
import AddCalc from "./addCalc";
import {TOTPTimer} from "./addGoogle";
import BrokerOrders from "../../../BrokerPage/BrokerOrders";
import BrokerGeneral from "../../../BrokerPage/BrokerGeneral";



function PageTabsPrice({marketActive, setMarketActive}) {
    const [reloadKey, setReloadKey] = useState(0); // Добавьте состояние для ключа перерендера

    const handleTabClick = (key) => {
        setMarketActive(key);
        setReloadKey(prev => prev + 1); // Увеличиваем ключ для перерендера

        return
    };
    const tabContentHeight = 'calc(100vh - 90px)'; // 50px - предполагаемая высота заголовка вкладок

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Tabs
                    tabPosition='top'
                    type={'line'}
                    centered={true}
                    tabBarExtraContent={{ right: <><TOTPTimer /></> }} // Добавление текста "Привет" справа

                    onTabClick={handleTabClick}
                    items={[
                        {
                            label: '2FA',
                            key: 'google',
                            children: <div key={reloadKey}><AddGoogle /></div>  // Используйте ключ для перерендера

                        },


                    {
                        label: 'Баланс',
                        key: 'balancePrice',
                        children:   <div key={reloadKey} style={{ maxHeight: tabContentHeight, overflowY: 'auto', overflowX: 'hidden' }}>

                        <BalanceComp size={12} />
                        </div>
                    },     {
                            label: 'Ботовод',
                            key: 'activBots',
                            children:
                                <AddBots />
                        } ,
                        {
                            label: 'Калькулятор',
                            key: 'calc',
                            children:
                            <>
                                <AddCalc/>
                            </>

                        },
                        {
                            label: 'Брокер ',
                            key: 'broker',
                            children:
                                <>
                                    <BrokerOrders/>
                                </>

                        },
                        {
                            label: 'Брокер Управление',
                            key: 'brokerGeneral',
                            children:
                                <>
                                    <BrokerGeneral />
                                </>

                        }
                ]}
                    />
            </Col>

        </Row>
    );
}

export default PageTabsPrice;
