import React, { useState, useEffect } from 'react';
import { Input, Button, Card, Form,message } from 'antd';
import {fetchAddBlock} from "../../config/api/apiGeneral";
import {OneColCenter} from "../../Layouts/formRow/2col";
const BlockComponent = () => {
    const [url, setUrl] = useState('');
    const [userId, setUserId] = useState('');
    const [exchangeName, setExchangeName] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        parseUrl(url); // Анализируем URL при каждом его изменении
    }, [url]);

    const parseUrl = (url) => {
        if (!url) {
            setUserId('');
            setExchangeName('');
            setIsButtonDisabled(true);
            return;
        }

        try {
            const urlObj = new URL(url);
            const host = urlObj.hostname;
            let localExchangeName = '';
            let localUserId = '';

            if (host.includes('www.bybit.com')) {
                localExchangeName = "ByBit";
                const pathSegments = urlObj.pathname.split('/');
                const profileIndex = pathSegments.findIndex(segment => segment === 'profile');
                if (profileIndex !== -1 && pathSegments.length > profileIndex + 1) {
                    localUserId = pathSegments[profileIndex + 1];
                }
            } else if (host.includes('www.commex.com')) {
                localExchangeName = "Commex";
                const urlParams = new URLSearchParams(urlObj.search);
                if (urlParams.has('advertiserNo')) {
                    localUserId = urlParams.get('advertiserNo');
                }
            }

            setUserId(localUserId);
            setExchangeName(localExchangeName);
            setIsButtonDisabled(!(localExchangeName && (localExchangeName !== "ByBit" || localUserId)));
        } catch (error) {
            console.error("Ошибка при анализе URL:", error);
            setIsButtonDisabled(true);
        }
    };

    const handleSubmit = async () => {
        let body = {
            marketNumber: exchangeName === "ByBit" ? "2" : exchangeName === "Commex" ? "4" : undefined,
        };

        if (userId) {
            body.userId = userId;
        }

        if (body.marketNumber) {
            try {
                const result = await fetchAddBlock(body); // Предполагаем, что эта функция определена где-то в другом месте
                // Проверка результата
                if (result.code === 1000) {
                    console.log('Успех');
                    message.success('Операция выполнена успешно!');
                } else {
                    console.log('Ошибка: ', result.msg);
                    message.error(`Ошибка: ${result.msg}`);
                }
            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error);
                message.error('Ошибка при выполнении запроса');
            }
        }
    };

    return (

        <OneColCenter
            col1={() => (
                <Card title="Введите URL">
                    <Form layout="vertical" onFinish={handleSubmit}>
                        <Form.Item>
                            <Input
                                placeholder="https://example.com"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Биржа">
                            <Input placeholder="Биржа" value={exchangeName} disabled />
                        </Form.Item>
                        <Form.Item label="USER ID">
                            <Input placeholder="Контрагент" value={userId} disabled />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={isButtonDisabled}>Отправить</Button>
                        </Form.Item>
                    </Form>
                </Card>
            )}
        />
    );
};

export default BlockComponent;
