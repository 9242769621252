import axios from "axios";
import {MASS_GLASS_URL, URLS_GLASS} from "../configUrls";

export const fetchGlassAll = async (marketNumber) => {
    const URL = URLS_GLASS[marketNumber] + "/all"; // Обратите внимание на использование [] вместо ()
    const response = await axios.get(URL);
    return response.data;
};

export const fetchGlassMass = async (preparedFilters) => {
    const URL = MASS_GLASS_URL + "/search-all-asset"
    const response = await axios.post(URL, preparedFilters);
    return response.data;
};


export const fetchGlassDensity = async (preparedFilters) => {
    const URL = MASS_GLASS_URL + "/search-density"; // Обратите внимание на использование [] вместо ()
    const response = await axios.post(URL, preparedFilters);
    return response.data;
};


export const fetchTwoMass = async (preparedFilters) => {
    const URL = MASS_GLASS_URL + "/search-market-ways"; // Обратите внимание на использование [] вместо ()
    const response = await axios.post(URL, preparedFilters);
    return response.data;
};