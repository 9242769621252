import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';


const firebaseConfig = {
    apiKey: "AIzaSyCyhLhN1chnZIdz9vyHgFVw4O-crO5iYQU",
    authDomain: "appconsole-db518.firebaseapp.com",
    databaseURL: 'https://appconsole-db518-default-rtdb.europe-west1.firebasedatabase.app/',
    projectId: "appconsole-db518",
    storageBucket: "ppconsole-db518.appspot.com",
    messagingSenderId: "266959355065",
    appId: "1:266959355065:web:8f495f5033ab84e6f52f68",
    measurementId: "G-L9R3E3H625"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

export { auth, database };