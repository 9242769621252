import { runInAction, makeAutoObservable,reaction  } from 'mobx';
import {fetchTwoMass} from "../config/api/apiGlass";
import piceStore from "./PriceStore";
import merchStore from "./MerchStore";

class GlassPriceStore {
    bank = "Tinkoff";
    asset = "USDT";
    buyData = null;
    sellData = null;
    minAmountFilter = 0;
    merchChecked = false;
    isOnline = false;
    isTriangle = false;
    glassUsers = [];
    previousBuyData  = [];
    previousSellData   = [];
    constructor() {
        makeAutoObservable(this);
        this.updateGlassUsers(2)
        reaction(
            () => piceStore.marketActive,
            (marketActive) => {
                if (marketActive !== null) {
                    // Очистка данных перед загрузкой новых
                    this.previousBuyData = [];
                    this.previousSellData = [];
                    this.updateGlassUsers(marketActive);
                }

            }
        );
    }
    updateGlassUsers(marketActive) {
        const glass = merchStore.getUsersGlass(parseInt(marketActive));
        this.setGlassUsers(glass);
    }
    async fetchData() {
        if (piceStore.marketActive === null) {
            return;
        }

        try {
            const body = {
                marketNumber: parseInt(piceStore.marketActive),
                asset: this.asset,
                amount: this.minAmountFilter,
                pay: [this.bank],
                isMerchant:  null,
            };

            const data = await fetchTwoMass(body);
            if (data) {
                runInAction(() => {
                    this.previousBuyData = this.buyData;
                    this.previousSellData = this.sellData;
                    this.buyData = data['data']['BUY'];
                    this.sellData = data['data']['SELL'];
                });

            }
        } catch (error) {
            console.error("Ошибка при загрузке данных:", error);
        }
    }

    toggleMerchChecked() {
        this.merchChecked = !this.merchChecked;
    }

    toggleIsOnline() {
        this.isOnline = !this.isOnline;
    }

    toggleIsTriangle() {
        this.isTriangle = !this.isTriangle;
    }
    handleSelectAsset(value) {
        this.asset = value;
    }

    handleSelectBank(value) {
        this.bank = value;
    }
    setGlassUsers(array) {
        this.glassUsers = array;
    }
    setMinAmountFilter(value) {
        this.minAmountFilter = value;
    }

    formatNumber(number) {
        const numberString = number.toString();
        const parts = numberString.split('.');
        const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        const formattedNumber = parts.length === 2 ? `${formattedInteger}.${parts[1]}` : formattedInteger;
        return formattedNumber;
    }


    compareData(currentData, previousData) {
        const prevDataMap = new Map(previousData.map(item => [item.adv, item]));

        const changes = currentData.map(item => {
            const prevItem = prevDataMap.get(item.adv);

            let changeType = 'none';
            if (!prevItem) {
                changeType = 'new'; // Новый элемент
            } else {
                const priceIncreased = parseFloat(item.price) < parseFloat(prevItem.price);
                const priceDecreased = parseFloat(item.price) > parseFloat(prevItem.price);

                if (item.tradeType === 'BUY') {
                    changeType = priceDecreased ? 'down' : (priceIncreased ? 'up' : 'none');
                } else {
                    changeType = priceIncreased ? 'down' : (priceDecreased ? 'up' : 'none');
                }

                if (parseFloat(item.surplus) !== parseFloat(prevItem.surplus)) {
                    changeType = 'surplusChanged'; // Surplus изменился
                }
            }

            return { ...item, change: changeType };
        });

        return changes;
    }

    get formattedSellData() {
        const currentData = this.formatData(this.sellData);
        const previousData = this.formatData(this.previousSellData);
        return this.compareData(currentData, previousData);
    }

    get formattedBuyData() {
        const currentData = this.formatData(this.buyData);
        const previousData = this.formatData(this.previousBuyData);
        return this.compareData(currentData, previousData);
    }
    formatData(data) {
        if (!data || !Array.isArray(data)) {
            return [];
        }

        // Filter out items based on isOnline and triangle condition upfront
        const filteredData = data.filter((item) => {
            const isOnlineStatus = !this.isOnline || item.online === true;
            const triangle = item.maxAmount - item.minAmount <= 2;
            const merchChecked = !this.merchChecked || item.isMerchant === true;

            // Exclude items where triangle condition is true and this.isTriangle is true
            return isOnlineStatus && !(triangle && this.isTriangle) && merchChecked;
        });

        return filteredData.map((item) => {
            // Convert string representations of numbers to numbers and format them
            const priceFormatted = Number(item.price).toFixed(2);
            const minAmountFormatted = this.formatNumber(item.minAmount);
            const maxAmountFormatted = this.formatNumber(item.maxAmount);
            const surplusFormatted = this.formatNumber(item.surplus);

            // Determine the backgroundColor based on the user's nickName
            const backgroundColor = this.glassUsers.find(user => user.nickName === item.nickName)?.colorGlass || 'defaultColor';

            return {
                ...item, // Copy all existing properties of item
                price: priceFormatted,
                minAmount: minAmountFormatted,
                maxAmount: maxAmountFormatted,
                surplus: surplusFormatted,
                backgroundColor: backgroundColor,
                triangle: item.maxAmount - item.minAmount <= 2, // Add the triangle condition result
            };
        });
    }
}

const glassPriceStore = new GlassPriceStore();
export default glassPriceStore;

