import { makeAutoObservable } from "mobx";
import {fetchMerchAllInfo} from "../config/crm/apiMerch";

class MerchStore {
    dataMerchants = [];
    loadingData = true
    userNumbers = []
    markets = []
    constructor() {
        makeAutoObservable(this);
        const savedData = localStorage.getItem('dataMerchants');
        if (savedData) {
            this.dataMerchants = JSON.parse(savedData);
            this.loadingData = false
            this.updateListMerchants();
            this.markets = this.getAllMarkets();
        }
        // Если данные в localStorage отсутствуют или устарели, загрузить их заново
        if (!this.dataMerchants.length) {
            this.getDataMerch();
        }
    }

    getDataMerch = async ()  =>{
        try {
            const team = localStorage.getItem('team');

            const body = {
                "team": team
            }
            const response = await fetchMerchAllInfo(body);
            if (response.code === 1000) {
                this.dataMerchants = response.data; // Обновление массива, а не вызов функции
                localStorage.setItem('dataMerchants', JSON.stringify(response.data));

                this.updateListMerchants(); // Обновление listMerchants и localStorage
            }
            this.loadingData = false
        } catch (error) {
            this.loadingData = true
        }
    }

    updateListMerchants() {
        this.userNumbers = this.dataMerchants
            .flatMap(merchant =>
                merchant.markets.map(market => ({
                    ...market,
                    colorGlass: merchant.colorGlass
                }))
            )
            .filter(market => market.workStatus === true);

        localStorage.setItem('userNumbers', JSON.stringify(this.userNumbers));
    }
    getAllMarkets() {
        const marketsMap = new Map();
        this.userNumbers.forEach(user => {
            marketsMap.set(user.marketNumber, { marketNumber: user.marketNumber, marketName: user.marketName });
        });
        return Array.from(marketsMap.values());
    }

    getAllNickNameValue() {
        const marketsMap = new Map();
        this.userNumbers.forEach(user => {
            marketsMap.set(user.nickName, { text: user.nickName, value: user.nickName });
        });
        return Array.from(marketsMap.values());
    }

    getNickNameByUserNumber(userNumber) {
        const user = this.userNumbers.find(user => user.userNumber === userNumber);
        return user ? user.nickName : null;
    }

    getColorGlass(userNumber) {
        const user = this.userNumbers.find(user => user.userNumber === userNumber);
        return user ? user.colorGlass : null;
    }

    getUserInfoByUserNumber(userNumber) {
        return this.userNumbers.find(user => user.userNumber === userNumber);
    }

    getInfoTelegram(userNumber) {
        for (const merchant of this.dataMerchants) {
            const market = merchant.markets.find(market => market.userNumber === userNumber);
            if (market) {
                return merchant.telegram;
            }
        }
        return undefined;

    }


    getAllMarketsValue() {
        const marketsMap = new Map();
        this.userNumbers.forEach(user => {
            marketsMap.set(user.marketName, { text: user.marketName, value: user.marketNumber });
        });
        return Array.from(marketsMap.values());
    }

    getUsersByMarket(market) {
        return this.userNumbers.filter(user => user.marketName === market);
    }

    getUsersByMarketNumber(marketNumber) {
        return this.userNumbers.filter(user => user.marketNumber === marketNumber);
    }
    getUsersGlass(market) {
        return this.userNumbers
            .filter(user => user.marketNumber === market)
            .map(user => ({ nickName: user.nickName, colorGlass: user.colorGlass }));
    }
}

const merchStore = new MerchStore();
export default merchStore;

