import { makeAutoObservable, runInAction } from 'mobx';

class WindowSizeStore {
    width = window.innerWidth;
    height = window.innerHeight;

    constructor() {
        makeAutoObservable(this);
        window.addEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        runInAction(() => {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        });
    }

    cleanup = () => {
        window.removeEventListener('resize', this.handleResize);
    }

    get isMobile() {
        return this.width < 768;
    }
}

const windowSizeStore = new WindowSizeStore();
export default windowSizeStore;