import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import {fetchGetPaysInfo} from '../../config/crm/apiCRM'
import { handleCopy } from "../../components/LogicComponents";

function UsersPage() {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetchGetPaysInfo();
            if (result && result.data) {
                setData(result.data);
            }
        };
        fetchData();
    }, []);

    const columns = [
        {
            title: 'ФИО',
            key: 'fio',
            render: (text, record) => `${record.lastName} ${record.firstName} ${record.middleName}`
        },
        {
            title: 'Тип зарплаты',
            dataIndex: 'salaryType',
            key: 'salaryType',
            render: text => {
                switch (text) {
                    case 'bank': return 'Банк';
                    case 'crypto': return 'Крипто';
                    default: return text;
                }
            }
        },
        {
            title: 'Название банка',
            dataIndex: 'payName',
            key: 'payName',
            render: text => {
                switch (text) {
                    case 'sber': return 'СБЕР';
                    case 'tinkoff': return 'Тинькофф';
                    default: return text;
                }
            }
        },
        { title: 'Номер', dataIndex: 'numberPay', key: 'numberPay',
            render: (_, record) => (
                <span onClick={() => handleCopy(record.numberPay)} style={{ cursor: 'pointer' }}>{record.numberPay}</span>
            )},
    ];

    return <Table columns={columns} dataSource={data} rowKey="uid" pagination={false} />;
};
export default UsersPage;
