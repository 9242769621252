import React, { useEffect, useState } from 'react';
import GlassPrice from "./components/GlassPrice";
import ControlPrice from "./components/ControlPrice";
import AdsStore from "../../state/AdsStore";
import { observer } from "mobx-react";
import {Col, Row} from "antd";

const PriceNoTabsPage = observer (() => {
    useEffect(() => {
        AdsStore.loadAllAds(); // Загрузка всех объявлений один раз при монтировании компонента

    }, []); // Пустой массив зависимостей, чтобы вызов был выполнен только один раз

    return (
    <Row gutter={[16, 16]}>
        <Col style={{ display: 'flex', flexDirection: 'column'}}>
            <>
                <ControlPrice/>
                <GlassPrice/>
            </>
        </Col>
    </Row>

    );
})

export default PriceNoTabsPage;