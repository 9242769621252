import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Layout } from 'antd';

import MySider from "./MySider";
import WebSocketStore from "../state/WebSocketStore";
import windowSizeStore from "../state/WindowSizeStore";

const {  Content } = Layout;

const App = observer(({ children, requiresWebSocket  }) => {
    const isMobile = windowSizeStore.isMobile;
    useEffect(() => {
        console.log("WebSocket connection requirement has changed:", requiresWebSocket);
        if (requiresWebSocket) {
            WebSocketStore.connect();
        }

        return () => {
            if (requiresWebSocket) {
                WebSocketStore.disconnect();
            }
        };
    }, [requiresWebSocket]);
    return (
        <Layout>
            <MySider isMobile={isMobile} />
            <Layout
                style={{ minHeight: '100vh' }}
            >
                <Content
                    style={{
                        padding: '1.5vh',
                        backgroundColor: '#e0e0e0',
                        marginTop: isMobile ? '0vh' : '0vh' // добавлен отступ сверху для мобильных устройств
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
});

export default App;
