import React, {useEffect, useMemo} from 'react';
import { observer } from 'mobx-react';
import { tradeStore } from "../../state/TradeStore";
import webSocketStore from "../../state/WebSocketStore";
import TabsOrders from "./newTabs";
import { VhRow2ColNew } from "../../Layouts/comp/CustomRow";
import {uniqueId} from "lodash";
const TradePage = observer(({ tradeType, typeTabs, isCardless }) => {
    useEffect(() => {
        tradeStore.setGlobalTradeType(tradeType);
        tradeStore.upLoadingOrders();
    }, [tradeType, isCardless]);


    const observerId = useMemo(() => uniqueId('observer_'), []); // Генерируем уникальный ID на базе lodash или другой библиотеки

    useEffect(() => {
        const handleTradeMessages = (data) => {
            tradeStore.handleWebSocketMessage(data);
        };

        webSocketStore.addObserver(handleTradeMessages,observerId);

        return () => {
            webSocketStore.removeObserver(observerId);
        };
    }, []);


    if (isCardless) {
        return (
            <VhRow2ColNew
                col1={() => <TabsOrders tradeType={"SELL"} typeTabs={typeTabs} />}
                col2={() => <TabsOrders tradeType={"BUY"} typeTabs={typeTabs} />}
                size={[12, 12]}
            />
        );
    } else {
        return <TabsOrders tradeType={tradeType} typeTabs={typeTabs} />;
    }
});

export default TradePage;
