import React from 'react';
import {Row, Col, Tabs, Space} from 'antd';
import './ControlPrice.css'
import SelectionControlPrice from "./SelectionContorPrice";
import MerchStore from "../../../state/MerchStore";
import PriceStore from "../../../state/PriceStore";
const { TabPane } = Tabs;
function ControlPrice( {} ) {
    const markets = MerchStore.getAllMarkets();
    const handleTabClick = (key) => {
        PriceStore.setMarketActive(key);
    };
    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Tabs
                    tabPosition='bottom'
                    size={"small"}
                    centered={true}
                    onTabClick={handleTabClick}
                    defaultActiveKey={PriceStore.marketActive} // Установка активной вкладки по умолчанию
                >
                    {markets.map((tab) => (
                        <TabPane tab={tab.marketName} key={tab.marketNumber} forceRender>
                            <SelectionControlPrice marketNumber={tab.marketNumber} />
                        </TabPane>
                    ))}
                </Tabs>
            </Col>
        </Row>
    );
}

export default ControlPrice;
