import React, { useState, useEffect } from 'react';
import { Select, InputNumber, Button, Table,Row, Col,Badge,Typography   } from 'antd';
import {getBankTag, MarketIcon, MerchantIcon} from "../../components/CommonComponents";
import {OneColCenter} from "../../Layouts/formRow/2col";
import {fetchGlassMass} from "../../config/api/apiGlass";
import {
    payOptionsArray,
    marketsOptions,
    assetsOptions,
    tradeTypeOptions,
    glassUsersOptions, glassStatusOptions
} from "../../config/constants";

const { Title } = Typography;
const { Option } = Select;
const MassGlassPage = () => {
    const [loading,setLoading] = useState(false)
    const [filters, setFilters] = useState({
        asset: "USDT", // Значение по умолчанию для asset
        tradeType: "BUY", // Значение по умолчанию для tradeType
        pay: ["Tinkoff"],
        isMerchant: "all",
        amount: 0, // Значение по умолчанию для amount
        marketNumbers: [],
        rate: 95,
        orderCount: 150,
        online : "all"
    });
    const [data, setData] = useState([]);

    const handleChange = (name, value) => {
        setFilters({ ...filters, [name]: value });
    };

    const handleSubmit = async () => {
        const preparedFilters = {
            ...filters,
            amount: filters.amount === "" || isNaN(filters.amount) || filters.amount === null ? 0 : filters.amount,
            rate: filters.rate === "" || isNaN(filters.rate) ? null : filters.rate,
            orderCount: filters.orderCount === "" || isNaN(filters.orderCount) ? null : filters.orderCount,
            online: filters.online === "all" ? null : filters.online,
            isMerchant: filters.isMerchant,
        };
        try {
            setLoading(true)
            const response = await fetchGlassMass(preparedFilters);
            setData(response.data);
        } catch (error) {
            console.error('Ошибка при отправке запроса:', error);

        } finally {
            setLoading(false)
        }
    };
    useEffect(() => {
        handleSubmit();
    }, []); // Пустой массив зависимостей означает, что эффект запустится только при монтировании компонента

    const columns = [
        {
            title: 'Биржа',
            width: '5%',
            dataIndex: 'marketNumber',
            key: 'marketNumber',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Badge
                        dot
                        color={record.online ? '#52c41a' : '#f5222d'} // Зеленый для онлайн, красный для офлайн
                    >
                        <MarketIcon market={record.marketNumber} />
                    </Badge>

                </div>
            ),
        },
        {
            title: 'Рекламодатель',
            width: '20%',
            dataIndex: 'marketNumber',
            key: 'marketNumber',
            render: (text, record) => (
                <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span> <b>{record.nickName}</b></span>
                    {record.isMerchant && <span style={{ marginLeft: '10px' }}><MerchantIcon /></span>}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{record.orderCount} ордеров |</span>
                    <span style={{ marginLeft: '10px' }}>{record.rate}% выполнено</span>
                </div>
                </>
            ),
        },
        {
            title: 'Цена',
            width: '15%',
            dataIndex: 'price',
            key: 'price',
            render: (text) => (
                <Title level={4}>{Number(text).toLocaleString('ru-RU')} ₽</Title>

            ),
        },
        {
            title: 'Лимит/Доступно',
            dataIndex: 'minAmount',
            width: '20%',
            render: (text, record) => (
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{Number(record.minAmount).toLocaleString('ru-RU')} - {Number(record.maxAmount).toLocaleString('ru-RU')} ₽</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{record.surplus} {record.asset}</span>
                    </div>
                </>
            ),
        },
        {
            title: 'Способ оплаты',
            width: '30%',
            dataIndex: 'payMethods',
            key: 'payMethods',
            render: payMethods => (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {payMethods.map((method, index) => (
                        <div key={index} style={{ marginBottom: '10px' }}>
                            {getBankTag(method)}
                        </div>
                    ))}
                </div>
            ),
        },
        {
            width: '10%',
            title: 'Действие',
            dataIndex: 'adv',
            key: 'adv',
            render: (text, record) => (
                <>
                    <Button>Действие</Button>
                </>
            ),
        }
    ];

    return (
        <OneColCenter
            col1={() => (
                <div>
                    <div style={{ margin: 'auto', paddingBottom:16 }}>
                        <Row gutter={[16, 16]} style={{paddingBottom:16 }}>
                            <Col xs={24} sm={6} md={6} lg={3}>
                                <Select
                                    placeholder="Asset"
                                    style={{ width: '100%' }}
                                    value={filters.asset}
                                    onChange={(value) => handleChange('asset', value)}
                                    options={assetsOptions}
                                />
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={4}>
                                <Select
                                    placeholder="Trade Type"
                                    style={{ width: '100%' }}
                                    value={filters.tradeType}
                                    options={tradeTypeOptions}
                                    onChange={(value) => handleChange('tradeType', value)}
                                >
                                </Select>
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={3}>
                                <Select
                                    onChange={(value) => handleChange('isMerchant', value)}
                                    value={filters.isMerchant}
                                    style={{ width: '100%' }}
                                    options={glassUsersOptions}
                                >
                                </Select>
                            </Col>


                            <Col xs={24} sm={6} md={6} lg={3}>
                                <Select
                                    onChange={(value) => handleChange('online', value)}
                                    value={filters.online}
                                    style={{ width: '100%' }}
                                    options={glassStatusOptions}
                                />
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={4}>
                                <InputNumber
                                    placeholder="Рейтинг от"
                                    style={{ width: '100%' }}
                                    min={0}      // Минимальное значение
                                    max={100}    // Максимальное значение
                                    step={1}     // Шаг
                                    value={filters.rate}
                                    onChange={(value) => handleChange('rate', value)}
                                />
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={3}>
                                <InputNumber
                                    placeholder="Кол-во ордеров"
                                    style={{ width: '100%' }}
                                    min={0}         // Минимальное значение
                                    max={50000}     // Максимальное значение
                                    step={100}      // Шаг
                                    value={filters.orderCount}
                                    onChange={(value) => handleChange('orderCount', value)}
                                />
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={4}>
                                <InputNumber
                                    placeholder="Цена"
                                    style={{ width: '100%' }}
                                    min={0}         // Минимальное значение
                                    step={100}      // Шаг
                                    value={filters.amount}
                                    onChange={(value) => handleChange('amount', value)}
                                />
                            </Col>


                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={10}>
                                <Select mode="multiple" placeholder="Способ оплаты" style={{ width: "100%" }}
                                        onChange={(value) => handleChange('pay', value)}
                                        value={filters.pay}
                                >
                                    {payOptionsArray.map(item => (
                                        <Option key={item.value} value={item.value}>{item.label}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={10}>
                                <Select
                                    mode="multiple"
                                    placeholder="Биржи"
                                    style={{ width: '100%' }}
                                    onChange={(value) => handleChange('marketNumbers', value)}
                                >
                                    {marketsOptions.map(market => (
                                        <Option key={market.value} value={market.value}>{market.label}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={4}>
                                <Button type="primary" onClick={handleSubmit} style={{ width: '100%' }}>Поиск</Button>
                            </Col>
                        </Row>
                    </div>
                    <Table dataSource={data} columns={columns} rowKey="adv" loading={loading}      scroll={{ x: 'max-content'}}/>

                </div>
            )}
        />

    );
};

export default MassGlassPage;
