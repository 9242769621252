import React, {useEffect, useState,useCallback} from 'react';
import {
    Row,
    Col,
    Tabs,Button
} from 'antd';
import { observer } from 'mobx-react';
import MerchStore from "../../../state/MerchStore";
import AdsStore from "../../../state/AdsStore";
import {ControlPanel} from "./PriceEditor";
import { DndContext, PointerSensor, useSensor, useSensors, closestCenter } from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
const { TabPane } = Tabs;

const  ControlUser= observer(({ userNumber, marketNumber }) => {
    const [buyData, setBuyData] = useState(null);
    const [sellData, setSellData] = useState(null);
    const [loadingList, setLoadingList] = useState(true);
    const btnAdsList = async (userNumber) => {
        try {
            const response = await AdsStore.getAdsData(userNumber); // Добавлено await
            setBuyData(response.BUY)
            setSellData(response.SELL)
            setLoadingList(AdsStore.getAdsStatus(userNumber))
        } catch (error) {
        }
    };

    useEffect(() => {
        btnAdsList(userNumber);
    }, [userNumber, AdsStore.adsData[userNumber]]); // Добавление userNumber в зависимости для реакции на его изменения
    return (

        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'column' }}>
                <ControlPanel title="Продажа" userNumber={userNumber} adsList={sellData} marketNumber={marketNumber} tradeType="SELL" loadingList={loadingList}/>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'column' }}>
                <ControlPanel title="Покупка" userNumber={userNumber} adsList={buyData} marketNumber={marketNumber} tradeType="BUY" loadingList={loadingList}/>
            </Col>
        </Row>

    );
})

const SelectionControlPrice = observer(({ marketNumber }) => {
    const [merchList, setMerchList] = useState([]);
    const [activeUserNumber, setActiveUserNumber] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const users = MerchStore.getUsersByMarketNumber(marketNumber);
            setMerchList(users);
            // Устанавливаем активного пользователя по умолчанию (если список не пуст)
            if (users.length > 0) {
                setActiveUserNumber(users[0].userNumber);
            }
        };

        fetchData();
    }, [marketNumber]);

    const handleTabChange = useCallback((key) => {
        setActiveUserNumber(key);
    }, []);

    const { text, color } = AdsStore.buttonProps;

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === 'Backquote') {  // KeyQ всегда соответствует клавише 'Q'
                AdsStore.toggleTriangleMode();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Убираем обработчик при размонтировании компонента
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            <Tabs
                centered={true}
                onChange={handleTabChange}
                activeKey={activeUserNumber}
                tabBarExtraContent={
                    <Button   style={{
                        width: 100,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                        type={color}
                        onClick={() => AdsStore.toggleTriangleMode()}
                    >
                        {text}
                    </Button>
                }
            >
                {merchList.map((tab) => (
                    <TabPane tab={tab.nickName} key={tab.userNumber}>
                        {/* Контент для каждого пользователя может быть добавлен здесь */}
                    </TabPane>
                ))}
            </Tabs>
            {activeUserNumber && <ControlUser userNumber={activeUserNumber} marketNumber={marketNumber} />}
        </>
    );
});

export default SelectionControlPrice;
