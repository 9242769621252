import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './config/google-fire/authContext';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
        <ConfigProvider locale={ruRU}>
            <AuthProvider>
                <App />
            </AuthProvider>
        </ConfigProvider>
    // </React.StrictMode>
);

reportWebVitals();
