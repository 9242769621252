import React, { useState } from 'react';
import { Form, Input, Button, Card, Alert,Select } from 'antd';
import { registerUser } from '../../config/google-fire/authController';
import { mongoRegisterUser } from '../../config/crm/apiCRM'
import { addToast } from "../../components/Notification/Toast";
function RegisterPage() {
    const [form] = Form.useForm();
    const [notification, setNotification] = useState('');
    const [error, setError] = useState('');
    const onFinish = async (values) => {
        try {
            const uid = await registerUser(
                values.email,
                values.password,
                values.lastName,
                values.firstName,
                values.middleName,
                values.birthdate,
                values.telegram,
                values.roleType
            );

            const body = {
                uid,
                email: values.email,
                lastName: values.lastName,
                firstName: values.firstName,
                middleName: values.middleName,
                birthdate: values.birthdate,
                telegram: values.telegram,
                roleType: values.roleType
            };
            await mongoRegisterUser(body)

            form.resetFields();
            setNotification('Пользователь успешно зарегистрирован.');
            setError('');
        } catch (error) {
            setError('Ошибка регистрации. Пожалуйста, проверьте введенные данные. ');
            await addToast('error','Ошибка регистрации',`${error}.`);
            setNotification('');
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <Card title="Административная Панель" className="my-box-style">
                        {error && <Alert message={error} type="error" showIcon />}
                        {notification && <Alert message={notification} type="success" showIcon />}
                        <Form form={form} onFinish={onFinish}>
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Пожалуйста, введите Email' }]}
                            >
                                <Input placeholder="Email" autoComplete="username" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]}
                            >
                                <Input.Password placeholder="Пароль" autoComplete="new-password" />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                rules={[
                                    { required: true, message: 'Пожалуйста, подтвердите пароль' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Пароли не совпадают'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Подтвердите пароль" autoComplete="new-password" />
                            </Form.Item>
                            <Form.Item
                                name="telegram"
                                rules={[{ required: true, message: 'Пожалуйста, введите ID Telegram' }]}
                            >
                                <Input placeholder="ID Telegram" />
                            </Form.Item>
                            <Form.Item
                                name="lastName"
                                rules={[{ required: true, message: 'Пожалуйста, введите фамилию' }]}
                            >
                                <Input placeholder="Фамилия" />
                            </Form.Item>
                            <Form.Item
                                name="firstName"
                                rules={[{ required: true, message: 'Пожалуйста, введите имя' }]}
                            >
                                <Input placeholder="Имя" />
                            </Form.Item>
                            <Form.Item
                                name="middleName"
                                rules={[{ required: true, message: 'Пожалуйста, введите отчество' }]}
                            >
                                <Input placeholder="Отчество" />
                            </Form.Item>
                            <Form.Item
                                name="birthdate"
                                rules={[{ required: true, message: 'Пожалуйста, введите дату рождения' }]}
                            >
                                <Input type="date" placeholder="Дата рождения" />
                            </Form.Item>
                            <Form.Item  name="roleType"
                                        rules={[{ required: true, message: 'Пожалуйста, введите тип пользователя' }]}
                            >
                                <Select>
                                    <Select.Option value="WORKER">Воркер</Select.Option>
                                    <Select.Option value="PRICE">ПрайсМЕН</Select.Option>
                                </Select>

                            </Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Зарегистрировать
                            </Button>
                        </Form>
                    </Card>
                </div>
            </div>
        </div>
    );
}
export default RegisterPage;
