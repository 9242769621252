import {CRM_URL} from "../configUrls";
import {postApiRequest, getApiRequest, getUserIp} from "../apiParams";
import {addToast} from "../../components/Notification/Toast";



export const sendLoginNotification = async (uid, status) => {
    let statusMsg;
    switch (status) {
        case 'login_success':
            statusMsg = 'login_success';
            break;
        case 'logout':
            statusMsg = 'logout';
            break;
        case 'active_session':
            statusMsg = 'active_session';
            break;
        default:
            statusMsg = '';
    }

    const ipAddress = await getUserIp(); // Дожидаемся получения IP-адреса
    return postApiRequest(`${CRM_URL}/notify/login`, { uid, statusMsg, ipAddress });
};

export const mongoRegisterUser = (body) => {
    return postApiRequest(`${CRM_URL}/user/add-user`, body);
};


export const sendSessionDataToServer = async (sessionData, action) => {
    const ipAddress = await getUserIp(); // Дожидаемся получения IP-адреса
    const endpoint = action === 'login' ? `${CRM_URL}/user/session/login` : `${CRM_URL}/user/session/logout`;
    const sessionDataWithIp = {
        ...sessionData,
        ipAddress
    };
    return postApiRequest(endpoint, sessionDataWithIp);
};

export const checkActiveSession = async (uid, sessionId) => {
    try {
        const response = await fetch(`${CRM_URL}/user/session/check`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uid, sessionId })
        });

        if (!response.ok) {
            throw new Error('Ошибка запроса');
        }

        const data = await response.json();
        return data.isActive; // Предполагается, что сервер возвращает объект с полем isActive
    } catch (error) {
        await addToast('error','Проверка активной сессии',`${error}.`);
        return false;
    }
};


export const sendSessionEndRequestSys = (uid, logoutType) => {
    return postApiRequest(`${CRM_URL}/user/add-user`, {uid, logoutType});
};


export const fetchUserInfo = async (uid) => {
    return getApiRequest(`${CRM_URL}/user/info/${uid}`, );
};


export const fetchUserUpdateSalary = (body) => {
    return postApiRequest(`${CRM_URL}/user/update-salary`, body);
};


export const fetchSessionHistory = (uid) => {
    return postApiRequest(`${CRM_URL}/user/session/history`, {uid});
};


export const fetchTeam = (body) => {
    return postApiRequest(`${CRM_URL}/user/team`, body);
};

export const fetchGetPaysInfo = () => {
    return getApiRequest(`${CRM_URL}/user/info-pay`);
};