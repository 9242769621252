import React from "react";
import { Col, Row} from 'antd';

export const VhRow2Col = ({ col1, col2,size=[12,12] }) => {
    const [size1, size2] = size;
    return (
        <Row gutter={[16,16]} style={{ height: 'calc(100vh - 32px)', overflow: 'hidden' }}>
            <Col xs={24} sm={24} md={size1} lg={size1} xl={size1} className="mobile-column" style={{ display: 'flex', flexDirection: 'column' }}>
                {col1()}
            </Col>
            <Col xs={24} sm={24} md={size2} lg={size2} xl={size2} className="mobile-column" style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                {col2()}
            </Col>
        </Row>
    );
};

export const VhRow2ColNew = ({ col1, col2,size=[12,12] }) => {
    const [size1, size2] = size;
    return (
        <Row gutter={[16,16]}>
            <Col xs={24} sm={24} md={size1} lg={size1} xl={size1} className="mobile-column" style={{ display: 'flex', flexDirection: 'column' }}>
                {col1()}
            </Col>
            <Col xs={24} sm={24} md={size2} lg={size2} xl={size2} className="mobile-column" style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                {col2()}
            </Col>
        </Row>
    );
};