import React, { useEffect, useState } from 'react';
import {Card, Table} from 'antd';
import { observer } from "mobx-react";

export const CustomCard = observer(({vhPx, title, extra, children }) => {
    const [cardHeight, setCardHeight] = useState(window.innerHeight - vhPx);

    useEffect(() => {
        const handleResize = () => {
            setCardHeight(window.innerHeight - vhPx); // Вы можете настроить вычет для высоты других элементов, если это необходимо
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Для инициализации при монтировании

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Card
            title={title}
            extra={extra}
            size={"small"}
            style={{ height: cardHeight, overflowY: 'auto' }}
        >
            {children}
        </Card>
    );
});




export const CustomCardRow = observer(({ title, extra, children, children2 }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ position: 'sticky', top: 0, zIndex: 1, marginBottom:16 }}>
                <Card
                    title={title}
                    extra={extra}
                    size="small"
                    style={{ overflowY: 'auto' }}
                >
                    {children} {/* Этот блок будет прилипать к верху */}
                </Card>
            </div>
            <div style={{ flexGrow: 1}}>
                {children2} {/* Этот блок не перекрывает Card */}
            </div>
        </div>
    );
});


export const CustomTableVh = observer(({ vhPx, dataSource, columns,rowClassName,onChange,rowKey,}) => {
    const [tableHeight, setTableHeight] = useState(window.innerHeight - vhPx);

    useEffect(() => {
        const handleResize = () => {
            setTableHeight(window.innerHeight - vhPx); // Вычитаем высоту других элементов
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Для инициализации при монтировании
        console.log(tableHeight)
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content', y: tableHeight }}
            style={{ overflowY: 'auto' }}
            rowClassName={rowClassName}
            onChange={onChange}
            rowKey={rowKey}
        />
    );
});
