import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Card, Alert, Spin, Result } from 'antd';
import { UserOutlined, LockOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import authStore from "../../state/AuthStore";
import { observer } from 'mobx-react';


const  LoginPage  = observer(() => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [userName, setUserName] = useState('');
    const onFinish = async (values) => {
        setError(null);
        try {
            authStore.loading = true;
            await authStore.login(values.email, values.password);
            setSuccess(true);
            setTimeout(() => {
                navigate('/home');
            }, 1500); // Переадресация через 1.5 секунды
        } catch (error) {
            setLoading(false);
            const errorMessage = error.message === 'active_session'
                ? 'На другом устройстве уже активна сессия.'
                : 'Ошибка входа в систему. Пожалуйста, проверьте ваши учетные данные.';
            setError(errorMessage);
        }
    };
    useEffect(() => {
        if (success) {
            setTimeout(() => {
                navigate('/home');
            }, 1500);
        }
    }, [success, navigate]);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('dataUser'));
        const lastName = userData ? userData.lastName : 'Фамилия';
        const firstName = userData ? userData.firstName : 'Имя';
        setUserName(`${firstName} ${lastName}`);
    }, []); // Пустой массив зависимостей гарантирует, что эффект выполнится только один раз после монтирования компонента


    return (
        <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
            <Col xs={22} sm={18} md={12} lg={10} xl={8}>
                <Card title="Вход в систему" className="my-box-style">
                    {success ? (

                        <Result
                            icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                            title={`Привет, ${userName}!`}
                        />
                    ) : (
                        <>
                            {error && <Alert message={error} type="error" showIcon style={{ marginBottom: '16px' }} />}
                            <Spin spinning={loading}>
                                <Form
                                    form={form}
                                    onFinish={onFinish}
                                    size="large"
                                >
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Пожалуйста, введите вашу электронную почту!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder="Электронная почта"
                                            autoComplete="email"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Пожалуйста, введите пароль!',
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                            placeholder="Пароль"
                                            autoComplete="current-password"
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                            Войти
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Spin>
                        </>
                    )}
                </Card>
            </Col>
        </Row>
    );
})

export default LoginPage;
