import React, { useEffect,useState, useCallback } from 'react';
import OrderTable from "./components/OrderTable";
import OrderFilters from "./components/OrderFilters";
import { OneColCenter } from "../../Layouts/formRow/2col";
import { fetchHistoryOrders} from "../../config/api/apiGeneral";


const HistoryPage = () => {
    const ITEMS_PER_PAGE = 10;
    const team = localStorage.getItem('team');
    console.log(team)
    const [filters, setFilters] = useState({
        userNickName: 'all',
        tradeType: 'all',
        marketName: 'all',
        orderStatus: 'all',
        asset: 'all',
        totalPriceStart: '',
        totalPriceEnd: '',
        createTimeStart: '',
        createTimeEnd: '',
        orderNumber: '',
        team: team
    });
    const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE); // Начальное значение
    const [loadingTable, setLoadingTable] = useState(false); // Начальное значение

    const updateFilter = useCallback((key, value) => {
        setFilters(prev => ({ ...prev, [key]: value }));
    }, []);

    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalOrders, setTotalOrders] = useState(0);

    const handleSearch =  useCallback(async(page) => {
        const payload = {
            ...filters,
            tradeType: filters.tradeType !== 'all' ? filters.tradeType : null,
            createTimeStart: filters.createTimeStart ? (new Date(filters.createTimeStart).getTime()) : null,
            createTimeEnd: filters.createTimeEnd ? (new Date(filters.createTimeEnd).getTime()) : null,
            orderStatus: filters.orderStatus !== 'all' ? filters.orderStatus : null,
            marketName: filters.marketName !== 'all' ? filters.marketName : null,
            userNickName: filters.userNickName !== 'all' ? filters.userNickName : null,
            orderNumber: filters.orderNumber ? String(filters.orderNumber) : null,
            asset: filters.asset !== 'all' ? filters.asset : null,
            size: itemsPerPage,
            page,
            totalPriceStart: parseFloat(filters.totalPriceStart) || null,
            totalPriceEnd: parseFloat(filters.totalPriceEnd) || null,
        };
        setLoadingTable(true)
        const response = await fetchHistoryOrders(payload)
        const dataGo = response.data.map(item => ({ ...item, key: item._id }))
        setTableData(dataGo);
        setTotalOrders(response.total);
        setLoadingTable(false)
    }, [filters,itemsPerPage]);

    useEffect(() => {
        handleSearch(1); // Вызываем handleSearch для первой страницы
    }, [handleSearch]); // Передаем handleSearch как зависимость


    const [isAdvancedSearchVisible, setIsAdvancedSearchVisible] = useState(false);

    const newSearch = useCallback(async () => {
        setCurrentPage(1);
        await handleSearch(1);
    }, [handleSearch]);

    const handlePageClick = async (page) => {
        setCurrentPage(page);
        await handleSearch(page);
    };
    return (
        <OneColCenter
            col1={() => (
                <>
                    <OrderFilters
                        filters={filters}
                        updateFilter={updateFilter}
                        newSearch={newSearch}
                        isAdvancedSearchVisible={isAdvancedSearchVisible}
                        setIsAdvancedSearchVisible={setIsAdvancedSearchVisible}
                    />
                    <OrderTable
                        tableData={tableData}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        totalOrders={totalOrders}
                        handlePageClick={handlePageClick}
                        setItemsPerPage = {setItemsPerPage}
                        setCurrentPage={setCurrentPage}
                        loadingTable = {loadingTable}
                    />
                </>
            )}
        />

    );
}

export default HistoryPage;
