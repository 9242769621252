import React from 'react';
import { OneColCenter } from "../../Layouts/formRow/2col";
import MerchantPage from "../AdminPage/MerchantPage";

const SessionPage = () => {
    return (
        <OneColCenter
            col1={() => (
                <> <MerchantPage/></>
            )}
        />

    );
};

export default SessionPage;