// ReportModal.js
import React, { useState, useEffect,useCallback } from 'react';
import { Modal, Row, Col,Button,message,Select } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import {tradeStore} from "../../../../state/TradeStore";
import {formatNumber} from "../../../../components/numericFunc";
const { Option } = Select;

const options = {
    "SELL": [
        { value: "встречный", label: "Встречный" },
        { value: "обработка", label: "Обработка" },
        { value: "мороз", label: "Мороз" },
        { value: "мороз", label: "Нет зеленого" },
        { value: "другое", label: "Другое" },
        { value: "мошенник", label: "Мошенник" },
    ],
    "BUY": [
        { value: "встречный", label: "Встречный" },
        { value: "обработка", label: "Обработка" },
        { value: "держим", label: "Нет красного" },
        { value: "держим", label: "Держим (отмена)" },
        { value: "другое", label: "Другое" }
    ]
};

const OrdersTable = ({ tradeType, orders, selectedOptions, updateSelectedOptions, counts}) => {
    const [formula, setFormula] = useState('');

    useEffect(() => {
        if (orders.length === 0) {
            setFormula('=0');
            return;
        }

        const filteredOrders = orders.filter(order => {
            const option = selectedOptions[order.orderNumber] ? selectedOptions[order.orderNumber].value : "другое";
            return option !== "встречный" && option !== "обработка" && option !== "держим" && option !== "мороз";
        });


        const startWithMinus = tradeType === "SELL" ? '-' : '';
        const calculation = filteredOrders.map((order, index) =>
            `${index === 0 ? startWithMinus : ''}${parseFloat(order.amount).toFixed(2).replace('.', ',')}`
        ).join(tradeType === "SELL" ? ' - ' : ' + ');

        setFormula('=' + calculation);
    }, [orders, tradeType, selectedOptions]);


    const copyReportToClipboard = () => {
        if (!validateMatchingCounts()) return;

        const report = orders.map((order, index) => {
            const orderTypeLabel = selectedOptions[order.orderNumber] ? options[tradeType].find(opt => opt.value === selectedOptions[order.orderNumber].value).label : "другое";
            return `${index + 1}. ${formatNumber(order.totalPrice,true)} - ${order.userNickName}\n${order.amount} - (${orderTypeLabel})\n№ ${order.orderNumber}`;
        }).join('\n\n');
        const userData = JSON.parse(localStorage.getItem('dataUser'));
        const lastName = userData ? userData.lastName : 'Фамилия';
        const firstName = userData ? userData.firstName : 'Имя';
        const completeReport = `${report}\n\nОтчет подготовил: ${lastName} ${firstName}`;

        navigator.clipboard.writeText(completeReport).then(() => {
            message.success('Отчет скопирован в буфер обмена!', 1.5);
        }, err => {
            message.error('Ошибка при копировании отчета!');
        });
    };

    const handleSelectChange = (value, orderId) => {
        updateSelectedOptions(orderId, value, tradeType);
    };

    const validateMatchingCounts = () => {
        const sellCount = (counts["SELL"]["встречный"] || 0) + (counts["SELL"]["обработка"] || 0);
        const buyCount = (counts["BUY"]["встречный"] || 0) + (counts["BUY"]["обработка"] || 0);
        if (sellCount !== buyCount) {
            message.error('Количество "встречный" и "обработка" в покупках и продажах должны совпадать!');
            return false;
        }
        return true;
    };

    const copyFormulaToClipboard = () => {
        if (!validateMatchingCounts()) return;

        navigator.clipboard.writeText(formula).then(() => {
            message.success('Формула скопирована в буфер обмена!', 1.5);
        }, err => {
            message.error('Ошибка при копировании формулы!');
        });
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3>{tradeType === "BUY" ? "Покупка" : "Продажа"}</h3>
                <Button icon={<CopyOutlined />} onClick={copyReportToClipboard}>Отчет</Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <Button icon={<CopyOutlined />} onClick={copyFormulaToClipboard} style={{ marginRight: '10px' }}>

                </Button>
                <div style={{
                    overflowX: 'auto', // Добавляет горизонтальную прокрутку при необходимости
                    whiteSpace: 'nowrap', // Гарантирует, что текст не будет переноситься на новую строку
                    flex: 1 // Занимает оставшееся пространство
                }}>
                    <div style={{ flexShrink: 0 }}>
                        {formula}
                    </div>
                </div>
            </div>
            <div>
                {orders.map((order, index) => (
                    <Row key={order.orderNumber} gutter={4} style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                        <Col span={16}>
                            <div>{index + 1}. {formatNumber(order.totalPrice,true)} - {order.userNickName}</div>
                            <div>{order.amount} {order.asset}</div>

                            <div style={{ color: '#888', fontSize: 'small' }}>№ {order.orderNumber}</div>
                        </Col>
                        <Col span={8}>
                            <Select defaultValue="другое" style={{ width: '100%' }} onChange={(value) => handleSelectChange(value, order.orderNumber)}>
                                {options[tradeType].map(option => <Option key={option.value} value={option.value}>{option.label}</Option>)}
                            </Select>
                        </Col>
                    </Row>
                ))}
            </div>
        </div>
    );
};

export const ReportModal = ({ isVisible, handleClose }) => {
    const [selectedOptions, setSelectedOptions] = useState({});
    const [counts, setCounts] = useState({ SELL: {}, BUY: {} });

    useEffect(() => {
        if (isVisible) {
            // Reset counts for SELL and BUY
            setCounts({
                SELL: { "встречный": 0, "обработка": 0, "мороз": 0, "другое": 0 },
                BUY: { "встречный": 0, "обработка": 0, "держим": 0, "другое": 0 }
            });
            // Clear selected options
            setSelectedOptions({});
        }
    }, [isVisible]);

    const updateSelectedOptions = useCallback((orderId, value, tradeType) => {
        setSelectedOptions(prev => {
            const previousValue = prev[orderId] ? prev[orderId].value : "другое";

            // Update counts: adjust counts for previous and new value
            setCounts(c => ({
                ...c,
                [tradeType]: {
                    ...c[tradeType],
                    [previousValue]: Math.max((c[tradeType][previousValue] || 0) - 1, 0),
                    [value]: (c[tradeType][value] || 0) + 1
                }
            }));

            return { ...prev, [orderId]: { value, tradeType } };
        });
    }, []);

    const appealSellOrders = tradeStore.dataOrders.filter(order => order.tradeType === "SELL" && order.orderStatus === "APPEAL");
    const appealBuyOrders = tradeStore.dataOrders.filter(order => order.tradeType === "BUY" && order.orderStatus === "APPEAL");

    return (
        <Modal
            title="Отчет по апелляциям"
            open={isVisible}
            onOk={handleClose}
            onCancel={handleClose}
            width={800}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <OrdersTable tradeType="SELL" orders={appealSellOrders} selectedOptions={selectedOptions} updateSelectedOptions={updateSelectedOptions} counts={counts} />
                </Col>
                <Col span={12}>
                    <OrdersTable tradeType="BUY" orders={appealBuyOrders} selectedOptions={selectedOptions} updateSelectedOptions={updateSelectedOptions}  counts={counts}  />
                </Col>
            </Row>
        </Modal>
    );
};
