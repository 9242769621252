
export const API_PUSH = '/push';

export const API_URL = '/api';

export const CRM_URL = '/api/crm1';
export const CRM_URL2 = '/api/crm2';
export const WSS_URL = 'wss://v3-corp.ru:8765/';
export const BROKER_URL = '/broker';
export const BOT_URL = '/bots';
export const MASS_GLASS_URL = '/glass-mass';

export const URLS_GLASS = {
    1: "/glass-1",
    2: "/glass-2",
    3: "/glass-3",
    4: "/glass-4"
}
//
// export const API_PUSH = 'http://localhost:9000/v1'
//
// export const API_URL = 'http://localhost:8080/v1';
//
// export const CRM_URL = 'http://localhost:8080/v1/crm1';
//
//
// export const BROKER_URL = 'http://localhost:6543';
// export const CRM_URL2 = 'http://localhost:8080/v1/crm2';
//
//
// // export const WSS_URL = 'wss://v3-corp.ru:8765/ws';
// export const WSS_URL = 'wss://v3-corp.ru:8765/';
//
// // export const WSS_URL = 'ws://localhost:8765/';
//
//
// export const BOT_URL = 'http://localhost:7777';
// export const MASS_GLASS_URL = 'http://185.84.162.238:9111';
//
//
// export const URLS_GLASS = {
//     1: "http://45.153.68.28:9001",
//     2: "http://45.153.68.28:9002",
//     3: "http://45.153.68.28:9004",
//     4: "http://45.153.68.28:9003"
// }
