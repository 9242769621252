import React from 'react';
import { OneColCenter } from "../../Layouts/formRow/2col";
import RegisterPage from "./RegisterPage";
import UsersPage from "./UsersPage";
export function AdminPanel() {

    return (
        <OneColCenter
            col1={() => (
             <RegisterPage/>
            )}
        />
    );
}

export function UserPanel() {

    return (
        <OneColCenter
            col1={() => (
                <UsersPage/>
            )}
        />
    );
}

