import React, {useEffect, useState} from 'react';
import Countdown from 'react-countdown';
import {fetchOrderDone} from "../../config/api/apiGeneral";
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import {rendererTime} from "../LogicComponents";
import {tradeStore} from "../../state/TradeStore";

function statusType (orderStatus, tradeType) {
    if (orderStatus === "PEND") {
        if (tradeType === "SELL") {
            return "PENDSELL"
        } else {
            return "PENDBUY"
        }
    } else if (orderStatus === "PAID") {
        if (tradeType === "SELL") {
            return "PAIDSELL"
        } else {
            return "PAIDBUY"
        }
    } else {
        return orderStatus
    }
}
function OrderStatus({ orderInfo, done}) {
    let btnStatusText = null;

    switch (statusType(orderInfo.orderStatus, orderInfo.tradeType)) {
        case 'COMPLETE':
            btnStatusText = <span className="fs-6">Ордер исполнен</span>;
            break;
        case 'APPEAL':
            btnStatusText = <span className="fs-6">Ордер в стадии спора</span>;
            break;
        case 'PENDSELL':
            btnStatusText = (
                <span className="fs-6">
                    Ожидаем оплату от контрагента  <Countdown
                    date={orderInfo.tsNotifyPendEnd}
                    renderer={rendererTime}
                />
                </span>
            );
            break;

        case 'PENDBUY':
            btnStatusText = (
                <span className="fs-6">
                    Необходимо оплатить  <Countdown
                    date={orderInfo.tsNotifyPendEnd}
                    renderer={rendererTime}
                />
                </span>
            );
            break;

        case 'PAIDSELL':
            btnStatusText = (
                <span className="fs-6">
                    Необходимо перевести <Countdown date={orderInfo.tsNotifyPaidEnd} renderer={rendererTime} />
                </span>
            );
            break;

        case 'PAIDBUY':
            btnStatusText = (
                <span className="fs-6">
                    Ожидаем перевода монет <Countdown date={orderInfo.tsNotifyPaidEnd} renderer={rendererTime} />
                </span>
            );
            break;

        case 'CANCEL':
            btnStatusText = <span className="fs-6">Ордер отменен</span>;
            break;
        case 'CANCEL_SYS':
            btnStatusText = <span className="fs-6">Ордер отменен системой</span>;
            break;
        default:
            btnStatusText = null;
    }

    const [isLocked, setIsLocked] = useState(true);
    // Функция для переключения блокировки
    const toggleLock = () => {
        setIsLocked(!isLocked);
    };

    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');

    const handleOrderDoneBuy = async (item) => {
        if (!done) {
            return;
        }
        setLoading(true);
        try {
            const response = await tradeStore.handleOrderDone(item)
            if (response!== 1000) {
                setStatusMessage("Делюга"); // Установить сообщение из ответа сервера
            } else {
                setStatusMessage('Успешно оплачен!'); // Установить сообщение об успешной операции
            }
        }  finally {
            setLoading(false);
        }

    };

    const handleOrderDone = async () => {
        if (!done) {
            return;
        }
        setLoading(true);
        try {
            const response = await fetchOrderDone(orderInfo);
            if (response.code !== 1000) {
                setStatusMessage(response.msg); // Установить сообщение из ответа сервера
            } else {
                setStatusMessage('Успешно!'); // Установить сообщение об успешной операции
            }
        } catch (error) {
            setStatusMessage('Ошибка при выполнении операции.'); // Установить сообщение об ошибке
        } finally {
            setLoading(false);
        }
    };

    // Эффект для обработки блокировки/разблокировки
    useEffect(() => {
        setIsLocked(true)
        setLoading(false);
        setStatusMessage('');
    }, [orderInfo]);
    return (

        <div className="btn-group w-100">
            {orderInfo.orderStatus === 'COMPLETE' && (
                <button className='btn btn-success btn-sm w-100 disabled'>
                    {btnStatusText}
                </button>

            )}
            {orderInfo.orderStatus === 'APPEAL' && (
                <button className={`btn btn-danger btn-sm w-100 disabled`}>
                    {btnStatusText}
                </button>
            )}
            {orderInfo.orderStatus === 'PEND' && orderInfo.tradeType === 'SELL' &&(
                <button className={`btn btn-dark btn-sm w-100 disabled`}>
                    {btnStatusText}
                </button>
            )}
            {orderInfo.orderStatus === 'PEND' && orderInfo.tradeType === 'BUY' &&(
                <button
                className={`btn btn-outline-success btn-sm w-100`}
                    onClick={() => handleOrderDoneBuy(orderInfo)
                    }
                >
                    {loading ? (
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {' '}Оплачиваю платеж{/* Убедитесь, что здесь нет класса visually-hidden */}
                        </>
                    ) : (
                        statusMessage || btnStatusText
                    )}
                </button>
            )}
            {orderInfo.orderStatus === 'PAID' && orderInfo.tradeType === 'SELL' && (
                <button
                    className={`btn btn-primary btn-sm w-75 ${(!isLocked) ? '' : 'disabled'}`}
                    onClick={handleOrderDone}
                >
                    {loading ? (
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {' '}Отдаю платеж{/* Убедитесь, что здесь нет класса visually-hidden */}
                        </>
                    ) : (
                        statusMessage || btnStatusText
                    )}
                </button>
            )}
            {orderInfo.orderStatus === 'PAID' && orderInfo.tradeType === 'BUY' &&(
                <button className={`btn btn-dark btn-sm w-100 disabled`}>
                    {btnStatusText}
                </button>
            )}
            {orderInfo.orderStatus === 'CANCEL' && (
                <button className={`btn btn-warning btn-sm w-100 disabled`}>
                    {btnStatusText}
                </button>
            )}
            {orderInfo.orderStatus === 'CANCEL_SYS' && (
                <button className={`btn btn-warning btn-sm w-100 disabled`}>
                    {btnStatusText}
                </button>
            )}
            {orderInfo.orderStatus === 'PAID' && orderInfo.tradeType === 'SELL' &&(
                <button type="button" className="btn btn-secondary btn-sm" onClick={toggleLock}>
                    {isLocked ? <LockOutlined /> : <UnlockOutlined />}


                </button>
            )}

            {Object.keys(orderInfo).length === 4 &&(
                <button type="button" className="btn btn-secondary btn-sm disabled">
                    {isLocked ? <LockOutlined /> : <UnlockOutlined />}
                </button>
            )}
        </div>
    );
}

export default OrderStatus;