import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, message, Spin } from 'antd';
import { fetchGetCommands, createCommand, editCommand, deleteCommand } from "../../../../config/crm/apiCRM2";
import { addMessage } from "../../../../components/Notification/Toast";
import {EditOutlined, DeleteOutlined } from "@ant-design/icons";

const { Column } = Table;

const CommandManagement = () => {
    const [commands, setCommands] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [selectedCommand, setSelectedCommand] = useState(null);

    const fetchCommands = async () => {
        setLoading(true);
        try {
            const response = await fetchGetCommands();
            if (response && response.code === 1000) {
                setCommands(response.data);
            } else {
                addMessage('error', response.msg || 'Не удалось получить список команд');
            }
        } catch (error) {
            addMessage('error', error.message || 'Ошибка при получении списка команд');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCommands();
    }, []);

    const handleAdd = () => {
        setSelectedCommand(null);
        form.resetFields();
        setIsModalVisible(true);
    };

    const handleEdit = (command) => {
        setSelectedCommand(command);
        form.setFieldsValue(command);
        setIsModalVisible(true);
    };

    const handleDelete = (command) => {
        Modal.confirm({
            title: 'Вы уверены, что хотите удалить команду?',
            content: `Команда: ${command.label}`,
            onOk: async () => {
                setLoading(true);
                try {
                    const response = await deleteCommand({ team: command.team });
                    if (response && response.code === 1000) {
                        addMessage('success', response.msg || 'Команда успешно удалена');
                        fetchCommands();
                    } else {
                        addMessage('error', response.msg || 'Не удалось удалить команду');
                    }
                } catch (error) {
                    addMessage('error', error.message || 'Ошибка при удалении команды');
                } finally {
                    setLoading(false);
                }
            }
        });
    };

    const handleSave = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);
            let response;
            if (selectedCommand) {
                response = await editCommand({ team: selectedCommand.team, label: values.label });
            } else {
                response = await createCommand(values);
            }

            if (response && response.code === 1000) {
                addMessage('success', response.msg || `Команда успешно ${selectedCommand ? 'обновлена' : 'создана'}`);
                fetchCommands();
                setIsModalVisible(false);
            } else {
                addMessage('error', response.msg || `Не удалось ${selectedCommand ? 'обновить' : 'создать'} команду`);
            }
        } catch (error) {
            addMessage('error', error.message || `Ошибка при ${selectedCommand ? 'обновлении' : 'создании'} команды`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Spin spinning={loading}>
            <Button type="primary" onClick={handleAdd}>Добавить команду</Button>
            <Table dataSource={commands} rowKey="team" style={{ marginTop: 20 }}>
                <Column title="Номер команды" dataIndex="team" key="team" />
                <Column title="Название команды" dataIndex="label" key="label" />
                <Column title="Пользователей" dataIndex="countMerchants" key="countMerchants" />


                <Column
                    title="Действия"
                    key="actions"
                    render={(text, record) => (
                        <>
                            <Button icon={<EditOutlined />} type="link" onClick={() => handleEdit(record)}/>
                            <Button icon={<DeleteOutlined />} type="link" danger onClick={() => handleDelete(record)}/>
                        </>
                    )}
                />
            </Table>
            <Modal
                title={selectedCommand ? 'Редактировать команду' : 'Добавить команду'}
                open={isModalVisible}
                onOk={handleSave}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="label"
                        label="Название команды"
                        rules={[{ required: true, message: 'Пожалуйста, введите название команды' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </Spin>
    );
};

export default CommandManagement;
