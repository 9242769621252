import {observer} from "mobx-react";
import React, { useEffect, useState, useCallback } from "react";
import {tradeStore} from "../../state/TradeStore";
import {Badge, Button, Drawer, Spin, Tabs, Tooltip} from "antd";
import {formatNumber} from "../../components/numericFunc";
import {CloseOutlined, ReloadOutlined} from "@ant-design/icons";
import {ReportModal} from "./components/table/ReportModal";
import NewTableOrders from "./newTableOrders";
import ConsoleMain from "../../components/OrderDetail/ConsoleMain";
import {VhRow2ColNew} from "../../Layouts/comp/CustomRow";

const refreshInterval = 60; // 60 секунд
const tabsConfig = (typeTabs, tradeType) => {
    return typeTabs === 1 ? {
        'SELL': [
            { id: "PAID", label: 'Активные', status: 'PAID' },
            { id: "APPEAL", label: 'Апелляция', status: 'APPEAL' },
        ],
        'BUY': [
            { id: 'PAID', label: 'Активные', status: 'PAID' },
            { id: 'APPEAL', label: 'Апелляция', status: 'APPEAL' },
        ],
    } : {
        'SELL': [
            { id: "PAID", label: 'Активные', status: 'PAID' },
            { id: "PEND", label: 'Неоплаченные ордера', status: 'PEND' },
            { id: "APPEAL", label: 'Апелляция', status: 'APPEAL' },
        ],
        'BUY': [
            { id: 'PEND', label: 'Активные', status: 'PEND' },
            { id: 'PAID', label: 'Ждем актив', status: 'PAID' },
            { id: 'appealGo', label: 'Долго отдает', status: 'PAID', extraFilter: (order) => Date.now() - order.tsPaid >= 900000 },
            { id: 'APPEAL', label: 'Апелляция', status: 'APPEAL' },
        ],
    };
};

const renderTabTitle = (tab, amount) => {
    const badgeColor = tab.data.some(order => order.msgCount > 0) ? 'red' : tab.data.length === 0 ? 'yellow' : 'green';
    return (
        <span>

        {tab.label}
            <Tooltip placement="bottomLeft" title={`Общая сумма: ${formatNumber(amount,true,"USDT")}`}>
      <Badge
          count={tab.data.length}
          style={{
              backgroundColor: badgeColor,
              boxShadow: `0 0 0 2px ${badgeColor === 'yellow' ? '#ffe58f' : '#fff'}`,
              marginLeft: 8,
          }}
          overflowCount={999}
      />
          </Tooltip>
    </span>
    );
};

const TabsOrders = observer(({tradeType, typeTabs}) =>{

    const [activeTab, setActiveTab] = useState("PAID");
    const [activeData, setActiveData] = useState([]);

    const [isReportModalVisible, setReportModalVisible] = useState(false);
    const [orderNumber,setOrderNumber] = useState("")
    const [userNumber, setUserNumber] = useState("")
    const [totalPrice, setTotalPrice] = useState(0)
    const [refreshKey, setRefreshKey] = useState(0);  // Добавлено новое состояние


    const [autoRefresh, setAutoRefresh] = useState(false);
    const [countdown, setCountdown] = useState(refreshInterval);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const tabs = tabsConfig(typeTabs, tradeType);
    useEffect(() => {
        // Фильтруем данные для активной вкладки при её изменении
        const filteredData = tradeStore.dataOrders.filter(order => order.tradeType === tradeType && order.orderStatus === activeTab);
        setActiveData(filteredData);
    }, [activeTab, tradeType, tradeStore.dataOrders]); // Добавьте tradeStore.dataOrders в зависимости

    const handleTabChange = (key) => {
        setActiveTab(key); // Устанавливаем новую активную вкладку
    };


    const tabData = tabs[tradeType].map((tab) => {
        let data = tradeStore.dataOrders.filter((order) => {
            // Сравниваем tradeType заказа напрямую с заданным tradeType
            const tradeTypeMatch = order.tradeType === tradeType;

            // Определяем, соответствует ли статус заказа текущей вкладке
            let statusMatch = false;
            if (typeTabs === 1) {
                if (tab.id === "APPEAL" && order.orderStatus === "APPEAL") {
                    statusMatch = true; // Для вкладки APPEAL берём только заказы со статусом APPEAL
                } else if (tab.id === "PAID" && order.orderStatus !== "APPEAL") {
                    statusMatch = true; // Для вкладки PAID берём все заказы, кроме со статусом APPEAL
                }
            } else {
                statusMatch = tab.status === order.orderStatus;
            }

            // Если есть дополнительный фильтр, применяем его также
            if (tab.extraFilter) {
                return tradeTypeMatch && statusMatch && tab.extraFilter(order);
            }

            return tradeTypeMatch && statusMatch;
        });

        return {
            ...tab,
            data
        };
    });

    useEffect(() => {
        let intervalId;
        let countdownInterval;

        if (autoRefresh) {
            intervalId = setInterval(() => {
                tradeStore.upLoadingOrders();
                setCountdown(refreshInterval); // Сбросить таймер
            }, refreshInterval * 1000); // Запустить автообновление каждые 60 секунд

            countdownInterval = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown > 0 ? prevCountdown - 1 : refreshInterval);
            }, 1000); // Уменьшить счетчик каждую секунду
        } else {
            setCountdown(refreshInterval); // Сбросить таймер при выключении автообновления
        }

        return () => {
            clearInterval(intervalId);
            clearInterval(countdownInterval);
        };
    }, [autoRefresh]);

    const handleRightClick = useCallback((event) => {
        event.preventDefault();
        setAutoRefresh(!autoRefresh);
    }, [autoRefresh]);



    const showReportModal = useCallback(() => {
        setReportModalVisible(true);
    }, []);

    const handleModalClose = useCallback(() => {
        setReportModalVisible(false);
    }, []);


    const updateButton = (
        <Button
            onContextMenu={handleRightClick}
            onClick={() => {
                tradeStore.setLoadingList(true);
                tradeStore.upLoadingOrders();
            }}
            disabled={tradeStore.loadingList}
            icon={tradeStore.loadingList ? <Spin /> : <ReloadOutlined />}
        >
            Обновить {autoRefresh ? `(${countdown} сек)` : ""}
        </Button>
    );
    let tabBarExtraContent = {
        right: updateButton
    };

    if (typeTabs === 1) {
        tabBarExtraContent = {
            right: (
                <>
                    {tradeType === "SELL" && updateButton}
                    {tradeType === "BUY" && (
                        <>
                            <Button onClick={showReportModal}>
                                Отчет
                            </Button>
                            <ReportModal isVisible={isReportModalVisible} handleClose={handleModalClose} />
                        </>
                    )}
                </>
            )
        };
    }


    const closeModal = useCallback(() => {
        setTotalPrice(0);
        setIsModalOpen(false);
    }, []);



    const drawerPlacement = tradeType === "BUY" ? 'right' : 'left';
    const backgroundColor = tradeType === 'SELL' ? 'rgba(255, 230, 230, 0.85)' : 'rgba(230, 255, 230, 0.85)';
    const closeButtonPosition = tradeType === "BUY" ? 'left' : 'right';
    const title = (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div style={{ position: 'absolute', [closeButtonPosition]: 0 }}>
                <Button onClick={closeModal} className="close-button">
                    <CloseOutlined style={{ color: 'rgba(0, 0, 0, 0.9)', fontSize: '16px' }} />
                </Button>
            </div>
            <div style={{ fontWeight: 'bold' }}>Ордер на {formatNumber(totalPrice, true)}</div>
        </div>
    );



    const tabsComponent  = (
        <Tabs
            defaultActiveKey="PAID"
            onChange={setActiveTab}
            tabBarExtraContent={tabBarExtraContent}
            items={tabData.map(tab => {
                const amount = tab.data.reduce((acc, order) => acc + order.amount, 0);
                return {
                    label: renderTabTitle(tab, amount),
                    key: tab.id,
                    children: (
                        <NewTableOrders
                            dataOrders={tab.data}
                            tradeType={tradeType}
                            typeTabs={typeTabs}
                            setUserNumber={setUserNumber}
                            setOrderNumber={setOrderNumber}
                            setIsModalOpen={setIsModalOpen}
                            setTotalPrice={setTotalPrice}
                            isModalOpen={isModalOpen}
                        />
                    ),
                };
            })}
        />
    );
    return (

        <>
            {typeTabs === 1 ? (
                <>
                    <div className="shadow card border-0">
                        {tabsComponent}

                    </div>
                    <Drawer
                        title={title}
                        placement={drawerPlacement}
                        open={isModalOpen}
                        onClose={closeModal}
                        closable={false}
                        getContainer={false}
                        width={760}

                        style={{ justifyContent: 'center', backgroundColor: backgroundColor, position: 'relative' }}
                    >
                        <ConsoleMain tradeType={tradeType} userNumber={userNumber} orderNumber={orderNumber} componentKey={refreshKey} setKey={setRefreshKey}  sizeChat={300} />
                    </Drawer>
                </>
            ) : (
                <VhRow2ColNew
                    col1={() => tabsComponent}
                    col2={() => <ConsoleMain tradeType={tradeType} userNumber={userNumber} orderNumber={orderNumber} componentKey={refreshKey} setKey={setRefreshKey}  sizeChat={200} />}
                    size={[14, 10]}
                />
            )}
        </>

    );
})

export default TabsOrders;