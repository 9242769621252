import { ref, set } from 'firebase/database';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, database } from './firebase-config.js';

export const registerUser = (email, password, lastName, firstName, middleName, birthdate,telegram,roleType) => {
    return new Promise((resolve, reject) => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const userUID = userCredential.user.uid;
                const userRef = ref(database, `users/${userUID}/data`);

                set(userRef, {
                    uid: userUID,
                    email: email,
                    lastName: lastName,
                    firstName: firstName,
                    middleName: middleName,
                    birthdate: birthdate,
                    telegramID: telegram,
                    roleType : roleType
                })
                    .then(() => {
                        resolve(userUID);
                    })
                    .catch((error) => {
                        reject('Произошла ошибка регистрации');
                    });
            })
            .catch((error) => {
                switch (error.code) {
                    case 'google-fire/email-already-in-use':
                        reject('Пользователь с этим адресом электронной почты уже зарегистрирован.');
                        break;
                    case 'google-fire/invalid-email':
                        reject('Неверный формат адреса электронной почты.');
                        break;
                    case 'google-fire/weak-password':
                        reject('Слабый пароль. Пароль должен содержать минимум 6 символов.');
                        break;
                    default:
                        reject('Произошла ошибка при регистрации.');
                        break;
                }
            });
    });
};


