import { createContext, useEffect, useState, useContext } from 'react';
import { auth } from './firebase-config';
import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { sendSessionDataToServer,checkActiveSession} from '../crm/apiCRM'

const AuthContext = createContext(null);
const generateSessionId = () => {
    return Math.random().toString(36).substring(2);
};

const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
    const browser = userAgent.includes("Firefox") ? "Firefox" :
        userAgent.includes("Chrome") ? "Chrome" :
            userAgent.includes("Safari") ? "Safari" : "Other";
    const os = userAgent.includes("Win") ? "Windows" :
        userAgent.includes("Mac") ? "MacOS" :
            userAgent.includes("Linux") ? "Linux" : "Other";

    return { browser, os };
};
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dataUser, setDataUsers] = useState(null);
    const [token] = useState(null);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setLoading(true);
            if (currentUser) {
                const isActiveSession = await checkActiveSession(currentUser.uid, localStorage.getItem('sessionId'));
                if (isActiveSession) {
                    setIsAuthenticated(true);
                    setUser(currentUser);
                } else {
                    setIsAuthenticated(false);
                    setUser(null);
                    setDataUsers(null)
                    localStorage.removeItem('sessionId');
                    localStorage.removeItem('uid');
                }
            } else {
                setIsAuthenticated(false);
                setUser(null);
                setDataUsers(null)
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const login = async (email, password) => {
        try {
            setLoading(true);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const uid = userCredential.user.uid;
            const newSessionId = generateSessionId();
            const sessionData = {
                uid: uid,
                loginTime: Date.now(),
                logoutTime: null,
                deviceInfo: getDeviceInfo(),
                sessionId: newSessionId, // Получаем сохраненный идентификатор сессии
            };

            // Отправляем данные о входе на сервер
            await sendSessionDataToServer(sessionData, 'login');
            localStorage.setItem('sessionId', newSessionId);
            localStorage.setItem('uid', uid);
            setIsAuthenticated(true);
            setUser(userCredential.user);
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const logout = async () => {
        try {
            setLoading(true);
            const uid = user?.uid;
            const currentSessionId = localStorage.getItem('sessionId');

            const sessionData = {
                uid: uid,
                logoutTime: Date.now(),
                logoutType: "user",
            };

            // Отправляем данные о завершении сессии на сервер
            await sendSessionDataToServer(sessionData, 'logout');

            // Завершаем текущую сессию
            await signOut(auth);

            // Удаляем идентификатор сессии из localStorage
            localStorage.removeItem(currentSessionId);
            localStorage.removeItem(uid);
            localStorage.removeItem("dataMerchants")
            localStorage.clear('userNumber')
            setIsAuthenticated(false);
            setUser(null);
            setDataUsers(null);
        } catch (error) {

          } finally {
            setLoading(false);
        }
    };

    return (
        <AuthContext.Provider value={{ user, isAuthenticated, login, logout, loading, dataUser, token }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);