import React, { useEffect, useState } from 'react';
import GlassPrice from "./components/GlassPrice";
import ControlPrice from "./components/ControlPrice";
import PageTabsPrice from "./components/leftTabs/PageTabsPrice";
import AdsStore from "../../state/AdsStore";
import { observer } from "mobx-react";
import {TwoColLayout} from "../../Layouts/formRow/2col";


const PricePage = observer (() => {
    const [marketActive, setMarketActive] = useState(2);

    useEffect(() => {
        AdsStore.loadAllAds(); // Загрузка всех объявлений один раз при монтировании компонента

    }, []); // Пустой массив зависимостей, чтобы вызов был выполнен только один раз

    return (
        <TwoColLayout
            col1={() => (
                <>
                    <ControlPrice/>
                    <GlassPrice/>
                </>
            )}
            col2={() => (
                <PageTabsPrice
                    marketActive={marketActive}
                    setMarketActive={setMarketActive}
                />
            )}
            sizeCol={[12,12]}

        />

    );
})

export default PricePage;