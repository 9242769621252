import React  from 'react';

const NotFound  = () => {
    return (
        <div>
            <span>Страницы не существует</span>
        </div>
    );
};

export default NotFound ;
