import React, { useState } from 'react';
import { Button, InputNumber, Table } from 'antd';

const CrmPage = () => {
    const [employees, setEmployees] = useState([]);
    const [totalProfit, setTotalProfit] = useState(0); // Значение по умолчанию для общей прибыли
    const addEmployee = () => {
        const newEmployee = {
            key: employees.length,
            name: `Сотрудник ${employees.length + 1}`,
            startTime: 12, // По умолчанию начало смены
            endTime: 22, // По умолчанию конец смены
            profit: 0,
        };
        setEmployees([...employees, newEmployee]);
    };

    const handleTimeChange = (key, timeType, value) => {
        setEmployees(employees.map(emp => (
            emp.key === key ? { ...emp, [timeType]: value } : emp
        )));
    };

    const calculateProfits = () => {
        const intervals = [];

        // Создаем массив интервалов на основе сотрудников
        employees.forEach(emp => {
            const interval = {
                startTime: emp.startTime,
                endTime: emp.endTime,
                totalProfit: emp.endProfit - emp.startProfit,
                employeeCount: 1,
            };

            intervals.push(interval);
        });

        // Сортируем интервалы по начальному времени
        intervals.sort((a, b) => a.startTime - b.startTime);

        // Проходим по интервалам и распределяем прибыль
        intervals.forEach((interval, index) => {
            for (let i = index + 1; i < intervals.length; i++) {
                if (intervals[i].startTime < interval.endTime) {
                    // Интервалы пересекаются, распределяем прибыль
                    const overlap = Math.min(interval.endTime, intervals[i].endTime) - Math.max(interval.startTime, intervals[i].startTime);
                    const overlapRatio = overlap / (interval.endTime - interval.startTime);
                    const distributedProfit = interval.totalProfit * overlapRatio;
                    interval.totalProfit -= distributedProfit;
                    intervals[i].totalProfit += distributedProfit;
                    intervals[i].employeeCount++;
                }
            }
        });

        // Обновляем прибыль для каждого сотрудника
        employees.forEach(emp => {
            const interval = intervals.find(interval => interval.startTime === emp.startTime && interval.endTime === emp.endTime);
            if (interval) {
                emp.profit = (interval.totalProfit / interval.employeeCount).toFixed(2);
            }
        });

        setEmployees([...employees]);
    };


// Функция для подсчета количества сотрудников в интервале
    const employeesInInterval = (startTime, endTime) => {
        let count = 0;
        employees.forEach(emp => {
            if (emp.startTime < endTime && emp.endTime > startTime) {
                count++;
            }
        });
        return count;
    };    const columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Начало смены',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (text, record) => (
                <InputNumber
                    min={0} max={24}
                    defaultValue={text}
                    onChange={(value) => handleTimeChange(record.key, 'startTime', value)}
                />
            ),
        },
        {
            title: 'Конец смены',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (text, record) => (
                <InputNumber
                    min={0} max={24}
                    defaultValue={text}
                    onChange={(value) => handleTimeChange(record.key, 'endTime', value)}
                />
            ),
        },
        {
            title: 'Начальная прибыль',
            dataIndex: 'startProfit',
            key: 'startProfit',
            render: (text, record) => (
                <InputNumber
                    min={0}
                    defaultValue={text}
                    onChange={(value) => handleTimeChange(record.key, 'startProfit', value)}
                />
            ),
        },
        {
            title: 'Конечная прибыль',
            dataIndex: 'endProfit',
            key: 'endProfit',
            render: (text, record) => (
                <InputNumber
                    min={0}
                    defaultValue={text}
                    onChange={(value) => handleTimeChange(record.key, 'endProfit', value)}
                />
            ),
        },
        {
            title: 'Заработок',
            dataIndex: 'profit',
            key: 'profit',
        },
    ];

    // Добавим логику для расчета заработка

    return (
        <div>
            <Button onClick={addEmployee}>Добавить сотрудника</Button>
            <InputNumber
                min={0}
                defaultValue={totalProfit}
                onChange={(value) => setTotalProfit(value)}
                style={{marginLeft: '10px'}}
            />
            <Button onClick={calculateProfits} style={{marginLeft: '10px'}}>Рассчитать заработок</Button>
            <Table dataSource={employees} columns={columns}/>
        </div>
    );
};

export default CrmPage;
