export const bankOptions = [
    { value: 'sber', label: 'Сбер', secondaryLabel: 'Карта / телефон' },
    { value: 'tinkoff', label: 'Тинькофф', secondaryLabel: 'Номер карты' },
    { value: 'spb', label: 'СБП', secondaryLabel: 'Номер телефона' },
];

export const cryptoOptions = [
    { value: 'trc20', label: 'TRC-20' },
    { value: 'bep20', label: 'BEP-20' },
];

export const tradeTypeOptions = [
    { value: 'BUY', label: 'Покупка' },
    { value: 'SELL', label: 'Продажа' },
];

export const glassUsersOptions = [
    { value: true, label: 'Мерчанты' },
    { value: false, label: 'Серые' },
    { value: null, label: 'Все' },
];

export const glassStatusOptions = [
    { value: true, label: 'Только онлайн' },
    { value: null, label: 'Все' },
];

export const assetsOptions = [
    { value: 'USDT', label: 'USDT', round:2},
    { value: 'BTC', label: 'BTC', round:6 },
    { value: 'ETH', label: 'ETH', round:6 },
    { value: 'BNB', label: 'BNB', round:3 },
];
export const ASSETS_DEF = assetsOptions.reduce((acc, option) => {
    acc[option.value] = { freeze: 0, free: 0, total: 0 };
    return acc;
}, {});


export const marketsOptions = [
    { value: 1, label: "Binance" },
    { value: 2, label: "ByBit" },
    { value: 3, label: "HTX" },
    { value: 4, label: "Commex" }
];


export const statusDescriptions = {
    PEND: 'Новый ордер',
    PAID: 'Оплачен',
    COMPLETE: 'Ордер завершен',
    APPEAL: 'Апелляция',
    CANCEL: 'Отменен',
    TIMEOUT: 'Осталось мало времени',
    CANCEL_SYS: 'Отменен системой'
};


export const quickRepliesBuy = [
    { label: 'Упал в обработку', text: 'Добрый день, платеж к сожалению ушел в "обработку" ... Сейчас позвоню в банк узнаю в чем дело' },
    { label: 'Упал в обработку + ожидание', text: 'Приветствую. Прошу прощения за длительное ожидание. У меня платеж попал в обработку. Ожидайте пожалуйста. Уже звоню на горячую линию.   ' },

    { label: 'Упал в обработку (для поддержки)', text: 'Уважаемая служба поддержки! Платеж попал в обработку банка. Перевод был выполнен, чек прикреплен в чат ордера.' },
    { label: 'Не отпускает (для поддержки)', text: 'Уважаемая служба поддержки! Оплата была произведена успешно, контрагент не отпускает монеты!' },
    { label: 'Запрос данных для банка', text: 'Здраствуйте,будьте добры полное фио,возраст и город получателя,на случай обработки в тинькофф,спасибо за понимание' },

    { label: 'Тех проблемы', text: 'У меня небольшие технические проблемы, ожидайте пожалуйста. Я на связи.' },

];

export const quickRepliesSell = [
    { label: 'Дайте чек', text: 'Предоставьте пожалуйста после оплаты чек в pdf формате' },
    { label: 'Дайте PDF', text: 'Предоставьте пожалуйста чек(квитанцию) это поможет мне проверить поступление желательно в формате pdf' },
    { label: 'Дайте PDF2', text: 'Буду признателен, если вышлете мне чек (квитанцию) в формате PDF. Необходимость связана с внутренними требованиями по учёту. Спасибо' },
    { label: 'Дайте PDF3', text: 'Отправьте пожалуйста чек (квитанцию) в формате PDF. Заранее благодарен' },
    // { label: 'Нарушили условия', text: 'Вы нарушили условия сделки, у меня указано что я не принимаю оплату от третьих лиц, оплата только личной картой'},
    // { label: 'скриншот РАЙФ', text: 'Это просто картинка о переводе, в ней нет ФИО отправителя, загрузите пожалуйста в чат квитанцию(чек)'},
    { label: 'Просто скриншот', text: 'Это просто скриншот перевода. \n' +
            'Нажмите на "документы по операции" и отправьте сформировавшийся чек пожалуйста'},
    { label: 'Квитанция из ЛК', text: 'Скачайте квитанцию из личного кабинета (он скачивается в формате пдф) и прикрепите этот файл в чат ордера. В пр\n' +
            'авом верхнем углу ЛК банка синяя стрелка - это кнопка для скачивание чека пдф. Спасибо'},
    { label: 'Квиток Android', text: 'Если андроид, то аналогично, но сначала надо отправить в телеграм или на почту его, затем скачать на свое устройство и прикрепить в чат ордера'},
    { label: 'Вылетело из ЛК', text: 'У меня из лк вылетело, не могу проверить баланс, отправьте пожалуйста скрин из истории перевода (а лучше видео с историе перевода по данному ордеру) чтобы мог быстрее проверить и отпустить Вам монеты'},

    { label: 'Авторизация', text: 'Попробуйте между своими счетами в приложении TиH"KоFф перевести деньги, это обычно помогает для авторизации последнего платежа, если не поможет то тогда ждем авторизацию, необходима квитанция(чек)'},
    { label: 'Фото карты', text: 'Могу предложить Вам такой вариант сделайте фото карты (с которой был перевод) на фоне нашей с вами сделки (открытого ордера либо чата), имя держателя карты должно быть видно! Остальные данные можете закрыть'},
    // { label: 'Возврат от третьего лица', text: 'Предоставьте номер карты (с которой был перевод) я верну платёж, после возврата прошу отменить ордер'},
    { label: 'Номер карты', text: 'Нажмите иконку "оплатить" и Вы увидите реквизиты ФИО и номер карты'},
    { label: 'слепой и глупый', text: 'В моём объявлении прописаны все условия сделки, указан способ оплаты и прочие условия'},
    { label: 'Морось чек', text: 'Извините за задержку, но в данный момент чек еще не сформирован, идёт авторизация, подождите не много, я загружу чек'},
    { label: '2 ордера', text: 'У Вас открыто два одинаковых ордера, оплачен только один, оплатите второй или закройте его'},
    { label: 'Тех проблемы', text: 'У меня небольшие технические проблемы, ожидайте пожалуйста. Я на связи.' },
    // { label: 'СБЕР', text: 'Перевод с карты SБEP, могу принять только если ФИО карты совпадает с ФИО аккаунта биржи полностью!'},
    // { label: 'Потеряшка', text: 'Этот ордер уже отменён (время вышло, возможно вы не нажали "оплачено"), вам нужно открыть новый ордер на туже сумму и нажать в нём "оплачено" так же прошу вас скинуть в чат уже нового ордера чек об оплате'},

];

export const payOptions = {
    Tinkoff: { label: 'Тинькофф', color: '#E9E98B' },
    Sberbank: { label: 'Сбербанк', color: '#B4E09F' },
    SBP: { label: 'СБП', color: '#FFD700' },
    AlfaBank: { label: 'Альфа-Банк', color: '#FA8072' },
    RaiffeisenBank: { label: 'Райффайзенбанк', color: '#ADD8E6' },
    VTBBank: { label: 'ВТБ', color: '#20B2AA' },
    QIWI: { label: 'QIWI', color: '#FFDAB9' },
    HomeCreditBank: { label: 'Хоум Кредит', color: '#FF69B4' },
    GazpromBank: { label: 'Газпромбанк', color: '#A52A2A' },
    MTSBank: { label: 'МТС Банк', color: '#87CEEB' },
    BANK: { label: 'Банк', color: '#778899' },
    PostBank: { label: 'Почта Банк', color: '#6B8E23' },
    RosBank: { label: 'Росбанк', color: '#B8860B' },
    Umoney: { label: 'ЮMoney', color: '#FF4500' },
    SovkomBank: { label: 'Совкомбанк', color: '#8B4513' },
    OtkritieBank: { label: 'Открытие', color: '#DB7093' },
    RosselkhozBank: { label: 'Россельхозбанк', color: '#4682B4' },
    UralsibBank: { label: 'Уралсиб', color: '#D2B48C' },
    BCSBank: { label: 'БКС Банк', color: '#008080' },
    Mobiletopup: { label: 'Мобильная связь', color: '#DAA520' },
    AkBarsBank: { label: 'Ак Барс', color: '#5F9EA0' },
    RenaissanceCredit: { label: 'Ренессанс Кредит', color: '#FF8C00' },
    AdvCash: { label: 'AdvCash', color: '#FF6347' },
    Payeer: { label: 'Payeer', color: '#DA70D6' },
    BankSaintPetersburg: { label: 'Банк Санкт-Петербург', color: '#4169E1' },
    PromsvyazBank: { label: 'Промсвязьбанк', color: '#2E8B57' },
    RussianStandardBank: { label: 'Русский Стандарт', color: '#C71585' },
    UniCredit: { label: 'ЮниКредит', color: '#B22222' },
    CreditEuropeBank: { label: 'Кредит Европа Банк', color: '#8A2BE2' },
    OTPBank: { label: 'ОТП Банк', color: '#A0522D' },
    VostochnyBank: { label: 'Восточный', color: '#5F9EA0' },
    CreditMoscow: { label: 'Кредит Москва', color: '#FF69B4' },
    AbsolutBank: { label: 'Абсолют', color: '#6495ED' },
    CitiBank: { label: 'Ситибанк', color: '#FF4500' },
    RaiffeisenBankAval: { label: 'Райффайзен Аваль', color: '#008B8B' },
};

//
// export const payOptionsArray = [
//     { value: 'Tinkoff', label: 'Тинькофф', color: '#E9E98B' },
//     { value: 'Sberbank', label: 'Сбербанк', color: '#B4E09F' },
//     { value: 'SBP', label: 'СБП', color: '#FFD700' },
//     { value: 'AlfaBank', label: 'Альфа-Банк', color: '#FA8072' },
//     { value: 'RaiffeisenBank', label: 'Райффайзенбанк', color: '#ADD8E6' },
//     { value: 'VTBBank', label: 'ВТБ', color: '#20B2AA' },
//     { value: 'QIWI', label: 'QIWI', color: '#FFDAB9' },
//     { value: 'HomeCreditBank', label: 'Хоум Кредит', color: '#FF69B4' },
//     { value: 'GazpromBank', label: 'Газпромбанк', color: '#A52A2A' },
//     { value: 'MTSBank', label: 'МТС Банк', color: '#87CEEB' },
//     { value: 'BANK', label: 'Банк', color: '#778899' },
//     { value: 'PostBank', label: 'Почта Банк', color: '#6B8E23' },
//     { value: 'RosBank', label: 'Росбанк', color: '#B8860B' },
//     { value: 'Umoney', label: 'ЮMoney', color: '#FF4500' },
//     { value: 'SovkomBank', label: 'Совкомбанк', color: '#8B4513' },
//     { value: 'OtkritieBank', label: 'Открытие', color: '#DB7093' },
//     { value: 'RosselkhozBank', label: 'Россельхозбанк', color: '#4682B4' },
//     { value: 'UralsibBank', label: 'Уралсиб', color: '#D2B48C' },
//     { value: 'BCSBank', label: 'БКС Банк', color: '#008080' },
//     { value: 'Mobiletopup', label: 'Мобильная связь', color: '#DAA520' },
//     { value: 'AkBarsBank', label: 'Ак Барс', color: '#5F9EA0' },
//     { value: 'RenaissanceCredit', label: 'Ренессанс Кредит', color: '#FF8C00' },
//     { value: 'AdvCash', label: 'AdvCash', color: '#FF6347' },
//     { value: 'Payeer', label: 'Payeer', color: '#DA70D6' },
//     { value: 'BankSaintPetersburg', label: 'Банк Санкт-Петербург', color: '#4169E1' },
//     { value: 'PromsvyazBank', label: 'Промсвязьбанк', color: '#2E8B57' },
//     { value: 'RussianStandardBank', label: 'Русский Стандарт', color: '#C71585' },
//     { value: 'UniCredit', label: 'ЮниКредит', color: '#B22222' },
//     { value: 'CreditEuropeBank', label: 'Кредит Европа Банк', color: '#8A2BE2' },
//     { value: 'OTPBank', label: 'ОТП Банк', color: '#A0522D' },
//     { value: 'VostochnyBank', label: 'Восточный', color: '#5F9EA0' },
//     { value: 'CreditMoscow', label: 'Кредит Москва', color: '#FF69B4' },
//     { value: 'AbsolutBank', label: 'Абсолют', color: '#6495ED' },
//     { value: 'CitiBank', label: 'Ситибанк', color: '#FF4500' },
//     { value: 'RaiffeisenBankAval', label: 'Райффайзен Аваль', color: '#008B8B' }
// ];


export const payOptionsArray = [
    { value: 'Tinkoff', label: 'Тинькофф', color: '#ffdd2e' },
    { value: 'Sberbank', label: 'Сбербанк', color: '#08a652' },
    { value: 'Raiffeisenbank', label: 'Райффайзен', color: '#ADD8E6' },
];