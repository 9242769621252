import React, {useEffect, useState} from 'react';
import { Table} from 'antd';

import {ChatBankButton, MarketIcon} from "../../components/CommonComponents";
import {handleCopyPay, handleDisabled,ReverseTimer} from "../../components/LogicComponents";
import {tradeStore} from "../../state/TradeStore";
import {observer} from 'mobx-react';
import MerchStore from "../../state/MerchStore";
import WindowSizeStore from "../../state/WindowSizeStore";
const OrderTable = observer(({ dataOrders,tradeType, typeTabs,setUserNumber,setOrderNumber,setTotalPrice,setIsModalOpen,isModalOpen}) => {
    const marketFilter =  MerchStore.getAllMarketsValue()
    const userNickNameFilter = MerchStore.getAllNickNameValue()
    const [timeLeft, setTimeLeft] = useState({});
    const [activeButtonsPayCard, setActiveButtonsPayCard] = useState(
        dataOrders.map(() => ({ disabled: true, success: false }))
    );
    const [activeButtonsTotalPrice, setActiveButtonsTotalPrice] = useState(
        dataOrders.map(() => ({ disabled: true, success: false }))
    );
    const [activeChat, setActiveChat] = useState(null);
    const [scrollY, setScrollY] = useState(window.innerHeight);


    useEffect(() => {
        window.addEventListener('resize', updateTableScroll);

        // Вызов при монтировании
        updateTableScroll();

        // Очистка при размонтировании
        return () => {
            window.removeEventListener('resize', updateTableScroll);
        };
    }, []);
    const copyPayCardBotAndDeactivate = async (text, userNumber, orderNumber) => {
        await handleCopyPay(text, "Карта",() => {
            setActiveButtonsPayCard((prevState) => ({
                ...prevState,
                [orderNumber]: { disabled: true, success: true }
            }));
            tradeStore.orderDetailInfoReturn(userNumber, orderNumber);
        });
    };

    const copyTotalPriceAndDeactivate = async (text, orderNumber) => {
        await handleCopyPay(text, "Цена",() => {
            setActiveButtonsTotalPrice((prevState) => ({
                ...prevState,
                [orderNumber]: { disabled: true, success: true }
            }));
        });
    };
    const handleRightClick = async (event, orderNumber, type) => {
        event.preventDefault(); // Предотвращаем появление стандартного контекстного меню

        // Проверяем, заблокирована ли кнопка
        const isButtonDisabled = type === 'payCard'
            ? activeButtonsPayCard[orderNumber]?.disabled
            : activeButtonsTotalPrice[orderNumber]?.disabled;

        if (isButtonDisabled) {
            // Если кнопка заблокирована, то разблокируем ее
            if (type === 'payCard') {
                setActiveButtonsPayCard(prevState => ({
                    ...prevState,
                    [orderNumber]: { disabled: false, success: false }
                }));
                await handleDisabled('"Номер карта"')
            } else if (type === 'totalPrice') {
                setActiveButtonsTotalPrice(prevState => ({
                    ...prevState,
                    [orderNumber]: { disabled: false, success: false }
                }));
                await handleDisabled('"Итоговая цена"')
            }
        }
        // Если кнопка не заблокирована, ничего не делаем
    };


    const createStatusButton = (status, item, text, className) => (
        <button
            className={`btn btn-${className} btn-sm w-100`}
            onClick={() => {
                tradeStore.orderDetailInfoReturn(item.userNumber, item.orderNumber);
                handleChatClick(item.orderNumber);
            }}
        >{text}</button>
    );

    useEffect(() => {
        const interval = setInterval(() => {
            const newTimeLeft = {};
            dataOrders.forEach(order => {
                if (order.tsNotifyPendEnd) {
                    const remainingTime = Math.floor((new Date(order.tsNotifyPendEnd) - new Date()) / 1000);
                    newTimeLeft[order.orderNumber] = remainingTime;
                }
            });
            setTimeLeft(newTimeLeft);
        }, 1000); // Обновление каждую секунду

        return () => clearInterval(interval);
    }, [dataOrders]);

    const StatusColumnRender = (status, item) => {
        let buttonClass = 'btn btn-outline-secondary btn-sm w-100';
        if (status === 'PEND' && timeLeft[item.orderNumber] <= 300) {
            buttonClass = 'btn btn-outline-danger btn-sm w-100 btn-blink';
        }
        switch(status) {
            case 'COMPLETE':
                return createStatusButton(status, item, 'Исполнен', 'success');
            case 'APPEAL':
                return createStatusButton(status, item, 'Апелляция', 'danger');
            case 'CANCEL_SYS':
            case 'CANCEL':
                return createStatusButton(status, item, 'Отменен', 'warning');
            case 'PEND':
                return item.tradeType === "SELL" ? (
                    <button className={buttonClass}
                            onClick={() => handleToogleChatClick(item)}>
                        Осталось {' '}
                        <ReverseTimer date={item.tsNotifyPendEnd}/>
                    </button>
                ) : (
                    <button
                        className={buttonClass}

                        onClick={() => handleToogleChatClick(item)}
                    >
                        Осталось {' '}
                        <ReverseTimer date={item.tsNotifyPendEnd}/>

                    </button>

                );
            case 'PAID':
                return (
                    <button className={`btn  btn-sm w-100 ${item.tradeType === "BUY" ? 'btn-success' : 'btn-secondary'}`}
                            onClick={() => handleToogleChatClick(item)}
                    >
                        {item.tradeType === "BUY" ? "Оплачено" : "Оплатил"} {' '}
                        <ReverseTimer date={item.tsNotifyPaidEnd}/>
                    </button>
                );

            default:
                return null;
        }
    };


    const ChatColumnRender = (_, record) => {
        return (
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <ChatBankButton
                    msgCount={record.msgCount}
                    bank={record.bankNamePars}
                    onClick={() => {
                        toggleModal(record.userNumber, record.orderNumber, record.totalPrice)
                    }}
                />
                {record.asset !== "USDT" && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '-2px',
                            left: 0,
                            width: '100%',
                            height: '1px',
                            backgroundColor: 'black'
                        }}
                    />
                )}
            </div>
        );
    };
    const TotalPriceColumnRender = (_, item) => {
        const isDisabled = item.orderStatus !== 'PEND' || activeButtonsTotalPrice[item.orderNumber]?.disabled;
        // Определяем, должна ли кнопка быть зеленой и неактивной
        const shouldButtonBeGreenAndDisabled = isDisabled && item.tradeType === "BUY";
        // Определяем класс успеха на основе условия
        const successClass = shouldButtonBeGreenAndDisabled || activeButtonsTotalPrice[item.orderNumber]?.success ? 'btn-success' : 'btn-outline-secondary';

        return (
            <button
                className={`btn ${successClass} ${isDisabled ? 'disabled-style' : ''} btn-sm w-100`}
                onClick={() => {
                    if (!isDisabled) {
                        copyTotalPriceAndDeactivate(Number(item.totalPrice), item.orderNumber);
                    }
                }}
                onContextMenu={(e) => handleRightClick(e, item.orderNumber, 'totalPrice')}
            >
                {Number(item.totalPrice).toLocaleString('ru-RU')}
            </button>
        );
    };



    const PayCardColumnRender = (_, item) => {
        const isDisabled = item.orderStatus !== 'PEND' || activeButtonsPayCard[item.orderNumber]?.disabled;
        const shouldButtonBeGreenAndDisabled = isDisabled && item.tradeType === "BUY";
        const successClass = shouldButtonBeGreenAndDisabled || activeButtonsPayCard[item.orderNumber]?.success ? 'btn-success' : 'btn-outline-secondary';

        return (
            <button
                className={`btn ${successClass} ${isDisabled ? 'disabled-style' : ''} btn-sm w-100`}
                onClick={() => {
                    if (!isDisabled) {
                        copyPayCardBotAndDeactivate(item.payCardBot, item.userNumber, item.orderNumber);
                        handleToogleChatClick(item);
                    }
                }}
                onContextMenu={(e) => handleRightClick(e, item.orderNumber, 'payCard')}
            >
                {item.payCardText || "Карта"}
            </button>
        );
    };

    const PayCardFioColumnRender = (_, item) => {
        const tradeType = item.tradeType;

        return tradeType === "BUY" ? (
            PayCardColumnRender(_, item)
        ) : (
            <span>{item.agentFIO}</span>
        );
    };
    const marketColumn = {
        title: 'Биржа',
        dataIndex: 'market',
        width: '10%',
        filters: marketFilter,
        onFilter: (value, record) => record.market === value,
        render: market => <MarketIcon market={market} />,
    };
    const userNickNameColumn = {
        title: 'Никнейм',
        dataIndex: 'userNickName',
        width: '15%',
        filters: userNickNameFilter,
        onFilter: (value, record) => record.userNickName.startsWith(value),

    };
    const chatColumn = {
        title: 'Чат',
        key: 'chat',
        width: '10%',
        render: ChatColumnRender,
        filters: [
            { text: 'Тинькофф', value: 'TinkoffBank' },
            { text: 'Сбер', value: 'SberBank' },
            { text: 'Остальные', value: 'others' }
        ],
        onFilter: (value, record) => {
            if (value === 'others') {
                return !record.bankNamePars.includes('TinkoffBank') && !record.bankNamePars.includes('SberBank');
            } else {
                return record.bankNamePars.includes(value);
            }
        },
    };
    const sumColumn = {
        title: 'Сумма',
        dataIndex: 'totalPrice',
        width: '15%',
        sorter: (a, b) => a.totalPrice - b.totalPrice,
        render: (_, item) => <b>{Number(item.totalPrice).toLocaleString('ru-RU')} ₽</b>,
    };
    const sumNumberColumn = {
        title: 'Сумма',
        dataIndex: 'totalPrice',
        width: '20%',
        sorter: (a, b) => a.totalPrice - b.totalPrice,

        render: TotalPriceColumnRender, // Предполагается, что функция PayCardColumnRender определена выше
    };
    const cardNumberColumn = {
        title: 'Номер карты',
        dataIndex: 'payCard',
        width: '25%',
        render: PayCardColumnRender, // Предполагается, что функция PayCardColumnRender определена выше
    };
    const cardNumberFioColumn = {
        title: 'Номер карты/ФИО',
        dataIndex: 'payCard',
        width: '25%',
        render: PayCardFioColumnRender, // Предполагается, что функция PayCardColumnRender определена выше

    };
    const statusColumn = {
        title: 'Статус',
        dataIndex: 'orderStatus',
        width: '20%',
        render: StatusColumnRender, // Предполагается, что функция StatusColumnRender определена выше
    };
    const fioColumn = {
        title: 'ФИО',
        dataIndex: 'agentFIO',
        width: '35%'
    };
    const columnsSell = [
        marketColumn,
        userNickNameColumn,
        sumColumn,
        fioColumn,
        statusColumn,
        chatColumn,
    ];
    const columnsBuy = [
        cardNumberColumn,
        sumNumberColumn,
        statusColumn,
        marketColumn,
        userNickNameColumn,
        chatColumn,
    ];
    const columnsNight = [
        cardNumberFioColumn,
        sumNumberColumn,
        statusColumn,
        marketColumn,
        userNickNameColumn,
        chatColumn,
    ];

    const updateTableScroll = () => {
        const otherElementsHeight = 150; // Примерное значение, измените на ваше
        const availableHeight = window.innerHeight - otherElementsHeight;
        setScrollY(availableHeight);
    };
    const handleChatClick = (orderNumber) => {
        setActiveChat(orderNumber);
    };

    const handleToogleChatClick = (item) => {
        toggleModal(item.userNumber, item.orderNumber, item.totalPrice);
        if (tradeStore.globalTradeType !== "NIGHT") {
            handleChatClick(item.orderNumber);
        }
    };

    const getColumns = () => {
        if (WindowSizeStore.isMobile) {
            return [
                userNickNameColumn,
                sumColumn,
                chatColumn,
            ];
        }
        return tradeType === 'SELL' ? (columnsSell ? columnsSell : columnsNight) : (tradeType === 'BUY' ? columnsBuy : columnsNight);
    };
    const toggleModal = (activUserNumber, activOrderNumber,activTotalPrice) => {
        setUserNumber(activUserNumber)
        setOrderNumber(activOrderNumber)
        setTotalPrice(activTotalPrice)
        setIsModalOpen(!isModalOpen);
    };
    const enhancedRowClassName = (record) => {
        let classNames = 'table-row';

        if (record.orderNumber === activeChat) {
            classNames += ' active-chat-row';
        }

        if (record.disabledNote) {
            classNames += ' note-status-active'; // Добавьте класс для подсветки отключенной строки
        }
        return classNames;
    };
    return (
        <>
            <Table
                columns={getColumns()}
                dataSource={dataOrders.map((order, index) => ({
                    ...order,
                    key: `${order.orderNumber}_${index}`
                }))}
                locale={{ emptyText: 'Нет ордеров' }}
                pagination={false}
                loading={tradeStore.loadingList}
                scroll={{ y: scrollY ? `${scrollY - 10}px` : undefined }}
                className="custom-table-full"
                rowClassName={enhancedRowClassName}
            />
   </>
    );
})

export default OrderTable;
