import React, { useCallback } from 'react';
import { Input, Select, Button,  Row, Col  } from 'antd';
import { SearchOutlined, SlidersOutlined, DeleteOutlined } from '@ant-design/icons';
import MerchStore from "../../../state/MerchStore";

const InputGroup = ({ data }) =>{
    return (
        <Row gutter={[16, 16]} wrap>
            {data.map(item => (
                <Col flex="1 1 0%" key={item.key} style={{ minWidth: '100px', marginBottom: 16 }}>
                    <div className="input-group">
                        <span className="input-group-text">
                            <i className={item.icon}></i>
                        </span>
                        {item.select && (
                            <Select
                                style={{ width: '100%' }}
                                value={item.value}
                                onChange={item.onChange}
                            >
                                {item.options.map(option => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                        {item.input && (
                            <Input
                                placeholder={item.placeholder}
                                type={item.type}
                                value={item.value}
                                onChange={(e) => item.onChange(e.target.value)}
                            />
                        )}
                    </div>
                </Col>
            ))}
        </Row>
    );
}

const createInputItem = (type, key, icon, value, onChange, otherProps = {}) =>{
    return {
        type,
        key,
        icon,
        value,
        onChange,
        ...otherProps
    };
}


const OrderFilters=({ filters, updateFilter, newSearch, isAdvancedSearchVisible, setIsAdvancedSearchVisible }) => {
    const {
        tradeType,
        userNickName,
        marketName,
        orderStatus,
        asset,
        orderNumber,
        createTimeStart,
        createTimeEnd,
        totalPriceStart,
        totalPriceEnd,
        team
    } = filters;

    const setFilter = (key) => {
        return (value) => {
            updateFilter(key, value);
        };
    };

    const toggleAdvancedSearch = useCallback(() => {
        setIsAdvancedSearchVisible(prevVisible => !prevVisible);
    }, [setIsAdvancedSearchVisible]);

    const trashDefault = () => {
        const team = localStorage.getItem('team');
        console.log(team)
        const defaultFilters = {
            userNickName: 'all',
            tradeType: 'all',
            marketName: 'all',
            orderStatus: 'all',
            asset: 'all',
            totalPriceStart: '',
            totalPriceEnd: '',
            createTimeStart: '',
            createTimeEnd: '',
            orderNumber: '',
            team: team,
        };

        for (const key in defaultFilters) {
            updateFilter(key, defaultFilters[key]);
        }
    };
    const merchants = MerchStore.getAllNickNameValue(); // Получаем массив
    merchants.unshift({ value: 'all', label: 'Все Мерчанты' });

    const markets = MerchStore.getAllMarketsValue(); // Получаем массив
    markets.unshift({ value: 'all', label: 'Все биржи' });

    const inputGroupBase = [
        createInputItem('text', 'tradeType', 'bi bi-arrow-left-right', tradeType, setFilter('tradeType'), {
            select: true,
            options: [
                { value: 'all', label: 'Любой тип' },
                { value: 'BUY', label: 'Покупка' },
                { value: 'SELL', label: 'Продажа' },
            ],
        }),
        createInputItem('text', 'team', 'bi bi-currency-bitcoin', team, setFilter('team'), {
            select: true,
            options: [
                { value: '0', label: 'Все команды' },
                { value: '1', label: 'Команда А' },
                { value: '2', label: 'Команда Б' },
                { value: '3', label: 'Команда В' },
                { value: '4', label: 'Команда М' },
            ],
        }),
        createInputItem('text', 'userNickName', 'bi bi-person', userNickName, setFilter('userNickName'), {
            select: true,
            options: merchants,
        }),
        createInputItem('text', 'marketName', 'bi bi-bank', marketName, setFilter('marketName'), {
            select: true,
            options: markets,
        }),
        createInputItem('text', 'orderStatus', 'bi bi-clipboard-check', orderStatus, setFilter('orderStatus'), {
            select: true,
            options: [
                { value: 'all', label: 'Любой статус' },
                { value: 'COMPLETE', label: 'Завершен' },
                { value: 'PAID', label: 'В процессе' },
                { value: 'PEND', label: 'Оплачен' },
                { value: 'APPEAL', label: 'Аппеляция' },
                { value: 'CANCEL', label: 'Отменен' },
            ],
        }),
        createInputItem('text', 'asset', 'bi bi-currency-bitcoin', asset, setFilter('asset'), {
            select: true,
            options: [
                { value: 'all', label: 'Все активы' },
                { value: 'USDT', label: 'USDT' },
                { value: 'BTC', label: 'BTC' },
            ],
        })
    ];

    const inputGroupAdd = [
        createInputItem('text', 'searchValue', 'bi bi-journal', orderNumber, setFilter('orderNumber'), {
            input: true,
            placeholder: 'Номер ордера'
        }),
        createInputItem('datetime-local', 'createTimeStart', 'bi bi-calendar3', createTimeStart, setFilter('createTimeStart'), {
            input: true
        }),
        createInputItem('datetime-local', 'createTimeEnd', 'bi bi-calendar3', createTimeEnd, setFilter('createTimeEnd'), {
            input: true
        }),
        createInputItem('text', 'totalPriceStart', 'bi bi-tag', totalPriceStart, setFilter('totalPriceStart'), {
            input: true,
            placeholder: 'От'
        }),
        createInputItem('text', 'totalPriceEnd', 'bi bi-tag', totalPriceEnd, setFilter('totalPriceEnd'), {
            input: true,
            placeholder: 'До'
        })
    ];


    return (
        <div>
            <Row gutter={[16, 16]} justify="start">
                <Col lg={20} md={16} sm={24} xs={24}>
                    <InputGroup data={inputGroupBase} />
                </Col>
                <Col lg={4} md={8} sm={24} xs={24}>
                    <Row justify="end" gutter={[16, 16]}>
                            <Button type="default" icon={<DeleteOutlined />} onClick={trashDefault} />
                            <Button type="default" icon={<SearchOutlined />} onClick={newSearch} />
                            <Button type="default" icon={<SlidersOutlined />} onClick={toggleAdvancedSearch} />
                    </Row>
                </Col>
            </Row>
            {isAdvancedSearchVisible && (
                <Row gutter={[16, 16]} justify="start">
                <Col lg={20} md={16} sm={24} xs={24}>
                    <InputGroup data={inputGroupAdd} />
                </Col>
            <Col lg={4} md={8} sm={24} xs={24}>

            </Col>
        </Row>

            )}
        </div>
    );
}

export default OrderFilters;